import LoadingButton from '@mui/lab/LoadingButton';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { FormSkeleton, NotFound } from '../../../components/Elements/';
import { PERMISSIONS } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { useGetAllRolesQuery, useGetUserQuery } from '../../../store/api';
import { FormDetails } from '../components/FormDetails';

export const ViewUser = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { data, isLoading, error } = useGetUserQuery(userId!);
  const { data: roleData, isLoading: isRoleLoading, error: roleError } = useGetAllRolesQuery();

  const {
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userName: data?.userName || '',
      firstname: data?.firstname || '',
      lastName: data?.lastName || '',
      email: data?.email || '',
      phoneNumber: data?.phoneNumber || '',
      role: data?.role || '',
      isActive: data ? data.isActive : true,
    },
  });

  useEffect(() => {
    reset(data);
  }, [data]);

  const handleEdit = () => {
    navigate(`/user-management/users/edit/${userId}`);
  };

  const handleCancelClick = () => {
    navigate('/user-management/users');
  };

  const hasViewPermission = getAuthorization(PERMISSIONS.USER.VIEW);
  const hasEditPermission = getAuthorization(PERMISSIONS.USER.EDIT);

  return (
    <>
      {!hasViewPermission ? (
        <NotFound snackbarText="Unauthorized to view page" text="Unauthorized to view page" />
      ) : isLoading || isRoleLoading ? (
        <FormSkeleton />
      ) : error && 'data' in error && error.data.Message == 'User not found' ? (
        <NotFound snackbarText="User not found" text="User not found" />
      ) : error || roleError ? (
        <>Error loading user, please refresh the page</>
      ) : (
        <>
          <Box>
            <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" component="h1">
                  Information
                </Typography>
              </Box>
            </Box>
            <Grid container sx={{ mt: 1 }} spacing={4}>
              <Grid item xs={12}>
                <Avatar src="/asd.jpg" sx={{ width: 150, height: 150, mx: 'auto' }} />
              </Grid>

              <FormDetails
                errors={errors}
                control={control}
                rolesMaster={roleData || []}
                formState={'view'}
              />

              <Grid
                item
                xs={12}
                display={'flex'}
                sx={{
                  mx: { xs: 'auto', md: '0' },
                  justifyContent: { xs: 'center', md: 'flex-end' },
                }}
              >
                <Button sx={{ mr: 2 }} onClick={handleCancelClick}>
                  Cancel
                </Button>
                {hasEditPermission && (
                  <LoadingButton color="primary" onClick={handleEdit}>
                    Edit
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
