import {
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChangeEvent, MouseEvent, useState } from 'react';

import { EmptyTable, MobileEmptyTable, MobileTableSkeleton } from '../../../components/Elements';
import { TableSkeleton } from '../../../components/Elements/TableSkeleton';
import { PERMISSIONS } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { PaginationSizeFilter } from '../../../types';
import { UserColumns, UserDto, UserDtoListResultDto } from '../types';
import { DeleteUser } from './DeleteUser';
import { UserCardMobileView } from './UserCardMobileView';

type UsersTableProps = {
  data: UserDtoListResultDto | undefined;
  isFetching?: boolean;
  pageSize: number;
  page: number;
  handleChangePageSize: (event: ChangeEvent<HTMLInputElement>) => void;
  handlePageChange: (_: unknown, newPage: number) => void;
  handleEditRow: (selectedRow: string) => void;
  handleViewRow: (selectedRow: string) => void;
};

export const UsersTable = ({
  pageSize,
  handleEditRow,
  page,
  handleChangePageSize,
  handlePageChange,
  handleViewRow,
  isFetching,
  data,
}: UsersTableProps) => {
  const theme = useTheme();
  const biggerThanSm = useMediaQuery(theme.breakpoints.up('md'));

  const [selectedRow, setSelectedRow] = useState<string | null>();
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    setSelectedRow(event.currentTarget.getAttribute('data-id'));
    setContextMenu(
      contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const deleteCallBack = () => {
    handleClose();
  };

  const onPageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChangePageSize(event);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    handlePageChange(_, newPage);
  };

  const onEditClick = () => {
    handleEditRow(selectedRow!);
  };

  const onViewClick = () => {
    handleViewRow(selectedRow!);
  };

  const hasDeleteUserPermission = getAuthorization(PERMISSIONS.USER.DELETE);
  const hasViewUserPermission = getAuthorization(PERMISSIONS.USER.VIEW);
  const hasEditUserPermission = getAuthorization(PERMISSIONS.USER.EDIT);

  const desktopTable = (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={0} variant="outlined">
        <TableContainer sx={{ height: '50vh' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {UserColumns.filter((column) => !column.hidden).map((column) => (
                  <TableCell key={column.field} style={{ flex: column.flex }}>
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <TableSkeleton columnsCount={7} />
              ) : data && data.items.length > 0 ? (
                data.items.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onContextMenu={handleContextMenu}
                      data-id={row.id}
                      sx={{ cursor: 'context-menu' }}
                    >
                      {UserColumns.filter((column) => !column.hidden).map((column) => {
                        const value = row[column.field as keyof UserDto];
                        return (
                          <TableCell key={column.field} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <EmptyTable />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={PaginationSizeFilter}
          component="div"
          count={data?.totalRecords || 0}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onPageSizeChange}
        />
      </Paper>

      {(hasEditUserPermission || hasDeleteUserPermission || hasViewUserPermission) && (
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              },
            },
          }}
        >
          {hasViewUserPermission && (
            <MenuItem sx={{ fontSize: 13 }} onClick={onViewClick}>
              View User
            </MenuItem>
          )}
          {hasEditUserPermission && (
            <MenuItem sx={{ fontSize: 13 }} onClick={onEditClick}>
              Edit User
            </MenuItem>
          )}
          {hasDeleteUserPermission && <DeleteUser id={selectedRow!} callback={deleteCallBack} />}
        </Menu>
      )}
    </>
  );

  const mobileView = (
    <Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={0} variant="outlined">
      <TableContainer sx={{ height: '100%', minHeight: '600px' }}>
        {isFetching ? (
          <MobileTableSkeleton />
        ) : data && data.items.length > 0 ? (
          data.items.map((item) => {
            return (
              <UserCardMobileView
                key={item.id}
                user={item}
                handleEditRow={handleEditRow}
                handleViewRow={handleViewRow}
              />
            );
          })
        ) : (
          <MobileEmptyTable />
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={PaginationSizeFilter}
        component="div"
        count={data?.totalRecords || 0}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onPageSizeChange}
      />
    </Paper>
  );

  return biggerThanSm ? desktopTable : mobileView;
};
