import { ReactNode, useCallback } from 'react';

import storage from '../utils/storage';

export const useAuthorization = () => {
  const roles = storage.getRoles();

  if (!roles) {
    throw Error('No permission found!');
  }

  const checkAccess = useCallback(
    ({ requiredRoles }: { requiredRoles: string | string[] }) => {
      if (requiredRoles && requiredRoles.length > 0) {
        // NOTE: using some here instead of every, button should expect only one permission
        if (typeof requiredRoles === 'string') {
          return roles.includes(requiredRoles);
        }
        return requiredRoles?.some((r: any) => roles.includes(r));
      }

      return true;
    },
    [roles]
  );

  return { checkAccess, roles: roles };
};

type AuthorizationProps = {
  forbiddenFallback?: ReactNode;
  children: ReactNode;
} & (
  | {
      requiredRoles: string;
      policyCheck?: never;
    }
  | {
      requiredRoles: string[];
      policyCheck?: never;
    }
);

export const Authorization = ({
  requiredRoles,
  forbiddenFallback = null,
  children,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (requiredRoles) {
    canAccess = checkAccess({ requiredRoles });
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};

export const getAuthorization = (requiredRoles: string[] | string | null | undefined) => {
  const { checkAccess } = useAuthorization();
  return requiredRoles && requiredRoles.length > 0 && checkAccess({ requiredRoles });
};

export const nonHookGetAuthorization = (requiredRoles: string[] | string | null | undefined) => {
  const roles = storage.getRoles();

  if (!roles) {
    return;
  }

  const checkAccess = ({ requiredRoles }: { requiredRoles: string | string[] }) => {
    if (requiredRoles && requiredRoles.length > 0) {
      // NOTE: using some here instead of every, button should expect only one permission
      if (typeof requiredRoles === 'string') {
        return roles.includes(requiredRoles);
      }
      return requiredRoles?.some((r: any) => roles.includes(r));
    }

    return true;
  };

  return requiredRoles && requiredRoles.length > 0 && checkAccess({ requiredRoles });
};
