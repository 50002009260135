import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { dot } from 'dot-object';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, unstable_usePrompt as usePrompt } from 'react-router-dom';
import { z } from 'zod';
import { useBeforeunload } from 'react-beforeunload';

import { PERMISSIONS } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { CreateRoleDto, PermissionDto, RoleDto } from '../types';
import { DeleteRole } from './DeleteRole';
import { FormDetails } from './FormDetails';

const schema = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string(),
});

type CreateOrUpdateRoleFormProps = {
  submitAction: (data: CreateRoleDto | RoleDto) => void;
  mapRole: (origin: any, destination: any) => CreateRoleDto | RoleDto;
  permissionsMaster?: any;
  isSubmitting?: boolean;
  role?: RoleDto;
};

export const CreateOrUpdateRoleForm = ({
  role,
  submitAction,
  permissionsMaster,
  mapRole,
  isSubmitting = false,
}: CreateOrUpdateRoleFormProps) => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    getValues,
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: role?.name || '',
      description: role?.description || '',
      ...(permissionsMaster &&
        Object.assign(
          {},
          ...permissionsMaster.map((p: PermissionDto) => {
            return { [p.name]: role?.permissions.includes(p.name) || false };
          })
        )),
    },
  });

  const isUpdate = !!role;

  const onSubmit = async () => {
    try {
      const data = dot(getValues());
      const { name, description, ...rest } = data;
      const result = {
        name,
        description,
        permissions: Object.keys(rest)
          .filter((a) => rest[a] === true)
          .map((a) => {
            return a;
          }),
      };

      const objectToSubmit = mapRole(role!, result);
      submitAction(objectToSubmit);
      setShouldBlock(false);
    } catch (ex: any) {
      console.log(ex);
      throw new Error(ex);
    }
  };

  const handleCancelClick = () => {
    navigate('/user-management/roles');
  };

  const hasDeletePermission = getAuthorization(PERMISSIONS.ROLE.DELETE);
  const [shouldBlock, setShouldBlock] = useState(isDirty);

  useEffect(() => {
    setShouldBlock(isDirty);
  }, [isDirty]);

  usePrompt({ when: shouldBlock, message: 'You have unsaved changes. Are you sure you want to leave?' });
  useBeforeunload(shouldBlock ? (event) => {
    event.preventDefault();
  } : undefined);

  return (
    <>
      <Box>
        <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
          <Typography variant="h6" component="h1">
            Information
          </Typography>
        </Box>
        <Grid
          container
          sx={{ mt: 1 }}
          spacing={4}
          noValidate
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormDetails
            control={control}
            errors={errors}
            permissionsMaster={permissionsMaster}
            getValues={getValues}
            setValue={setValue}
            formState={isUpdate ? 'edit' : 'create'}
          />

          <Grid
            item
            xs={12}
            display={'flex'}
            sx={{ mx: { xs: 'auto', md: '0' }, justifyContent: { xs: 'center', md: 'flex-end' } }}
          >
            <LoadingButton sx={{ mr: 2 }} onClick={handleCancelClick} loading={isSubmitting}>
              Cancel
            </LoadingButton>
            {isUpdate && hasDeletePermission && role?.id && (
              <DeleteRole
                id={role.id}
                navigateCallback={handleCancelClick}
                triggerButton={
                  <LoadingButton
                    sx={{ mr: 2 }}
                    color="error"
                    variant="outlined"
                    loading={isSubmitting}
                  >
                    Delete
                  </LoadingButton>
                }
                setShouldBlock={setShouldBlock}
              />
            )}
            <LoadingButton variant="contained" color="primary" type="submit" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
