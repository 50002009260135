import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getSaveSuccessMessage } from '../../const';

import { useChangePasswordMutation } from '../../store/api';

type ChangePasswordProps = {
  isOpen: boolean;
  close: () => void;
};

type FormType = {
  password: string;
  confirmPassword: string;
};

export const ChangePasswordDialog = ({ isOpen, close }: ChangePasswordProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
    setError,
  } = useForm<FormType>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const [triggerChangePassword, { isLoading }] = useChangePasswordMutation();
  const [errorMessage, setErrorMessage] = useState<string>();

  const onBackdropClick = (event: object, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }

    handleOnClose();
  };


  const handleOnClose = () => {
    close();
    reset();
    setErrorMessage("");
  };

  const onSubmit = (event: any) => {
    if (event) {
      if (typeof event.preventDefault === 'function') {
        event.preventDefault();
      }
      if (typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }
    }

    return handleSubmit(async (values) => {
      try {
        const password = {
          confirmPassword: values.confirmPassword,
          newPassword: values.password,
        };

        let result = await triggerChangePassword(password).unwrap();
        enqueueSnackbar(getSaveSuccessMessage(), { variant: 'success' });
        handleOnClose();
      } catch (ex: any) {
        setErrorMessage(ex?.data.Message);
        enqueueSnackbar(ex?.data.Message, { variant: 'error', style: { whiteSpace: 'pre-line' } });
        console.error(ex);
      }
    })(event);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onBackdropClick}
      maxWidth={'sm'}
      fullWidth
      //FIXME: look into solving the flicking issue, temporarily set to 0 to preventhe modal to be flickering when its state is changed
      transitionDuration={{ exit: 0 }}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Stack component="form" spacing={2}>
            <Controller
              name="password"
              control={control}
              rules={{
                required: 'Password is required',
                validate: {
                  validDate: (value) =>
                    getValues('confirmPassword').trim() !== ''
                      ? getValues('confirmPassword') === value ||
                        'Password and Confirm Password do not match'
                      : true,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ mt: 2 }}
                  variant="outlined"
                  label="Password"
                  type="password"
                  helperText={errors.password?.message}
                  error={!!errors.password}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              rules={{
                required: 'Confirm Password is required',
                validate: {
                  validDate: (value) =>
                    getValues('password').trim() !== ''
                      ? getValues('password') === value ||
                        'Password and Confirm Password do not match'
                      : true,
                },
              }}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="Confirm Password"
                  type="password"
                  helperText={errors.confirmPassword?.message}
                  error={!!errors.confirmPassword}
                />
              )}
            />
            <FormHelperText error>
              {errorMessage?.trim() !== ''
                ? errorMessage
                : ''}
            </FormHelperText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleOnClose} loading={isLoading}>
            Cancel
          </LoadingButton>
          <LoadingButton type="submit" loading={isLoading}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
