import { LoadingButton } from '@mui/lab';
import { Box, Chip, Grid, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { FormSkeleton } from '../../../components/Elements';
import { PERMISSIONS } from '../../../const';
import { useDisclosure } from '../../../hooks/useDisclosure';
import { getAuthorization } from '../../../lib/Authorization';
import { useGetSystemSettingsQuery, useProcessAccountYearEndMutation } from '../../../store/api';
import { getDateFormat } from '../../../types';
import { ProcessFinancialConfirmation } from './ProcessFinancialConfimation';

type FormType = {
  closingPeriod: string;
};

export const SystemActions = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    clearErrors,
    setValue,
    formState: { errors },
    getValues,
    setError,
  } = useForm<FormType>({
    defaultValues: {
      closingPeriod: '',
    },
  });

  const {
    close: closeFinancialConfirmation,
    open: openFinancialConfirmation,
    isOpen: isFinancialConfirmationOpen,
  } = useDisclosure();

  const { data, isFetching, error, isLoading } = useGetSystemSettingsQuery();
  const [triggerUpdateReportSettings, { isLoading: isSubmitting }] =
    useProcessAccountYearEndMutation();
  const hasEditPermission = getAuthorization(PERMISSIONS.YEAR_END_PROCESSING.CREATE);

  React.useEffect(() => {
    if (!!data && !isFetching) {
      setValue('closingPeriod', data.thisAccYearClosingPeriod.toString() || '12');
    }
  }, [data, isFetching]);

  const saveBatch = async (data: any) => {
    await triggerUpdateReportSettings(data);
  };

  const openConfirmationDialog = (data: any) => {
    if (Number.isNaN(Number(data.closingPeriod)) || data.closingPeriod.indexOf('.') != -1) {
      setError('closingPeriod', {
        type: 'custom',
        message: 'The period is not a valid number',
      });
      return;
    }
    openFinancialConfirmation();
  };

  const handleCancelClick = () => {
    navigate('/settings/report');
  };

  const [closingPeriod] = useWatch({
    control,
    name: ['closingPeriod'],
  });

  return isFetching ? (
    <FormSkeleton />
  ) : (
    <Grid
      container
      spacing={2}
      component="form"
      noValidate
      onSubmit={handleSubmit(openConfirmationDialog)}
      alignItems="center"
      justifyContent={'space-between'}
    >
      <Grid item xs={12}>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
            <Typography variant="h6" component="h1">
              Financial Cut Off
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Controller
          name="closingPeriod"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              InputProps={{
                readOnly: !hasEditPermission,
              }}
              variant="outlined"
              label="The program will close the account at period"
              type="text"
              helperText={errors.closingPeriod?.message}
              error={!!errors.closingPeriod}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }} display="flex" flexDirection={"row"} justifyContent="space-evenly">
        <Box>
          <Chip
            label={dayjs(data?.lastAccYearClosingDate)
              .add(1, 'day')
              .format(getDateFormat(data?.dateFormat || 'DD/MM/YYYY'))}
            variant="outlined"
          />
          {' ~ '}
          <Chip
            label={
              dayjs(data?.lastAccYearClosingDate).isValid() &&
              data?.lastAccYearClosingDate &&
              !Number.isNaN(Number(closingPeriod)) &&
              closingPeriod !== ''
                ? dayjs(data?.lastAccYearClosingDate)
                    .add(Number.parseInt(closingPeriod), 'month')
                    .format(getDateFormat(data?.dateFormat || 'DD/MM/YYYY'))
                : dayjs(data?.lastAccYearClosingDate)
                    .add(1, 'day')
                    .format(getDateFormat(data?.dateFormat || 'DD/MM/YYYY'))
            }
            variant="outlined"
          />
        </Box>
        <Box>
          {hasEditPermission && (
            <LoadingButton variant="contained" color="primary" type="submit" loading={isSubmitting}>
              Submit
            </LoadingButton>
          )}
        </Box>
      </Grid>

      <ProcessFinancialConfirmation
        item={closingPeriod}
        open={isFinancialConfirmationOpen}
        handleClose={closeFinancialConfirmation}
        submitAction={saveBatch}
        isLoading={isSubmitting}
      />
    </Grid>
  );
};
