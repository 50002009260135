import { Grid, Skeleton } from '@mui/material';
import { memo } from 'react';

export const FormSkeleton = memo(() => {
  return (
    <>
      <Grid container sx={{ mt: 1 }} spacing={4}>
        <Grid item xs={12}>
          <Skeleton variant="circular" sx={{ width: 150, height: 150, mx: 'auto' }} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" sx={{ width: '100%' }} height={50} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" sx={{ width: '100%' }} height={50} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" sx={{ width: '100%' }} height={50} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" sx={{ width: '100%' }} height={50} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" sx={{ width: '100%' }} height={50} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" sx={{ width: '100%' }} height={50} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" sx={{ width: '100%' }} height={50} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" sx={{ width: '100%' }} height={50} />
        </Grid>
      </Grid>
    </>
  );
});
