import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import { FormSkeleton } from '../../../components/Elements';
import { API_URL } from '../../../config';
import {
  useExportCategorizedStockReportMutation,
  useExportEndOfDayStockReportMutation,
  useExportStockQtyAdjustmentMutation,
  useExportStockTransactionsMutation,
  useGetAvailableReportsQuery,
  useGetDownloadFormatsQuery,
  useGetSystemSettingsQuery,
  useLazyGetCookieQuery,
} from '../../../store/api';
import { getDateFormat } from '../../../types';
import { CategorizedStockReport } from './components/CategorizedStockReport';
import { EndOfDayReportFilter } from './components/EndOfDayStock';
import { StockInOutReportFilter } from './components/StockInOutReportFilter';
import { StockQuantityAdjustmentFilter } from './components/StockQuantityAdjustmentFilter';
import {StockCardReport} from "./components/StockCardReport";

type ReportObject = {
  key: string;
  value: string;
  filter?: string;
};
export const ReportViewer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedValue, setSelectedValue] = useState<ReportObject | null>(null);
  const [filterObject, setFilterObject] = useState<any>(null);
  const [shouldViewReport, setShouldViewReport] = useState<boolean>(false);
  const { data: formatData, isFetching: formatFetching } = useGetDownloadFormatsQuery();
  const [triggerGetCookie] = useLazyGetCookieQuery();
  const { data: systemSettingsData, isFetching: systemSettingsFetching } =
    useGetSystemSettingsQuery();

  useEffect(() => {
    const getCookie = async () => {
      await triggerGetCookie();
    };

    getCookie();
  }, []);

  const { data, isFetching, error, isLoading } = useGetAvailableReportsQuery();

  const [triggerExportEndOfDay, { data: exportData, isLoading: exportUpdating }] =
    useExportEndOfDayStockReportMutation();
  const [triggerStockTransactionReport, { data: stockData, isLoading: stockUpdating }] =
    useExportStockTransactionsMutation();
  const [triggerExportCategorized, { data: categorizedData, isLoading: categorizeUpdating }] =
    useExportCategorizedStockReportMutation();
  const [triggerExportStockQtyAdjustment, { data: stockQtyData, isLoading: stockQtyLoading }] =
    useExportStockQtyAdjustmentMutation();

  const viewReport = async (data: any) => {
    setShouldViewReport(false);
    await triggerGetCookie();
    const filterString = !isNil(data)
      ? Object.keys(data)
          .map((key) => key + '=' + data[key])
          .join('&')
      : '';

    setSelectedValue((prev) => {
      if (prev != null) {
        return {
          ...prev,
          filter: filterString,
        };
      }

      return null;
    });
    setShouldViewReport(true);
  };

  const download = async (data: any) => {
    try {
      const filterString = !isNil(data)
        ? '?' +
          Object.keys(data)
            .map((key) => key + '=' + data[key])
            .join('&')
        : '';
      await triggerGetCookie();
      if (selectedValue?.key.toLowerCase() == 'Stock transaction report'.toLowerCase()) {
        enqueueSnackbar('Downloading... It may take awhile...', { variant: 'info' });
        // const result = await triggerStockTransactionReport(data).unwrap();
        const a = document.createElement('a');
        a.href = API_URL + '/Report/ExportStockTransactionsReport' + filterString;
        a.target = '_blank';
        a.click();

        enqueueSnackbar('Downloaded successfully!', { variant: 'success' });
      } else if (selectedValue?.key.toLowerCase() == 'End of day stock report'.toLowerCase()) {
        enqueueSnackbar('Downloading... It may take awhile...', { variant: 'info' });
        // const result = await triggerExportEndOfDay(data).unwrap();
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = API_URL + '/Report/ExportEndOfDayStockReport' + filterString;
        a.click();

        enqueueSnackbar('Downloaded successfully!', { variant: 'success' });
      } else if (selectedValue?.key.toLowerCase() == 'Categorized stock report'.toLowerCase()) {
        enqueueSnackbar('Downloading... It may take awhile...', { variant: 'info' });
        // const result = await triggerExportCategorized(data).unwrap();
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = API_URL + '/Report/ExportCategorizedStockReport' + filterString;
        a.click();

        enqueueSnackbar('Downloaded successfully!', { variant: 'success' });
      } else if (
        selectedValue?.key.toLowerCase() == 'Stock quantity adjustment report'.toLowerCase()
      ) {
        enqueueSnackbar('Downloading... It may take awhile...', { variant: 'info' });
        // const result = await triggerExportStockQtyAdjustment(data).unwrap();
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = API_URL + '/Report/ExportStockQtyAdjustment' + filterString;
        a.click();

        enqueueSnackbar('Downloaded successfully!', { variant: 'success' });
      } else if (
        selectedValue?.key.toLowerCase() == 'Stock card report'.toLowerCase()
      ) {
        enqueueSnackbar('Downloading... It may take awhile...', { variant: 'info' });
        // const result = await triggerExportStockQtyAdjustment(data).unwrap();
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = API_URL + '/Report/ExportStockCardReport' + filterString;
        a.click();

        enqueueSnackbar('Downloaded successfully!', { variant: 'success' });
      }
    } catch (ex) {
      enqueueSnackbar('Error when trying to export :(', { variant: 'error' });
    }
  };

  return (
    <>
      {isFetching || formatFetching || systemSettingsFetching ? (
        <FormSkeleton />
      ) : (
        <Box>
          <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" component="h1">
                Filter
              </Typography>
            </Box>
          </Box>

          <Grid container sx={{ my: 1 }} rowSpacing={2} columnSpacing={4}>
            <Grid item xs={12} md={3}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">Report</InputLabel>
                <Select
                  onChange={async (e: any) => {
                    let key =
                      (data &&
                        data.items &&
                        data.items.find((d) => d.url == e.target.value)?.reportName) ||
                      '';
                    setSelectedValue({ key: key, value: e.target.value });
                    setShouldViewReport(false);
                    setFilterObject(null);
                  }}
                  value={selectedValue?.value ?? ''}
                >
                  {data &&
                    data.items &&
                    data.items.map((d) => {
                      return (
                        <MenuItem value={d.url} key={d.reportName}>
                          {d.reportName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                {selectedValue?.key.toLowerCase() == 'Stock transaction report'.toLowerCase() ? (
                  <StockInOutReportFilter
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                    viewReport={viewReport}
                    download={download}
                    formatData={formatData}
                    loading={stockUpdating}
                    dateFormat={getDateFormat(systemSettingsData?.dateFormat || 'DD/MM/YYYY')}
                  />
                ) : selectedValue?.key.toLowerCase() == 'End of day stock report'.toLowerCase() ? (
                  <EndOfDayReportFilter
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                    viewReport={viewReport}
                    download={download}
                    formatData={formatData}
                    loading={exportUpdating}
                    dateFormat={getDateFormat(systemSettingsData?.dateFormat || 'DD/MM/YYYY')}
                  />
                ) : selectedValue?.key.toLowerCase() == 'Categorized stock report'.toLowerCase() ? (
                  <CategorizedStockReport
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                    viewReport={viewReport}
                    download={download}
                    formatData={formatData}
                    loading={exportUpdating}
                    dateFormat={getDateFormat(systemSettingsData?.dateFormat || 'DD/MM/YYYY')}
                  />
                ) : selectedValue?.key.toLowerCase() ==
                  'Stock quantity adjustment report'.toLowerCase() ? (
                  <StockQuantityAdjustmentFilter
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                    viewReport={viewReport}
                    download={download}
                    formatData={formatData}
                    loading={exportUpdating}
                    dateFormat={getDateFormat(systemSettingsData?.dateFormat || 'DD/MM/YYYY')}
                  />
                ) : selectedValue?.key.toLowerCase() ==
                'Stock card report'.toLowerCase() ? (
                    <StockCardReport
                        filterObject={filterObject}
                        setFilterObject={setFilterObject}
                        viewReport={viewReport}
                        download={download}
                        formatData={formatData}
                        loading={exportUpdating}
                        dateFormat={getDateFormat(systemSettingsData?.dateFormat || 'DD/MM/YYYY')}
                    />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>

          {!isNil(selectedValue) && shouldViewReport && (
            <Box sx={{ my: 4, width: '100%', height: '1200px' }}>
              <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h6" component="h1">
                    Report Viewer
                  </Typography>
                </Box>
              </Box>
              <Iframe
                iframe={
                  '<iframe height="1200px" style="border: none" width="100%" src=' +
                  API_URL +
                  selectedValue.value +
                  '?' +
                  selectedValue.filter +
                  ' />'
                }
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

function Iframe(props: any) {
  return <div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} />;
}
