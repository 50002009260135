import LoadingButton from '@mui/lab/LoadingButton';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { FormSkeleton, NotFound } from '../../../components/Elements/';
import { PERMISSIONS } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { useGetAllPermissionsQuery, useGetRoleQuery } from '../../../store/api';
import { FormDetails } from '../components/FormDetails';
import { PermissionDto } from '../types';

export const ViewRole = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { data, isLoading, error } = useGetRoleQuery(userId!);
  const {
    data: permissionData,
    isLoading: permissionLoading,
    error: permissionError,
  } = useGetAllPermissionsQuery();

  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: data?.name || '',
      description: data?.description || '',
      ...(permissionData &&
        Object.assign(
          {},
          ...permissionData.map((p: PermissionDto) => {
            return { [p.name]: data?.permissions.includes(p.name) || false };
          })
        )),
    },
  });

  useEffect(() => {
    reset(data);
    permissionData &&
      data &&
      permissionData.forEach((p: PermissionDto) => {
        setValue(p.name, data?.permissions.includes(p.name) || false);
      });
  }, [data, permissionData]);

  const handleEdit = () => {
    navigate(`/user-management/roles/edit/${userId}`);
  };

  const handleCancelClick = () => {
    navigate('/user-management/roles');
  };

  const hasViewPermission = getAuthorization(PERMISSIONS.ROLE.VIEW);
  const hasEditPermission = getAuthorization(PERMISSIONS.ROLE.EDIT);

  return (
    <>
      {!hasViewPermission ? (
        <NotFound snackbarText="Unauthorized to view page" text="Unauthorized to view page" />
      ) : isLoading || permissionLoading ? (
        <FormSkeleton />
      ) : error && 'data' in error && error.data.Message == 'User not found' ? (
        <NotFound snackbarText="User not found" text="User not found" />
      ) : error ? (
        <>Error loading user, please refresh the page</>
      ) : (
        <>
          <Box>
            <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" component="h1">
                  Information
                </Typography>
              </Box>
            </Box>
            <Grid container sx={{ mt: 1 }} spacing={4}>
              <Grid item xs={12}>
                <Avatar src="/asd.jpg" sx={{ width: 150, height: 150, mx: 'auto' }} />
              </Grid>

              <FormDetails
                errors={errors}
                control={control}
                permissionsMaster={permissionError ? undefined : permissionData}
                formState={'view'}
                getValues={getValues}
                setValue={setValue}
              />

              <Grid
                item
                xs={12}
                display={'flex'}
                sx={{
                  mx: { xs: 'auto', md: '0' },
                  justifyContent: { xs: 'center', md: 'flex-end' },
                }}
              >
                <Button sx={{ mr: 2 }} onClick={handleCancelClick}>
                  Cancel
                </Button>
                {hasEditPermission && (
                  <LoadingButton color="primary" onClick={handleEdit}>
                    Edit
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
