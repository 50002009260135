import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { FormSkeleton, NotFound } from '../../../components/Elements';
import { PERMISSIONS } from '../../../const';
import { getErrorMessage, getSaveSuccessMessage } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { useCreateRoleMutation, useGetAllPermissionsQuery } from '../../../store/api';
import { CreateOrUpdateRoleForm } from '../components/CreateOrUpdateRoleForm';
import { RoleDto } from '../types';

export const CreateRole = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    data: permissionData,
    isLoading: permissionLoading,
    error: permissionError,
  } = useGetAllPermissionsQuery();

  const [createRoleMutation, { isLoading: isUpdating }] = useCreateRoleMutation();
  const hasEditPermission = getAuthorization(PERMISSIONS.ROLE.EDIT);

  const handleSuccess = (id: string) => {
    if (hasEditPermission) navigate(`/user-management/roles/edit/${id}`);
    else navigate(`/user-management/roles/view/${id}`);
  };

  const updateRole = async (data: any) => {
    try {
      const result = await createRoleMutation(data).unwrap();

      if (!!result) {
        handleSuccess(result);
        enqueueSnackbar(getSaveSuccessMessage(), { variant: 'success' });
        handleSuccess(result);
      }
    } catch (err: any) {
      enqueueSnackbar(getErrorMessage(err?.data.Message.toString()), { variant: 'error' });
      throw err;
    }
  };

  const mapRole = (data: any, destination: any): RoleDto => {
    return destination as RoleDto;
  };

  const hasCreatePermission = getAuthorization(PERMISSIONS.ROLE.CREATE);

  return (
    <>
      {!hasCreatePermission ? (
        <NotFound snackbarText="Unauthorized to view page" text="Unauthorized to view page" />
      ) : permissionLoading ? (
        <FormSkeleton />
      ) : (
        <CreateOrUpdateRoleForm
          permissionsMaster={permissionError ? undefined : permissionData}
          mapRole={mapRole}
          submitAction={updateRole}
          isSubmitting={isUpdating}
        />
      )}
    </>
  );
};
