import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { Authorization } from '../../../lib/Authorization';

type AuthorizationButton = {
  variant: 'text' | 'outlined' | 'contained' | undefined;
  label: string;
  onClick?: () => void;
  to?: string;
  requiredPermission: string;
  size?: 'medium' | 'small' | 'large' | undefined;
  loading?: boolean;
  loadingText?: string;
};

export const AuthorizationButton = ({
  variant,
  label,
  onClick,
  to,
  requiredPermission,
  size = 'medium',
  loading,
  loadingText,
}: AuthorizationButton) => {
  return (
    <>
      <Authorization requiredRoles={requiredPermission}>
        {to ? (
          <Button variant={variant} size={size} component={Link} to={to}>
            {label}
          </Button>
        ) : (
          <LoadingButton
            variant={variant}
            size={size}
            onClick={onClick}
            loading={loading}
            {...(loadingText !== undefined && { loadingIndicator: loadingText })}
          >
            {label}
          </LoadingButton>
        )}
      </Authorization>
    </>
  );
};
