import { Box, Grid, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Controller } from 'react-hook-form';

import { PermissionTable } from './PermissionTable';

type FormDetailsProps = {
  control: any;
  errors?: any;
  permissionsMaster?: any;
  formState?: 'edit' | 'create' | 'view';
  getValues: any;
  setValue: any;
};

export const FormDetails = ({
  control,
  errors,
  permissionsMaster,
  formState,
  getValues,
  setValue,
}: FormDetailsProps) => {
  const isReadOnly = formState === 'view';

  return (
    <>
      <Grid item xs={12} md={6}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              required
              {...field}
              InputProps={{
                readOnly: isReadOnly,
              }}
              variant="outlined"
              label="Name"
              type="text"
              helperText={errors.name?.message}
              error={!!errors.name}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              InputProps={{
                readOnly: isReadOnly,
              }}
              variant="outlined"
              label="Description"
              type="text"
              helperText={errors.description?.message}
              error={!!errors.description}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
          <Typography variant="h6" component="h1">
            Access Rights
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <PermissionTable
          permissionData={permissionsMaster}
          control={control}
          getValues={getValues}
          setValue={setValue}
          isReadOnly={isReadOnly}
        />
      </Grid>
    </>
  );
};
