import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

type NotFoundProps = {
  snackbarText?: string;
  text?: string;
};

export const NotFound = ({
  snackbarText = 'Not found',
  text = 'Item not found',
}: NotFoundProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    enqueueSnackbar(snackbarText, { variant: 'error' });
    setTimeout(() => {
      navigate('/');
    }, 2000);
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h6">{text + '. Redirecting you back...'}</Typography>
    </Box>
  );
};
