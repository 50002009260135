const storagePrefix = 'LEC_';

const get = (name: string) => {
  return JSON.parse(window.localStorage.getItem(`${storagePrefix + name}`) as string);
};

const set = (name: string, value: string | string[]) => {
  window.localStorage.setItem(`${storagePrefix + name}`, JSON.stringify(value));
};

const remove = (name: string) => {
  window.localStorage.removeItem(`${storagePrefix + name}`);
};

const REFRESH_TOKEN = 'refresh_token';
const ACCESS_TOKEN = 'access_token';
const COMPANY_NAME = 'tenant';
const ROLES = 'roles';

const storage = {
  getAccessToken: () => {
    return get(ACCESS_TOKEN);
  },
  setAccessToken: (token: string) => {
    return set(ACCESS_TOKEN, token);
  },
  clearAccessToken: () => {
    remove(ACCESS_TOKEN);
  },
  getRefreshToken: () => {
    return get(REFRESH_TOKEN);
  },
  setRefreshToken: (token: string) => {
    return set(REFRESH_TOKEN, token);
  },
  clearRefreshToken: () => {
    remove(REFRESH_TOKEN);
  },
  getCompanyName: () => {
    return get(COMPANY_NAME);
  },
  setCompanyName: (companyName: string) => {
    return set(COMPANY_NAME, companyName);
  },
  clearCompanyName: () => {
    remove(COMPANY_NAME);
  },
  getRoles: () => {
    return get(ROLES);
  },
  setRoles: (roles: string[]) => {
    return set(ROLES, roles);
  },
  clearRoles: () => {
    remove(ROLES);
  },
};

export default storage;
