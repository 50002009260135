import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { findIndex, isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { useGetCategoriesQuery } from '../../../../store/api';
import { DropdownType } from '../../../../types';

type BatchReportFilterProps = {
  filterObject: any;
  setFilterObject: (data: any) => void;
  dateFormat?: string;
  viewReport: (data: any) => void;
  download: (data: any) => Promise<void>;
  formatData: any;
  loading: boolean;
};
export const StockInOutReportFilter = ({
  filterObject,
  setFilterObject,
  dateFormat = 'DD/MM/YYYY',
  viewReport,
  download,
  formatData,
  loading,
}: BatchReportFilterProps) => {
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  dayjs().locale('en_MY');
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<{
    fromDate: any;
    toDate: any;
    include0Transaction: any;
    include0QuantityItem: any;
    fromCategory: any;
    toCategory: any;
  }>({
    defaultValues: {
      fromDate: filterObject?.fromDate
        ? dayjs(filterObject?.fromDate)
        : dayjs().format('YYYY-MM-DD'),
      toDate: filterObject?.toDate ? dayjs(filterObject?.toDate) : dayjs().format('YYYY-MM-DD'),
      include0Transaction: !isNil(filterObject?.include0Transaction)
        ? filterObject.include0Transaction
        : false,
      include0QuantityItem: !isNil(filterObject?.include0QuantityItem)
        ? filterObject.include0QuantityItem
        : false,
    },
  });

  const autocompleteTo = useRef<HTMLElement>(null);
  const { data: masterData, isLoading, error } = useGetCategoriesQuery();

  const [fromCategory, setFromCategory] = useState<DropdownType[]>([]);
  const [toCategory, setToCategory] = useState<DropdownType[]>([]);

  useEffect(() => {
    if (!isLoading && masterData && masterData.items) {
      const items = masterData.items;
      setFromCategory(items);
      setToCategory(items);
    }
  }, [isLoading, masterData]);

  const [
    fromDate,
    toDate,
    include0QuantityItem,
    include0Transaction,
    fromCategoryField,
    toCategoryField,
  ] = useWatch({
    control,
    name: [
      'fromDate',
      'toDate',
      'include0QuantityItem',
      'include0Transaction',
      'fromCategory',
      'toCategory',
    ],
  });

  const downloadReport = async (fileType: number) => {
    await download({
      ...(!isNil(fromDate) && { fromDate: dayjs(fromDate).format('YYYY-MM-DD') }),
      ...(!isNil(toDate) && { toDate: dayjs(toDate).format('YYYY-MM-DD') }),
      ...(!isNil(include0Transaction) && { include0Transaction: include0Transaction }),
      ...(!isNil(include0QuantityItem) && { include0QuantityItem: include0QuantityItem }),
      ...(!isNil(fromCategoryField) && { fromCategory: fromCategoryField.value }),
      ...(!isNil(toCategoryField) && { toCategory: toCategoryField.value }),
      fileType: fileType,
    });
  };

  const onSubmit = (values: any) => {
    const { fromDate, toDate } = values;
    viewReport({
      ...(!isNil(fromDate) && { fromDate: dayjs(fromDate).format('YYYY-MM-DD') }),
      ...(!isNil(toDate) && { toDate: dayjs(toDate).format('YYYY-MM-DD') }),
      ...(!isNil(include0Transaction) && { include0Transaction: include0Transaction }),
      ...(!isNil(include0QuantityItem) && { include0QuantityItem: include0QuantityItem }),
      ...(!isNil(fromCategoryField) && { fromCategory: fromCategoryField.value }),
      ...(!isNil(toCategoryField) && { toCategory: toCategoryField.value }),
    });
  };

  useEffect(() => {
    if ((toCategoryField && fromCategoryField) || (!toCategoryField && !fromCategoryField)) {
      clearErrors();
    }
  }, [toCategoryField, fromCategoryField]);

  return (
    <Grid item xs={12} md={12}>
      <Grid
        container
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
        noValidate
        display="flex"
        alignItems={'flex-start'}
      >
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Controller
                name="fromDate"
                rules={{
                  required: false,
                  validate: {
                    validDate: (value) =>
                      value ? dayjs(value).isValid() || 'Invalid From Date' : true,
                    isBeforeToDate: (value) =>
                      dayjs(value).isValid() && dayjs(toDate).isValid()
                        ? dayjs(value).isSameOrBefore(dayjs(toDate)) ||
                          'From Date cannot be after To Date'
                        : true,
                  },
                }}
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="frFr">
                    <DatePicker
                      label="From Date"
                      value={field.value}
                      inputFormat={dateFormat}
                      onChange={(newValue: any) => {
                        field.onChange(newValue);
                        setFilterObject({
                          ...filterObject,
                          fromDate: dayjs(newValue).format('YYYY-MM-DD'),
                        });
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          fullWidth
                          {...params}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          variant="standard"
                          error={!!errors.fromDate}
                          helperText={errors.fromDate?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Controller
                name="toDate"
                rules={{
                  required: false,
                  validate: {
                    validDate: (value) =>
                      value ? dayjs(value).isValid() || 'Invalid To Date' : true,
                    isAfterFromDate: (value) =>
                      dayjs(value).isValid() && dayjs(fromDate).isValid()
                        ? dayjs(value).isSameOrAfter(dayjs(fromDate)) ||
                          'To Date cannot be before From Date'
                        : true,
                  },
                }}
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="frFr">
                    <DatePicker
                      label="To Date"
                      value={field.value}
                      inputFormat={dateFormat}
                      onChange={(newValue: any) => {
                        field.onChange(newValue);
                        setFilterObject({
                          ...filterObject,
                          toDate: dayjs(newValue).format('YYYY-MM-DD'),
                        });
                      }}
                      minDate={dayjs(fromDate)}
                      renderInput={(params: any) => (
                        <TextField
                          fullWidth
                          {...params}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          variant="standard"
                          error={!!errors.toDate}
                          helperText={errors.toDate?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Controller
                    name="include0Transaction"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e: any) => {
                          field.onChange(e.target.checked);
                          setFilterObject({
                            ...filterObject,
                            include0Transaction: e.target.checked,
                          });
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label="Include Zero Transaction"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Controller
                    name="include0QuantityItem"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e: any) => {
                          field.onChange(e.target.checked);
                          setFilterObject({
                            ...filterObject,
                            include0QuantityItem: e.target.checked,
                          });
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label="Include Zero Quantity Item"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Controller
                name="fromCategory"
                control={control}
                rules={{
                  required: toCategoryField != null,
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    autoHighlight
                    value={field.value ?? null}
                    onChange={(_, data) => {
                      field.onChange(data);

                      if (data) {
                        const index = findIndex(
                          fromCategory,
                          (x: DropdownType) => x.value == data.value
                        );
                        const clone = fromCategory.slice(index, fromCategory.length);
                        setToCategory(clone);

                        if (clone.length == 1) {
                          const lastItem = clone[0];
                          setValue('toCategory', lastItem);
                        } else {
                          if (toCategoryField) {
                            const toIndex = findIndex(
                              fromCategory,
                              (x: DropdownType) => x.value == toCategoryField.value
                            );
                            if (toIndex < index) {
                              const ele =
                                autocompleteTo.current != null
                                  ? (autocompleteTo.current.getElementsByClassName(
                                      'MuiAutocomplete-clearIndicator'
                                    )[0] as HTMLElement)
                                  : null;
                              if (ele) ele.click();
                            }
                            // setValue('toCategory', null);
                          }
                        }
                      } else {
                        setToCategory(masterData!.items || []);
                      }
                    }}
                    filterOptions={(x) => x}
                    options={fromCategory || []}
                    isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                    getOptionLabel={(option: any) => (option ? option?.displayName : null)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={toCategoryField != null}
                        label="From Category"
                        error={!!errors.fromCategory}
                        helperText={!!errors.fromCategory && 'From Category is required'}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
              <Controller
                name="toCategory"
                control={control}
                rules={{
                  required: fromCategoryField != null,
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    autoHighlight
                    value={field.value ?? null}
                    ref={autocompleteTo}
                    onChange={(_, data) => {
                      field.onChange(data);
                    }}
                    // MenuProps={{ PaperProps: { style: { maxHeight: '300px' } } }}
                    filterOptions={(x) => x}
                    options={toCategory || []}
                    isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                    getOptionLabel={(option: any) => (option ? option?.displayName : null)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={fromCategoryField != null}
                        label="To Category"
                        error={!!errors.toCategory}
                        helperText={!!errors.toCategory && 'To Category is required'}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              flexDirection={'row'}
              display="flex"
              sx={{ ml: 'auto' }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button type="submit" sx={{ mr: 2 }} variant="outlined">
                View
              </Button>
              <LoadingButton
                onClick={handleClick}
                loading={loading}
                variant="contained"
                size="medium"
                disabled={!!errors.fromDate || !!errors.toDate}
              >
                Export
              </LoadingButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {formatData &&
                  formatData?.items.map((f: any) => {
                    return (
                      <MenuItem key={f.value} onClick={() => downloadReport(Number(f.value))}>
                        {f.displayName}
                      </MenuItem>
                    );
                  })}
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
