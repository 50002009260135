import { axios } from '../../../lib/axios';
import { LoginCredentialsDTO, UserResponse } from '../types';

export const loginWithUsernameAndPassword = (data: LoginCredentialsDTO): Promise<UserResponse> => {
  const { companyName, ...loginCred } = data;
  return axios.post('/Account/GetToken', loginCred, {
    headers: {
      'X-Tenant': companyName,
    },
  });
};
