import { LoadingButton } from '@mui/lab';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { FormSkeleton } from '../../../components/Elements';
import { PERMISSIONS } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { useGetReportSettingsQuery, useUpdateReportSettingsMutation } from '../../../store/api';
import { ReportSettingsDto } from '../types';

type FormType = {
  companyEmail: string;
  companyAddress: string;
  companyName: string;
  companyPhoneNumber: string;
};

export const ReportSettings = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    clearErrors,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<FormType>({
    defaultValues: {
      companyEmail: '',
      companyAddress: '',
      companyName: '',
      companyPhoneNumber: '',
    },
  });

  const { data, isFetching, error, isLoading } = useGetReportSettingsQuery();
  const [triggerUpdateReportSettings, { isLoading: isSubmitting }] =
    useUpdateReportSettingsMutation();
  const hasEditPermission = getAuthorization(PERMISSIONS.REPORTSETTINGS.EDIT);

  React.useEffect(() => {
    if (!!data && !isFetching) {
      setValue('companyEmail', data.companyEmail || '');
      setValue('companyPhoneNumber', data.companyPhoneNumber || '');
      setValue('companyAddress', data.companyAddress || '');
      setValue('companyName', data.companyName || '');
    }
  }, [data, isFetching]);

  const saveBatch = (data: any) => {
    triggerUpdateReportSettings(data as ReportSettingsDto);
  };

  const handleCancelClick = () => {
    navigate('/settings/report');
  };

  return isFetching ? (
    <FormSkeleton />
  ) : (
    <Grid container spacing={2} component="form" noValidate onSubmit={handleSubmit(saveBatch)}>
      <Grid item xs={12}>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
            <Typography variant="h6" component="h1">
              Company Profile
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Controller
          name="companyName"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              InputProps={{
                readOnly: !hasEditPermission,
              }}
              variant="outlined"
              label="Company Name"
              type="text"
              helperText={errors.companyName?.message}
              error={!!errors.companyName}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Controller
          name="companyEmail"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              InputProps={{
                readOnly: !hasEditPermission,
              }}
              variant="outlined"
              label="Company Email"
              type="text"
              helperText={errors.companyEmail?.message}
              error={!!errors.companyEmail}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Controller
          name="companyPhoneNumber"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              InputProps={{
                readOnly: !hasEditPermission,
              }}
              variant="outlined"
              label="Company Phone Number"
              type="text"
              helperText={errors.companyPhoneNumber?.message}
              error={!!errors.companyPhoneNumber}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Controller
          name="companyAddress"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              variant="outlined"
              InputProps={{
                readOnly: !hasEditPermission,
              }}
              multiline
              minRows={2}
              maxRows={2}
              label="Company Adress"
              type="text"
              helperText={errors.companyAddress?.message}
              error={!!errors.companyAddress}
            />
          )}
        />
      </Grid>

      <Grid
        item
        xs={12}
        display={'flex'}
        sx={{ mx: { xs: 'auto', md: '0' }, justifyContent: { xs: 'center', md: 'flex-end' } }}
      >
        <LoadingButton sx={{ mr: 2 }} onClick={handleCancelClick} loading={isSubmitting}>
          Cancel
        </LoadingButton>
        {hasEditPermission && (
          <LoadingButton variant="contained" color="primary" type="submit" loading={isSubmitting}>
            Submit
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  );
};
