import { Box } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthorizationButton } from '../../../components/Elements';
import { PERMISSIONS } from '../../../const';
import { useGetRolesQuery } from '../../../store/api';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { setRoleSearchFilter } from '../../../store/search';
import { LargeTable } from '../../../types';
import { RolesFilter } from '../components/RolesFilter';
import { RolesTable } from '../components/RolesTable';

export const Roles = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(LargeTable);
  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();
  const { roleFilter } = useAppSelector((state) => state.search);

  const { data, isFetching, error, isLoading } = useGetRolesQuery(
    Object.assign({}, { PageNumber: page, RecordsPerPage: pageSize }, { ...roleFilter })
  );

  const handleChangePageSize = (event: ChangeEvent<HTMLInputElement>) => {
    setPageSize(Number(event.target.value));
    setPage(0);
  };

  const handlePageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const setFilterObject = (value: any) => {
    setPage(0);
    dispatch(setRoleSearchFilter(value));
  };

  const handleEditRow = (id: string) => {
    navigate(`edit/${id}`);
  };

  const handleViewRow = (id: string) => {
    navigate(`view/${id}`);
  };

  let content = (
    <Box sx={{ height: '80vh' }}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'flex-start' }}
      >
        <RolesFilter
          existingFilterObject={roleFilter}
          handleFilterChange={setFilterObject}
          isFetching={isFetching}
        />
        <AuthorizationButton
          variant="contained"
          size="medium"
          to="/user-management/roles/create"
          requiredPermission={PERMISSIONS.ROLE.CREATE}
          label="Add Role"
        />
      </Box>

      <RolesTable
        pageSize={pageSize}
        page={page}
        isFetching={isFetching}
        handleEditRow={handleEditRow}
        handleViewRow={handleViewRow}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        data={isLoading || error ? undefined : data}
      />
    </Box>
  );

  return <>{content}</>;
};
