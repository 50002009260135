import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Fragment, memo, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { PermissionTableHeader, mapPermissionToItem } from '../types';

export type PermissionTableProps = {
  permissionData: any;
  control: any;
  getValues: any;
  setValue: any;
  isReadOnly: boolean;
};

export const PermissionTable = memo(
  ({ permissionData, control, getValues, setValue, isReadOnly }: PermissionTableProps) => {
    const permissionsMapped = useMemo(
      () => permissionData && mapPermissionToItem(permissionData),
      [permissionData]
    );

    return permissionsMapped ? (
      <Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={0} variant="outlined">
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {PermissionTableHeader.map((column: string) => (
                  <TableCell key={'header' + column} sx={{ flex: 1, p: { xs: 1, md: 2 } }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(permissionsMapped).map((o, index) => {
                let groupedHeader = o;
                return (
                  <Fragment key={'ct' + groupedHeader + index}>
                    <TableRow role="checkbox" tabIndex={-1} key={'r' + groupedHeader + index}>
                      <TableCell
                        colSpan={6}
                        key={'c' + groupedHeader + index}
                        sx={{ p: { xs: 1, md: 2 } }}
                      >
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {groupedHeader.charAt(0).toUpperCase() + groupedHeader.slice(1)}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {Object.keys(permissionsMapped[groupedHeader]).map((item: any, i: number) => {
                      const row = item;
                      return (
                        <TableRow key={'r' + row + i}>
                          <TableCell key={'c' + row + index} sx={{ p: { xs: 1, md: 2 } }}>
                            {row.includes('_')
                              ? row
                                  .split('_')
                                  .map(
                                    (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
                                  )
                                  .join(' ')
                              : row.charAt(0).toUpperCase() + row.slice(1)}
                          </TableCell>

                          {PermissionTableHeader.filter((a) => a !== 'Permissions').map(
                            (column2: string, j: number) => {
                              const columnName =
                                permissionsMapped[groupedHeader][row][column2.toLowerCase()];
                              return (
                                <TableCell key={row + column2 + j} sx={{ p: { xs: 1, md: 2 } }}>
                                  {columnName !== undefined ? (
                                    <Controller
                                      name={columnName}
                                      control={control}
                                      defaultValue={false}
                                      key={'controller' + row + column2 + j}
                                      render={({ field }) => (
                                        <Checkbox
                                          key={'cb' + row + column2 + j}
                                          disabled={isReadOnly}
                                          onChange={(e: any) => {
                                            const action = e.target.checked;
                                            field.onChange(action);
                                            const currentRow =
                                              permissionsMapped[groupedHeader][row];
                                            const viewPermissionForRow = getValues(
                                              currentRow['view']
                                            );
                                            if (
                                              column2.toLowerCase() !== 'view' &&
                                              !viewPermissionForRow &&
                                              action
                                            ) {
                                              setValue(currentRow['view'], true);
                                            } else if (
                                              column2.toLowerCase() === 'view' &&
                                              !action
                                            ) {
                                              setValue(currentRow['create'], false);
                                              setValue(currentRow['edit'], false);
                                              setValue(currentRow['delete'], false);
                                            }
                                          }}
                                          checked={field.value}
                                        />
                                      )}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    ) : (
      <>
        Failed to load permissions, please contact system administrator or try refreshing the page.
      </>
    );
  }
);
