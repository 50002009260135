import { Route, Routes } from 'react-router';

import { ReportViewer } from './ReportViewer';

export const Reports = () => {
  return (
    <Routes>
      <Route path="/" element={<ReportViewer />} />
    </Routes>
  );
};
