import { Box } from '@mui/material';

type MobileTextLabelProps = {
  labelName?: string;
  field?: string | boolean | number | Date;
  fieldTransform?: (value: any) => string;
};

export const MobileTextLabel = ({ labelName, field, fieldTransform }: MobileTextLabelProps) => {
  return (
    <>
      {typeof field !== 'undefined' && field.toString() != '' ? (
        <>
          <Box component="span" fontWeight="bold" sx={{ display: 'inline-block' }}>
            {labelName + ':'}
          </Box>
          {typeof field === 'boolean' ? (
            <> {field ? 'Yes' : 'No'}</>
          ) : (
            <> {fieldTransform ? fieldTransform(field) : field}</>
          )}
          <br />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
