import { BreadcrumbPath } from '../components/Elements';

const breadcrumbName: { [key: string]: BreadcrumbPath[] } = {
  '/': [{ name: 'Home', path: '/' }],
  '/user-management/users': [{ name: 'User Management', path: '/user-management/users' }],
  '/user-management/users/create': [
    { name: 'User Management', path: '/user-management/users' },
    { name: 'Create', path: '/user-management/users/create' },
  ],
  '/user-management/users/view': [
    { name: 'User Management', path: '/user-management/users' },
    { name: 'View', path: '/user-management/users/view' },
  ],
  '/user-management/users/edit': [
    { name: 'User Management', path: '/user-management/users' },
    { name: 'Edit', path: '/user-management/users/create' },
  ],
  '/user-management/roles': [{ name: 'Role Management', path: '/user-management/roles' }],
  '/user-management/roles/create': [
    { name: 'Role Management', path: '/user-management/roles' },
    { name: 'Create', path: '/user-management/roles/create' },
  ],
  '/user-management/roles/view': [
    { name: 'Role Management', path: '/user-management/roles' },
    { name: 'View', path: '/user-management/roles/view' },
  ],
  '/user-management/roles/edit': [
    { name: 'Role Management', path: '/user-management/roles' },
    { name: 'Edit', path: '/user-management/users/create' },
  ],
  '/settings/system': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'System', path: '/settings/system' },
  ],
  '/settings/master-data': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
  ],
  '/settings/master-data/car-brand': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Car Brand', path: '/settings/master-data/car-brand' },
  ],
  '/settings/master-data/car-brand/create': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Car Brand', path: '/settings/master-data/car-brand' },
    { name: 'Create', path: '/settings/master-data/car-brand/create' },
  ],
  '/settings/master-data/car-brand/edit': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Car Brand', path: '/settings/master-data/car-brand' },
    { name: 'Edit', path: '/settings/master-data/car-brand/edit' },
  ],
  '/settings/master-data/car-brand/view': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Car Brand', path: '/settings/master-data/car-brand' },
    { name: 'View', path: '/settings/master-data/car-brand/view' },
  ],
  '/settings/master-data/colour': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Colour', path: '/settings/master-data/colour' },
  ],
  '/settings/master-data/colour/create': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Colour', path: '/settings/master-data/colour' },
    { name: 'Create', path: '/settings/master-data/colour/create' },
  ],
  '/settings/master-data/colour/edit': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Colour', path: '/settings/master-data/colour' },
    { name: 'Edit', path: '/settings/master-data/colour/edit' },
  ],
  '/settings/master-data/colour/view': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Colour', path: '/settings/master-data/colour' },
    { name: 'View', path: '/settings/master-data/colour/view' },
  ],
  '/settings/master-data/car-model': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Car Model', path: '/settings/master-data/car-model' },
  ],
  '/settings/master-data/car-model/create': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Car Model', path: '/settings/master-data/car-model' },
    { name: 'Create', path: '/settings/master-data/car-model/create' },
  ],
  '/settings/master-data/car-model/edit': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Car Model', path: '/settings/master-data/car-model' },
    { name: 'Edit', path: '/settings/master-data/car-model/edit' },
  ],
  '/settings/master-data/car-model/view': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Car Model', path: '/settings/master-data/car-model' },
    { name: 'View', path: '/settings/master-data/car-model/view' },
  ],
  '/settings/master-data/material': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Material', path: '/settings/master-data/material' },
  ],
  '/settings/master-data/material/create': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Material', path: '/settings/master-data/material' },
    { name: 'Create', path: '/settings/master-data/material/create' },
  ],
  '/settings/master-data/material/edit': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Material', path: '/settings/master-data/material' },
    { name: 'Edit', path: '/settings/master-data/material/edit' },
  ],
  '/settings/master-data/material/view': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Material', path: '/settings/master-data/material' },
    { name: 'View', path: '/settings/master-data/material/view' },
  ],

  '/settings/master-data/feature': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Feature', path: '/settings/master-data/feature' },
  ],
  '/settings/master-data/feature/create': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Feature', path: '/settings/master-data/feature' },
    { name: 'Create', path: '/settings/master-data/feature/create' },
  ],
  '/settings/master-data/feature/edit': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Feature', path: '/settings/master-data/feature' },
    { name: 'Edit', path: '/settings/master-data/feature/edit' },
  ],
  '/settings/master-data/feature/view': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Feature', path: '/settings/master-data/feature' },
    { name: 'View', path: '/settings/master-data/feature/view' },
  ],

  '/settings/master-data/series': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Series', path: '/settings/master-data/series' },
  ],
  '/settings/master-data/series/create': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Series', path: '/settings/master-data/series' },
    { name: 'Create', path: '/settings/master-data/series/create' },
  ],
  '/settings/master-data/series/view': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Series', path: '/settings/master-data/series' },
    { name: 'View', path: '/settings/master-data/series/view' },
  ],
  '/settings/master-data/series/edit': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Series', path: '/settings/master-data/series' },
    { name: 'Edit', path: '/settings/master-data/series/edit' },
  ],
  '/settings/master-data/item': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Item', path: '/settings/master-data/item' },
  ],
  '/settings/master-data/item/create': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Item', path: '/settings/master-data/item' },
    { name: 'Create', path: '/settings/master-data/item/create' },
  ],
  '/settings/master-data/item/edit': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Item', path: '/settings/master-data/item' },
    { name: 'Edit', path: '/settings/master-data/item/edit' },
  ],
  '/settings/master-data/item/view': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Master Data', path: '/settings/master-data' },
    { name: 'Item', path: '/settings/master-data/item' },
    { name: 'View', path: '/settings/master-data/item/view' },
  ],
  '/stocks/listing': [
    { name: 'Stocks', path: '/stocks/listing' },
    { name: 'Listing', path: '/stocks/listing' },
  ],
  '/stocks/batches': [
    { name: 'Stocks', path: '/stocks/batches' },
    { name: 'Batches', path: '/stocks/batches' },
  ],
  '/stocks/batches/create': [
    { name: 'Stocks', path: '/stocks/batches' },
    { name: 'Batches', path: '/stocks/batches' },
    { name: 'Create', path: '/stocks/batches/create' },
  ],
  '/stocks/batches/edit': [
    { name: 'Stocks', path: '/stocks/batches' },
    { name: 'Batches', path: '/stocks/batches' },
    { name: 'Edit', path: '/stocks/batches/edit' },
  ],
  '/stocks/batches/view': [
    { name: 'Stocks', path: '/stocks/batches' },
    { name: 'Batches', path: '/stocks/batches' },
    { name: 'View', path: '/stocks/batches/view' },
  ],
  '/stocks/stock-in': [
    { name: 'Stocks', path: '/stocks/stock-in' },
    { name: 'Stock In', path: '/stocks/stock-in' },
  ],
  '/stocks/stock-in/view': [
    { name: 'Stocks', path: '/stocks/stock-in' },
    { name: 'Stock In', path: '/stocks/stock-in' },
    { name: 'View', path: '/stocks/stock-in/view' },
  ],
  '/stocks/stock-in/create': [
    { name: 'Stocks', path: '/stocks/stock-in' },
    { name: 'Stock In', path: '/stocks/stock-in' },
    { name: 'Create', path: '/stocks/stock-in/create' },
  ],
  '/stocks/stock-in/edit': [
    { name: 'Stocks', path: '/stocks/stock-in' },
    { name: 'Stock In', path: '/stocks/stock-in' },
    { name: 'Edit', path: '/stocks/stock-in/edit' },
  ],
  '/stocks/stock-out': [
    { name: 'Stocks', path: '/stocks/stock-out' },
    { name: 'Stock Out', path: '/stocks/stock-out' },
  ],
  '/stocks/stock-out/create': [
    { name: 'Stocks', path: '/stocks/stock-out' },
    { name: 'Stock Out', path: '/stocks/stock-out' },
    { name: 'Create', path: '/stocks/stock-in/create' },
  ],
  '/stocks/stock-out/view': [
    { name: 'Stocks', path: '/stocks/stock-out' },
    { name: 'Stock Out', path: '/stocks/stock-out' },
    { name: 'View', path: '/stocks/stock-out/view' },
  ],
  '/stocks/stock-out/edit': [
    { name: 'Stocks', path: '/stocks/stock-out' },
    { name: 'Stock Out', path: '/stocks/stock-out' },
    { name: 'Edit', path: '/stocks/stock-out/edit' },
  ],
  '/settings/reports': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'Reports', path: '/settings/reports' },
  ],
  '/reports': [
    { name: 'Reports', path: '/reports' },
  ],
  '/settings/system-actions': [
    { name: 'Settings', path: '/settings/system' },
    { name: 'System Actions', path: '/settings/system-actions' },
  ],
};

export const getBreadcrumbName = (pathname: string): BreadcrumbPath[] => {
  return breadcrumbName[pathname];
};
