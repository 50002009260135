import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { cloneElement, useEffect } from 'react';

import { useDisclosure } from '../../../hooks/useDisclosure';

export type ConfirmationDialogProps = {
  triggerButton: React.ReactElement;
  confirmButton: React.ReactElement;
  title: string;
  isLoading?: boolean;
  body?: string;
  cancelButtonText?: string;
  isDone?: boolean;
  width?: 'sm' | 'md' | 'lg';
  callback?: () => void;
};

export const ConfirmationDialog = ({
  triggerButton,
  confirmButton,
  title,
  isLoading,
  body = '',
  cancelButtonText = 'Cancel',
  isDone = false,
  width = 'sm',
  callback,
}: ConfirmationDialogProps) => {
  const { close, open, isOpen } = useDisclosure();

  useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);

  const trigger = cloneElement(triggerButton, {
    onClick: open,
  });

  return (
    <>
      {trigger}
      <Dialog
        open={isOpen}
        fullWidth
        onClose={() => {
          if (!isLoading) {
            callback && callback();
            close();
          }
        }}
        maxWidth={width}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        {body && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              close();
              callback && callback();
            }}
            disabled={isLoading}
          >
            {cancelButtonText}
          </Button>
          {confirmButton}
        </DialogActions>
      </Dialog>
    </>
  );
};
