import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Controller, useForm } from 'react-hook-form';

import { useDisclosure } from '../../../hooks/useDisclosure';
import { useGetAllRolesQuery } from '../../../store/api';
import { GetUsersFilterDto } from '../types';

type UsersFilterProps = {
  handleFilterChange: (value: any) => void;
  isFetching?: boolean;
  existingFilterObject?: GetUsersFilterDto | null | undefined;
};

export const UsersFilter = ({
  isFetching,
  handleFilterChange,
  existingFilterObject,
}: UsersFilterProps) => {
  const { close, open, isOpen } = useDisclosure();
  const theme = useTheme();
  const biggerThanSm = useMediaQuery(theme.breakpoints.up('md'));

  const { data: roleData } = useGetAllRolesQuery();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: existingFilterObject?.firstName || '',
      lastName: existingFilterObject?.lastName || '',
      userName: existingFilterObject?.userName || '',
      role: existingFilterObject?.role || '',
    },
  });

  const content = (
    <>
      <Grid item xs={12} md={6}>
        <Controller
          name="userName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth={!biggerThanSm}
              sx={{ width: '100%' }}
              type="search"
              variant="standard"
              label="Username"
              helperText={errors.userName?.message}
              error={!!errors.userName}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth={!biggerThanSm}
              sx={{ width: '100%' }}
              type="search"
              variant="standard"
              label="First Name"
              helperText={errors.firstName?.message}
              error={!!errors.firstName}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth={!biggerThanSm}
              sx={{ width: '100%' }}
              type="search"
              variant="standard"
              label="Last Name"
              helperText={errors.lastName?.message}
              error={!!errors.lastName}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <FormControl variant="standard" fullWidth={!biggerThanSm} sx={{ minWidth: '100%' }}>
              <InputLabel id="demo-simple-select-standard-label">Role</InputLabel>
              <Select {...field}>
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {roleData &&
                  roleData.map((role: any) => {
                    return (
                      <MenuItem key={role.displayName} value={role.value}>
                        {role.displayName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
    </>
  );

  const onSubmit = (event: any) => {
    if (event) {
      if (typeof event.preventDefault === 'function') {
        event.preventDefault();
      }
      if (typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }
    }

    return handleSubmit(async (values) => {
      if (!biggerThanSm) {
        close();
      }
      handleFilterChange(values);
    })(event);
  };

  const largeDesktop = (
    <Box sx={{ mr: 4, minWidth: '80%' }}>
      <Accordion elevation={0} variant="outlined">
        <AccordionSummary
          sx={{ marginTop: 0 }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Filter</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mr: 4 }}>
          <Grid
            container
            sx={{ marginRight: 3, itemsAlign: 'flex-end' }}
            spacing={2}
            component="form"
            onSubmit={onSubmit}
          >
            <Grid item md={10}>
              <Grid container columnSpacing={3} rowSpacing={2}>
                {content}
              </Grid>
            </Grid>
            <Grid item md={2} sx={{ itemsAlign: 'flex-start', display: 'flex' }}>
              <LoadingButton
                sx={{ alignSelf: 'flex-start' }}
                loading={isFetching}
                variant="outlined"
                size="medium"
                type="submit"
              >
                Search
              </LoadingButton>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  const mobile = (
    <>
      <Button startIcon={<SearchIcon />} onClick={open} variant="outlined">
        Search
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={close}
        aria-labelledby="modal-modal-title"
      >
        <form onSubmit={onSubmit} noValidate>
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Search
              </Typography>
              <IconButton color="inherit" aria-label="close modal" onClick={close}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3} direction="column">
              {content}
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              sx={{ alignSelf: 'center' }}
              loading={isFetching}
              variant="outlined"
              size="medium"
              type="submit"
            >
              Search
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );

  return biggerThanSm ? largeDesktop : mobile;
};
