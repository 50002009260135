import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import { FormSkeleton, NotFound } from '../../../components/Elements/';
import { getErrorMessage, getSaveSuccessMessage } from '../../../const';
import { PERMISSIONS } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import {
  useGetAllRolesQuery,
  useGetUserQuery,
  useResetPasswordMutation,
  useUpdateUserMutation,
} from '../../../store/api';
import { CreateOrUpdateUserForm } from '../components/CreateOrUpdateUserForm';
import { UpdateUserDto, mapUserToUpdateDto } from '../types';

export const UpdateUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { data, isLoading, error } = useGetUserQuery(userId!);
  const { data: roleData, isLoading: isRoleLoading, error: roleError } = useGetAllRolesQuery();
  const [updateUserMutation, { error: errorUpdate, isLoading: isUpdating }] =
    useUpdateUserMutation();
  const [resetPasswordMutation, { error: errorPasswordUpdate, isLoading: isResettingPassword }] =
    useResetPasswordMutation();

  const handleSuccess = () => {
    navigate(`/user-management/users/edit/${userId}`);
  };

  const updateUser = async (data: any) => {
    try {
      await updateUserMutation(data).unwrap();
      enqueueSnackbar(getSaveSuccessMessage(), { variant: 'success' });
      handleSuccess();
    } catch (err: any) {
      enqueueSnackbar(getErrorMessage(err?.data.Message), { variant: 'error' });
      throw err;
    }
  };

  const resetPassword = async (data: any) => {
    try {
      await resetPasswordMutation(data).unwrap();
      enqueueSnackbar('Password reset successfully', { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(getErrorMessage(err?.data.Message), { variant: 'error' });
      throw err;
    }
  };

  const mapUser = (data: any, destination: any): UpdateUserDto => {
    const result = mapUserToUpdateDto(data, destination);
    return result;
  };

  const hasEditPermission = getAuthorization(PERMISSIONS.USER.EDIT);

  return (
    <>
      {!hasEditPermission ? (
        <NotFound snackbarText="Unauthorized to view page" text="Unauthorized to view page" />
      ) : isLoading || isRoleLoading ? (
        <FormSkeleton />
      ) : error && 'data' in error && error.data.Message == 'User not found' ? (
        <NotFound snackbarText="User not found" text="User not found" />
      ) : error || roleError ? (
        <>Error loading user, please refresh the page</>
      ) : (
        <CreateOrUpdateUserForm
          submitAction={updateUser}
          resetPassword={resetPassword}
          user={data}
          isSubmitting={isUpdating || isResettingPassword}
          mapUser={mapUser}
          rolesMaster={roleData || []}
        />
      )}
    </>
  );
};
