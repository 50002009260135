import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useNavigate, unstable_usePrompt as usePrompt } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';

import { FormSkeleton } from '../../../components/Elements';
import { PERMISSIONS, getSaveSuccessMessage } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import {
  useGetCurrenciesQuery,
  useGetDateFormatsQuery,
  useGetSystemSettingsQuery,
  useUpdateSystemSettingsMutation,
} from '../../../store/api';
import { DropdownType, getDateFormat } from '../../../types';
import { SystemSettingsDto } from '../types';

type FormType = {
  commaSeparator: boolean;
  connectionString: string;
  currency: string;
  dateFormat: string;
  thresholdNotification: boolean;
  lastAccYearClosingDate: Date;
  thisAccYearClosingPeriod: string;
};

export const SystemSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    clearErrors,
    setValue,
    formState: { isDirty, errors },
    getValues,
    setError,
  } = useForm<FormType>({
    defaultValues: {
      commaSeparator: false,
      currency: '',
      dateFormat: '',
      connectionString: '',
      thresholdNotification: false,
      lastAccYearClosingDate: dayjs().utc().toDate(),
      thisAccYearClosingPeriod: '12',
    },
  });

  const [shouldBlock, setShouldBlock] = useState(isDirty);

  useEffect(() => {
    setShouldBlock(isDirty);
  }, [isDirty]);

  usePrompt({ when: shouldBlock, message: 'You have unsaved changes. Are you sure you want to leave?' });
  useBeforeunload(shouldBlock ? (event) => {
    event.preventDefault();
  } : undefined);

  const { data, isFetching, error, isLoading } = useGetSystemSettingsQuery();
  const { data: currenciesData, isFetching: currenciesIsFetching } = useGetCurrenciesQuery();
  const { data: dateFormatData, isFetching: dateFormatIsFetching } = useGetDateFormatsQuery();
  const [triggerUpdateSystemSettings, { isLoading: isSubmitting }] =
    useUpdateSystemSettingsMutation();
  const hasEditPermission = getAuthorization(PERMISSIONS.SYSTEMSETTINGS.EDIT);

  const [lastAccYearClosingDate, thisAccYearClosingPeriod] = useWatch({
    control,
    name: ['lastAccYearClosingDate', 'thisAccYearClosingPeriod'],
  });

  useEffect(() => {
    if (!!data && !isFetching) {
      setValue('commaSeparator', !isNil(data.commaSeparator) ? data.commaSeparator : true);
      setValue('connectionString', data.connectionString || '');
      setValue('dateFormat', data.dateFormat || '');
      setValue('currency', data.currency || '');
      setValue(
        'thresholdNotification',
        !isNil(data.thresholdNotification) ? data.thresholdNotification : true
      );
      setValue(
        'lastAccYearClosingDate',
        !isNil(data.lastAccYearClosingDate) ? data.lastAccYearClosingDate : dayjs().utc().toDate()
      );
      setValue('thisAccYearClosingPeriod', data.thisAccYearClosingPeriod.toString() || '12');
    }
  }, [data, isFetching]);

  const saveSystemSettingsForm = async (data: any) => {
    try {
      setShouldBlock(false);
      if (
        Number.isNaN(Number(data.thisAccYearClosingPeriod)) ||
        data.thisAccYearClosingPeriod.indexOf('.') != -1
      ) {
        setError('thisAccYearClosingPeriod', {
          type: 'custom',
          message: 'This A/C year closing period is not a valid number',
        });
        return;
      }

      await triggerUpdateSystemSettings(data as SystemSettingsDto);
      enqueueSnackbar(getSaveSuccessMessage(), { variant: 'success' });
    } catch (ex) {
      enqueueSnackbar('Error occured, please contact system administrator', {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const handleCancelClick = () => {
    navigate('/settings/system');
  };

  return isFetching || dateFormatIsFetching || currenciesIsFetching ? (
    <FormSkeleton />
  ) : (
    <Grid
      container
      spacing={2}
      component="form"
      noValidate
      onSubmit={handleSubmit(saveSystemSettingsForm)}
    >
      <Grid item xs={12}>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
            <Typography variant="h6" component="h1">
              Numbers
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Controller
          name="currency"
          control={control}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth error={!!errors.currency}>
              <InputLabel required id="demo-simple-select-standard-label">
                Currencies
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                label="Currencies"
                {...field}
                inputProps={{ readOnly: !hasEditPermission }}
              >
                {currenciesData &&
                  currenciesData.items &&
                  currenciesData.items.map((role: DropdownType) => {
                    return (
                      <MenuItem key={role.displayName} value={role.value}>
                        {role.displayName}
                      </MenuItem>
                    );
                  })}
              </Select>
              {!!errors.currency && <FormHelperText>{errors.currency?.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Box>
          <FormControlLabel
            control={
              <Controller
                name="commaSeparator"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    disabled={!hasEditPermission}
                  />
                )}
              />
            }
            label="Comma Separator"
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Controller
          name="dateFormat"
          control={control}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth error={!!errors.dateFormat}>
              <InputLabel required id="demo-simple-select-standard-label">
                Date Format
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                label="Date Format"
                {...field}
                disabled={!hasEditPermission}
              >
                {dateFormatData &&
                  dateFormatData.items &&
                  dateFormatData.items.map((role: DropdownType) => {
                    return (
                      <MenuItem key={role.displayName} value={role.value}>
                        {role.displayName}
                      </MenuItem>
                    );
                  })}
              </Select>
              {!!errors.dateFormat && <FormHelperText>{errors.dateFormat?.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
          <Typography variant="h6" component="h1">
            Connection
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Controller
          name="connectionString"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              variant="outlined"
              label="Connection String"
              type="text"
              InputProps={{
                readOnly: !hasEditPermission,
              }}
              helperText={errors.connectionString?.message}
              error={!!errors.connectionString}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
          <Typography variant="h6" component="h1">
            Notification
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Box>
          <FormControlLabel
            control={
              <Controller
                name="thresholdNotification"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    disabled={!hasEditPermission}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />
            }
            label="Threshold Notification"
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
          <Typography variant="h6" component="h1">
            Accounting Period
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Box>
          <Controller
            name="lastAccYearClosingDate"
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="frFr">
                <DatePicker
                  label="Last A/C year closing date"
                  value={field.value}
                  inputFormat={getDateFormat(data?.dateFormat || 'DD/MM/YYYY')}
                  onChange={(newValue) => {
                    field.onChange(dayjs(newValue).toDate());
                  }}
                  readOnly={!hasEditPermission}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e: any) => {
                        e.preventDefault();
                      }}
                      required
                      inputProps={{ readOnly: true }}
                      fullWidth
                      {...params}
                      variant="outlined"
                      error={!!errors.lastAccYearClosingDate}
                      helperText={errors.lastAccYearClosingDate?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <Box>
          <Controller
            name="thisAccYearClosingPeriod"
            control={control}
            rules={{
              required: 'This A/C year closing period is required',
            }}
            render={({ field }) => (
              <TextField
                fullWidth
                required
                {...field}
                variant="outlined"
                label={'This A/C year closing period'}
                type="text"
                InputProps={{
                  readOnly: !hasEditPermission,
                }}
                helperText={errors.thisAccYearClosingPeriod?.message}
                error={!!errors.thisAccYearClosingPeriod}
              />
            )}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{ p: 1, mt: -2 }}
        display="flex"
        flexDirection={'row'}
        justifyContent="space-between"
        alignItems={'center'}
      >
        <Box>
          <FormLabel>Current A/C Period</FormLabel>
        </Box>
        <Box>
          <Chip
            label={dayjs(lastAccYearClosingDate)
              .add(1, 'day')
              .format(getDateFormat(data?.dateFormat || 'DD/MM/YYYY'))}
            variant="outlined"
          />
          {' ~ '}
          <Chip
            label={
              dayjs(lastAccYearClosingDate).isValid() &&
              thisAccYearClosingPeriod &&
              !Number.isNaN(Number(thisAccYearClosingPeriod))
                ? dayjs(lastAccYearClosingDate)
                    .add(Number.parseInt(thisAccYearClosingPeriod), 'month')
                    .format(getDateFormat(data?.dateFormat || 'DD/MM/YYYY'))
                : dayjs(lastAccYearClosingDate)
                    .add(1, 'day')
                    .format(getDateFormat(data?.dateFormat || 'DD/MM/YYYY'))
            }
            variant="outlined"
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        display={'flex'}
        sx={{ mx: { xs: 'auto', md: '0' }, justifyContent: { xs: 'center', md: 'flex-end' } }}
      >
        <LoadingButton sx={{ mr: 2 }} onClick={handleCancelClick} loading={isSubmitting}>
          Cancel
        </LoadingButton>
        {hasEditPermission && (
          <LoadingButton variant="contained" color="primary" type="submit" loading={isSubmitting}>
            Submit
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  );
};
