import { Assessment, Dashboard, PersonAdd, Settings, VerticalSplit } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { ReactNode, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getChildPermissions } from '../../const';
import { InventoryRoute } from '../../features/inventory';
import { Reports } from '../../features/reports/routes/Reports';
import { SettingsRoute } from '../../features/settings';
import { UsersRoute } from '../../features/users';
import { useDisclosure } from '../../hooks/useDisclosure';
import { Authorization, nonHookGetAuthorization } from '../../lib/Authorization';
import { api } from '../../store/api';
import { logout } from '../../store/auth';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { getBreadcrumbName } from '../../utils/breadcrumbname';
import { ChangePasswordDialog } from '../ChangePasswordDialog/ChangePasswordDialog';
import { Breadcrumb } from '../Elements';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

type MainLayoutProps = {
  children: ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onClickLogout = () => {
    dispatch(logout());
    dispatch(api.util.resetApiState());
    enqueueSnackbar('Logged out.', { variant: 'info' });
  };

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getBreadcrumb = () => {
    let actualPathName = '';
    if (location?.pathname.includes('edit') || location?.pathname.includes('view')) {
      const pathNameIgnore = location?.pathname.split('/');
      actualPathName = pathNameIgnore?.slice(0, pathNameIgnore?.length - 1).join('/') || '';
    } else {
      actualPathName = location?.pathname || '';
    }
    return getBreadcrumbName(actualPathName) || [];
  };

  const pathName = useMemo(() => getBreadcrumb(), [location?.pathname]);

  const hasUserPermission = nonHookGetAuthorization(getChildPermissions('USER'));
  const hasRolePermission = nonHookGetAuthorization(getChildPermissions('ROLE'));

  const userManagemenet = [
    { hasPermission: hasUserPermission, route: '/user-management/users' },
    { hasPermission: hasRolePermission, route: '/user-management/roles' },
  ];

  const firstPermissionUser = userManagemenet.find((a) => a.hasPermission) || { route: '/' };

  const hasInventoryPermission = nonHookGetAuthorization(getChildPermissions('INVENTORY'));
  const hasStockInPermission = nonHookGetAuthorization(getChildPermissions('STOCK_IN'));
  const hasStockOutPermission = nonHookGetAuthorization(getChildPermissions('STOCK_OUT'));
  const hasBatchPermission = nonHookGetAuthorization(getChildPermissions('BATCH'));

  const inv = [
    { hasPermission: hasInventoryPermission, route: '/stocks/listing' },
    { hasPermission: hasStockInPermission, route: '/stocks/stock-in' },
    { hasPermission: hasStockOutPermission, route: '/stocks/stock-out' },
    { hasPermission: hasBatchPermission, route: '/stocks/batches' },
  ];

  const firstPermission = inv.find((a) => a.hasPermission) || { route: '/' };

  const hasSystemSettingsPermission = nonHookGetAuthorization(
    getChildPermissions('SYSTEMSETTINGS')
  );
  const hasReportSettingsPermission = nonHookGetAuthorization(
    getChildPermissions('REPORTSETTINGS')
  );
  const hasMasterDataPermission = nonHookGetAuthorization(getChildPermissions('MASTERDATA'));

  const settings = [
    { hasPermission: hasSystemSettingsPermission, route: '/settings/system' },
    { hasPermission: hasMasterDataPermission, route: '/settings/master-data' },
    { hasPermission: hasReportSettingsPermission, route: '/settings/reports' },
  ];

  const firstPermissionSettings = settings.find((a) => a.hasPermission) || { route: '/' };

  const protectedRoutesChildren = [
    {
      path: '/',
      element: <Dashboard />,
      name: 'Dashboard',
      icon: <HomeIcon />,
      shouldInclude: true,
      navigateTo: '/',
      matchingRoute: '',
    },
    {
      path: '/user-management/*',
      element: <UsersRoute />,
      name: 'User Management',
      icon: <PersonAdd />,
      shouldInclude: true,
      navigateTo: firstPermissionUser.route,
      matchingRoute: 'user-management',
      requiredPermissions: [...getChildPermissions('USER'), ...getChildPermissions('ROLE')],
    },
    {
      path: '/stocks/*',
      element: <InventoryRoute />,
      name: 'Stocks',
      icon: <VerticalSplit />,
      shouldInclude: true,
      navigateTo: firstPermission.route,
      matchingRoute: 'stocks',
      requiredPermissions: [
        ...getChildPermissions('INVENTORY'),
        ...getChildPermissions('BATCH'),
        ...getChildPermissions('STOCK_IN'),
        ...getChildPermissions('STOCK_OUT'),
      ],
    },
    {
      path: '/settings/*',
      element: <SettingsRoute />,
      name: 'Settings',
      icon: <Settings />,
      shouldInclude: true,
      navigateTo: firstPermissionSettings.route,
      matchingRoute: 'settings',
      requiredPermissions: [
        ...getChildPermissions('SYSTEMSETTINGS'),
        ...getChildPermissions('MASTERDATA'),
        ...getChildPermissions('REPORTSETTINGS'),
      ],
    },
    {
      path: '/reports/*',
      element: <Reports />,
      name: 'Reports',
      icon: <Assessment />,
      shouldInclude: true,
      navigateTo: '/reports',
      matchingRoute: 'reports',
      requiredPermissions: [
        ...getChildPermissions('REPORT_STOCK_IN_OUT'),
        ...getChildPermissions('REPORT_END_OF_DAY'),
        ...getChildPermissions('REPORT_STOCK_QUANTITY_ADJUSTMENT'),
        ...getChildPermissions('REPORT_CATEGORIZED_STOCK'),

      ],
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMobile = () => {
    setAnchorEl(null);
  };

  const { close: closeChangePw, open: openChangePw, isOpen: isOpenChangePw } = useDisclosure();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <Breadcrumb paths={pathName} />
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <>
          <List>
            {protectedRoutesChildren
              .filter((route) => route.shouldInclude)
              .map((route) => (
                <ListItem key={route.name} disablePadding sx={{ display: 'block' }}>
                  <Authorization requiredRoles={route.requiredPermissions || []}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        backgroundColor:
                          location?.pathname.split('/')[1] === route.matchingRoute
                            ? grey[100]
                            : 'transparent',
                      }}
                      onClick={() => navigate(route.navigateTo!)}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            location?.pathname.split('/')[1] === route.matchingRoute
                              ? 'primary.main'
                              : '',
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {route.icon}
                      </ListItemIcon>
                      <ListItemText primary={route.name} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </Authorization>
                </ListItem>
              ))}
          </List>
          <List sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: 'transparent',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={isMenuOpen ? 'long-menu' : undefined}
                    aria-expanded={isMenuOpen ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <Avatar sx={{ height: 24, width: 24 }} />
                  </IconButton>

                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={handleCloseMobile}
                  >
                    <MenuItem
                      sx={{ fontSize: 13 }}
                      onClick={() => {
                        openChangePw();
                        setAnchorEl(null);
                      }}
                    >
                      Change Password
                    </MenuItem>
                  </Menu>
                </ListItemIcon>
                <ListItemText primary={user?.userName || ''} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: 'transparent',
                }}
                onClick={onClickLogout}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Logout />
                </ListItemIcon>
                <ListItemText primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </>
      </Drawer>
      <ChangePasswordDialog isOpen={isOpenChangePw} close={closeChangePw} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};
