import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { get } from 'lodash';

import { API_URL } from '../config';
import { UserResponse } from '../features/auth/types';
import { InProduction, LowInStock } from '../features/dashboard/types';
import {
  GetInventoryListingsFilterDto,
  InventoryListingDtoListResultDto,
} from '../features/inventory-listing/types';
import {
  BatchDtoListingResultDto,
  BatchItemFlat,
  CreateBatchDto,
  GetBatchItemDto,
  GetBatchesFilterDto,
  UpdateBatchDto,
} from '../features/inventory-listing/types/Batches';
import {
  CarBrandDto,
  CarBrandDtoListResultDto,
  CarModelDto,
  CarModelDtoListResultDto,
  ColourDto,
  ColourDtoListResultDto,
  CreateCarBrandDto,
  CreateCarModelDto,
  CreateColourDto,
  CreateDesignDto,
  CreateFeatureMasterDto,
  CreateItemDto,
  CreateMaterialDto,
  DesignDto,
  DesignDtoListResultDto,
  ExportItemDto,
  ExportResultDto,
  FeatureDto,
  FeatureMasterDto,
  FeatureMasterDtoListResultDto,
  FeatureTypeDto,
  GetCarBrandsDropdownFilterDto,
  GetCarBrandsFilterDto,
  GetCarModelsFilterDto,
  GetColoursFilterDto,
  GetDesignsFilterDto,
  GetFeaturesFilterDto,
  GetItemsFilterDto,
  GetMaterialsFilterDto,
  GetPiecesForPartsFilterDto,
  ItemDto,
  ItemDtoListResultDto,
  MaterialDto,
  MaterialDtoListResultDto,
  UpdateCarBrandDto,
  UpdateCarModelDto,
  UpdateColourDto,
  UpdateDesignDto,
  UpdateFeatureMasterDto,
  UpdateItemDto,
  UpdateMaterialDto,
} from '../features/master-data/types';
import {
  ExportCategorizedStockReport,
  ExportEndOfDayReportDto,
  ExportStockQtyAdjustment,
  ExportStockTransactionsDto,
  ReportTypeList,
} from '../features/reports/routes/types';
import {
  CreateRoleDto,
  GetRolesFilterDto,
  PermissionDto,
  RoleDto,
  RoleDtoListResultDto,
} from '../features/roles/types';
import { ReportSettingsDto, SystemSettingsDto } from '../features/settings/types';
import {
  CreateStockInDto,
  GetStockInFilterDto,
  StockInDtoListResultDto,
  StockInRecordDto,
  UpdateStockInDto,
} from '../features/stock-in/types';
import { StockOutDtoListResultDto, StockOutRecordDto, UpdateStockOutDto } from '../features/stock-out/types';
import {
  CreateUserDto,
  GetUsersFilterDto,
  UpdateUserDto,
  UserDto,
  UserDtoListResultDto,
} from '../features/users/types';
import { storeTokens } from '../lib/auth';
import { axios } from '../lib/axios';
import { DropdownType, DropdownTypeCode, ListResultBase, PaginationFilter } from '../types';
import storage from '../utils/storage';

type ApiResult = {
  data: {
    Message: string;
  };
};

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string | null } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    { data: any; status: number }
  > =>
  async ({ url, method, data, params }) => {
    try {
      // console.log({ url: baseUrl + url, method, data, params });
      const result = await axios.request({ url: baseUrl + url, method, data, params });

      return { data: result };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      if (err.response?.status === 401) {
        try {
          const refreshToken = storage.getRefreshToken();

          if (refreshToken) {
            const reAuth = (await axios.request({
              url: baseUrl + '/Account/RefreshToken',
              method: 'POST',
              data: refreshToken,
            })) as UserResponse;

            if (reAuth) {
              storeTokens(reAuth.accessToken, reAuth.refreshToken);
            }

            const result = await axios.request({ url: baseUrl + url, method, data, params });
            return { data: result };
          }
        } catch (err: any) {
          if (
            err.response?.data['Succeeded'] == false
            ) {
              // store.dispatch(logout());
              // store.dispatch(api.util.resetApiState());
          }
          return {
            error: {
              status: err.response?.status,
              data: err.response?.data || err.message,
            },
          };
        }
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: API_URL,
  }),
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  tagTypes: [
    'QrCode',
    'Units',
    'StockIns',
    'StockOuts',
    'Items',
    'InventoryListing',
    'Users',
    'Roles',
    'LowInStock',
    'Designs',
    'InProduction',
    'Permissions',
    'CarBrands',
    'Colours',
    'CarModels',
    'Materials',
    'Features',
    'Batches',
    'BatchStatuses',
    'SystemSettings',
    'ReportSettings',
    'DateFormats',
    'Currencies',
    'DownloadFormats',
    'FeatureTypes'
  ],
  endpoints(build) {
    return {
      getLowInStock: build.query<ListResultBase<LowInStock>, PaginationFilter>({
        query: ({ PageNumber, RecordsPerPage }) => ({
          url: '/dashboard/GetLowInStockItems',
          method: 'GET',
          params: { PageNumber, RecordsPerPage },
        }),
        providesTags: ['LowInStock'],
      }),
      getInProduction: build.query<InProduction[], PaginationFilter>({
        query: ({ PageNumber, RecordsPerPage }) => ({
          url: '/InProduction',
          method: 'GET',
          params: { PageNumber, RecordsPerPage },
        }),
        providesTags: ['LowInStock'],
      }),

      getUsers: build.query<UserDtoListResultDto, GetUsersFilterDto>({
        query: ({ PageNumber, RecordsPerPage, firstName, userName, lastName, role }) => ({
          url: '/User',
          method: 'GET',
          params: { PageNumber, RecordsPerPage, firstName, userName, lastName, role },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: string }) => ({ type: 'Users' as const, id })),
                { type: 'Users', id: 'LIST' },
              ]
            : [{ type: 'Users', id: 'LIST' }],
      }),
      getUser: build.query<UserDto, string>({
        query: (id) => ({
          url: `/User/${id}`,
          method: 'GET',
        }),
        providesTags: (_, __, id) => [{ type: 'Users', id }],
      }),
      updateUser: build.mutation<void, UpdateUserDto>({
        query: (data: UpdateUserDto) => {
          return {
            url: `/User`,
            method: 'PUT',
            data: data,
            params: {},
          };
        },
        invalidatesTags: (_, __, arg) => [
          { type: 'Users', id: 'LIST' },
          { type: 'Users', id: arg.id },
        ],
      }),
      createUser: build.mutation<string, CreateUserDto>({
        query: (data: CreateUserDto) => {
          return {
            url: `/User`,
            method: 'POST',
            data: data,
            params: {},
          };
        },
        transformResponse: (response: string) => {
          return response;
        },
        invalidatesTags: () => [{ type: 'Users', id: 'LIST' }],
      }),
      deleteUser: build.mutation<void, string>({
        query: (id) => ({
          url: `/User/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [{ type: 'Users', id: 'LIST' }],
      }),

      getAllRoles: build.query<DropdownType[], void>({
        query: () => ({
          url: `/Role`,
          method: 'GET',
          params: { PageNumber: 0, RecordsPerPage: 1000 },
        }),
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result.map((r: any) => {
            return {
              displayName: r.name.charAt(0).toUpperCase() + r.name.slice(1),
              value: r.name,
            } as DropdownType;
          });

          return returnResult;
        },
        providesTags: [{ type: 'Roles', id: 'LIST' }],
      }),

      getRoles: build.query<RoleDtoListResultDto, GetRolesFilterDto>({
        query: ({ PageNumber, RecordsPerPage, name }) => ({
          url: '/Role',
          method: 'GET',
          params: { PageNumber, RecordsPerPage, name },
        }),
        providesTags: [{ type: 'Roles', id: 'LIST' }],
      }),
      getAllPermissions: build.query<PermissionDto[], void>({
        query: () => ({
          url: '/Permission',
          method: 'GET',
        }),
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result.map((r: any) => {
            return {
              id: r.id,
              name: r.name,
            } as PermissionDto;
          });

          return returnResult.filter(
            (r: PermissionDto) =>
              r.name !== 'default-roles-lec' &&
              r.name !== 'offline_access' &&
              r.name !== 'uma_authorization'
          );
        },
        providesTags: ['Permissions'],
      }),
      getRole: build.query<RoleDto, string>({
        query: (id) => ({
          url: `/Role/${id}`,
          method: 'GET',
        }),
        providesTags: (_, __, id) => [{ type: 'Roles', id }],
      }),
      createRole: build.mutation<string, CreateRoleDto>({
        query: (data: CreateRoleDto) => {
          return {
            url: `/Role`,
            method: 'POST',
            data: data,
            params: {},
          };
        },
        transformResponse: (response: string) => {
          return response;
        },
        invalidatesTags: () => [{ type: 'Roles', id: 'LIST' }],
      }),
      updateRole: build.mutation<void, RoleDto>({
        query: (data: RoleDto) => {
          return {
            url: `/Role`,
            method: 'PUT',
            data: data,
            params: {},
          };
        },
        invalidatesTags: (_, __, arg) => [
          { type: 'Roles', id: 'LIST' },
          { type: 'Roles', id: arg.id },
        ],
      }),
      deleteRole: build.mutation<void, string>({
        query: (id) => ({
          url: `/Role/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [{ type: 'Roles', id: 'LIST' }],
      }),
      getCarBrands: build.query<CarBrandDtoListResultDto, GetCarBrandsFilterDto>({
        query: ({ PageNumber, RecordsPerPage, code, name, isActive }) => ({
          url: '/Data/GetCarBrands',
          method: 'GET',
          params: { PageNumber: PageNumber!, RecordsPerPage, code, name, isActive },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: number }) => ({
                  type: 'CarBrands' as const,
                  id,
                })),
                { type: 'CarBrands', id: 'LIST' },
              ]
            : [{ type: 'CarBrands', id: 'LIST' }],
      }),
      deleteCarBrand: build.mutation<void, string>({
        query: (id) => ({
          url: `/Data/DeleteCarBrand`,
          method: 'DELETE',
          params: { id },
        }),
        invalidatesTags: [{ type: 'CarBrands', id: 'LIST' }],
      }),
      createCarBrand: build.mutation<string, CreateCarBrandDto>({
        query: (data: CreateCarBrandDto) => {
          return {
            url: `/Data/CreateCarBrand`,
            method: 'POST',
            data: data,
            params: {},
          };
        },
        transformResponse: (response: string) => {
          return response;
        },
        invalidatesTags: () => [{ type: 'CarBrands', id: 'LIST' }],
      }),
      updateCarBrand: build.mutation<CarBrandDto, UpdateCarBrandDto>({
        query: (data: UpdateCarBrandDto) => {
          return {
            url: `/Data/UpdateCarBrand`,
            method: 'PUT',
            data: data,
            params: {},
          };
        },
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedPost } = await queryFulfilled;
            const patchResult = dispatch(
              api.util.updateQueryData('getCarBrand', id, (draft) => {
                Object.assign(draft, updatedPost);
              })
            );
          } catch {}
        },
        invalidatesTags: (_, __, arg) => [{ type: 'CarBrands', id: 'LIST' }],
      }),
      getCarBrand: build.query<CarBrandDto, number>({
        query: (id) => {
          if (isNaN(Number(id))) {
            throw new Error('Invalid params');
          }
          return {
            url: `/Data/GetCarBrandById`,
            method: 'GET',
            params: { id },
          };
        },
        providesTags: (_, __, id) => [{ type: 'CarBrands', id }],
      }),
      getColours: build.query<ColourDtoListResultDto, GetColoursFilterDto>({
        query: ({ PageNumber, RecordsPerPage, code, name, isActive, oldCode }) => ({
          url: '/Data/GetColours',
          method: 'GET',
          params: { PageNumber: PageNumber!, RecordsPerPage, code, name, isActive, oldCode },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: number }) => ({ type: 'Colours' as const, id })),
                { type: 'Colours', id: 'LIST' },
              ]
            : [{ type: 'Colours', id: 'LIST' }],
      }),
      deleteColour: build.mutation<void, string>({
        query: (id) => ({
          url: `/Data/DeleteColour`,
          method: 'DELETE',
          params: { id },
        }),
        invalidatesTags: [{ type: 'Colours', id: 'LIST' }],
      }),
      createColour: build.mutation<string, CreateColourDto>({
        query: (data: CreateColourDto) => {
          return {
            url: `/Data/CreateColour`,
            method: 'POST',
            data: data,
            params: {},
          };
        },
        transformResponse: (response: string) => {
          return response;
        },
        invalidatesTags: () => [{ type: 'Colours', id: 'LIST' }],
      }),
      updateColour: build.mutation<ColourDto, UpdateColourDto>({
        query: (data: UpdateColourDto) => {
          return {
            url: `/Data/UpdateColour`,
            method: 'PUT',
            data: data,
            params: {},
          };
        },
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedPost } = await queryFulfilled;
            const patchResult = dispatch(
              api.util.updateQueryData('getColour', id, (draft) => {
                Object.assign(draft, updatedPost);
              })
            );
          } catch {}
        },
        invalidatesTags: (_, __, arg) => [{ type: 'Colours', id: 'LIST' }],
      }),
      getColour: build.query<ColourDto, number>({
        query: (id) => {
          if (isNaN(Number(id))) {
            throw new Error('Invalid params');
          }
          return {
            url: `/Data/GetColourById`,
            method: 'GET',
            params: { id },
          };
        },
        providesTags: (_, __, id) => [{ type: 'Colours', id }],
      }),
      getCarModels: build.query<CarModelDtoListResultDto, GetCarModelsFilterDto>({
        query: ({ PageNumber, RecordsPerPage, code, name, isActive, groupByName = true }) => ({
          url: '/Data/GetCarModels',
          method: 'GET',
          params: {
            PageNumber: PageNumber!,
            RecordsPerPage,
            code,
            name,
            isActive,
            groupByName: groupByName,
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: number }) => ({
                  type: 'CarModels' as const,
                  id,
                })),
                { type: 'CarModels', id: 'LIST' },
              ]
            : [{ type: 'CarModels', id: 'LIST' }],
      }),
      deleteCarModel: build.mutation<void, string>({
        query: (id) => ({
          url: `/Data/DeleteCarModel`,
          method: 'DELETE',
          params: { id },
        }),
        invalidatesTags: [{ type: 'CarModels', id: 'LIST' }],
      }),
      createCarModel: build.mutation<string, CreateCarModelDto>({
        query: (data: CreateCarModelDto) => {
          return {
            url: `/Data/CreateCarModel`,
            method: 'POST',
            data: data,
            params: {},
          };
        },
        transformResponse: (response: string) => {
          return response;
        },
        invalidatesTags: () => [{ type: 'CarModels', id: 'LIST' }],
      }),
      updateCarModel: build.mutation<CarModelDto, UpdateCarModelDto>({
        query: (data: UpdateCarModelDto) => {
          return {
            url: `/Data/UpdateCarModel`,
            method: 'PUT',
            data: data,
            params: {},
          };
        },
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedPost } = await queryFulfilled;
            const patchResult = dispatch(
              api.util.updateQueryData('getCarModel', id, (draft) => {
                Object.assign(draft, updatedPost);
              })
            );
          } catch {}
        },
        invalidatesTags: (_, __, arg) => [{ type: 'CarModels', id: 'LIST' }],
      }),
      getCarModel: build.query<CarModelDto, number>({
        query: (id) => {
          if (isNaN(Number(id))) {
            throw new Error('Invalid params');
          }
          return {
            url: `/Data/GetCarModelById`,
            method: 'GET',
            params: { id },
          };
        },
        providesTags: (_, __, id) => [{ type: 'CarModels', id }],
      }),
      getAllCarBrands: build.query<DropdownTypeCode[], void>({
        query: () => ({
          url: `/Data/GetCarBrands`,
          method: 'GET',
          params: { PageNumber: 0, RecordsPerPage: 1000 },
        }),
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result
            .filter((r: any) => r.isActive)
            .map((r: any) => {
              return {
                displayName: r.name.charAt(0).toUpperCase() + r.name.slice(1),
                value: r.id.toString(),
                code: r.code,
              } as DropdownTypeCode;
            });

          return returnResult;
        },
        providesTags: [{ type: 'CarBrands', id: 'LIST' }],
      }),
      getAllCarModels: build.query<DropdownTypeCode[], void>({
        query: () => ({
          url: `/Data/GetCarModels`,
          method: 'GET',
          params: { PageNumber: 0, RecordsPerPage: 1000 },
        }),
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result
            .filter((r: any) => r.isActive)
            .map((r: any, index: number) => {
              return {
                displayName: r.name.charAt(0).toUpperCase() + r.name.slice(1),
                value: index.toString(), //TODO: change this once it's fixed
                // value: r.id.toString(),
                code: r.code,
              } as DropdownTypeCode;
            });

          return returnResult;
        },
        providesTags: [{ type: 'CarModels', id: 'LIST' }],
      }),
      getAllMaterials: build.query<DropdownTypeCode[], void>({
        query: () => ({
          url: `/Data/GetMaterials`,
          method: 'GET',
          params: { PageNumber: 0, RecordsPerPage: 1000 },
        }),
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result
            .filter((r: any) => r.isActive)
            .map((r: any) => {
              return {
                displayName: r.name.charAt(0).toUpperCase() + r.name.slice(1),
                value: r.id.toString(),
                code: r.code,
              } as DropdownTypeCode;
            });

          return returnResult;
        },
        providesTags: [{ type: 'Materials', id: 'LIST' }],
      }),
      getAllUnits: build.query<DropdownType[], void>({
        query: () => ({
          url: `/LookUp/Units`,
          method: 'GET',
          params: { PageNumber: 0, RecordsPerPage: 1000 },
        }),
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result.map((r: any) => {
            return {
              displayName: r.name.charAt(0).toUpperCase() + r.name.slice(1),
              value: r.id.toString(),
            } as DropdownType;
          });

          return returnResult;
        },
        providesTags: [{ type: 'Units', id: 'LIST' }],
      }),
      getAllCoverModels: build.query<DropdownTypeCode[], void>({
        query: () => ({
          url: `/Data/GetSeries`,
          method: 'GET',
          params: { PageNumber: 0, RecordsPerPage: 1000 },
        }),
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result
            .filter((r: any) => r.isActive)
            .map((r: any) => {
              return {
                displayName: r.name.charAt(0).toUpperCase() + r.name.slice(1),
                value: r.id.toString(),
                code: r.code,
              } as DropdownTypeCode;
            });

          return returnResult;
        },
        providesTags: [{ type: 'Designs', id: 'LIST' }],
      }),
      getAllFeatures: build.query<FeatureDto[], void>({
        query: () => ({
          url: `/Data/GetFeatures`,
          method: 'GET',
          params: { PageNumber: 0, RecordsPerPage: 1000 },
        }),
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result
            .filter((r: any) => r.isActive)
            .map((r: any) => {
              return {
                id: r.id,
                code: r.code,
                hasColourOption: r.hasColourOption,
                hasLogoOption: r.hasLogoOption,
                isActive: r.isActive,
                name: r.name,
              } as FeatureDto;
            });

          return returnResult;
        },
        providesTags: [{ type: 'Features', id: 'LIST' }],
      }),
      getMaterials: build.query<MaterialDtoListResultDto, GetMaterialsFilterDto>({
        query: ({ PageNumber, RecordsPerPage, code, name, isActive }) => ({
          url: '/Data/GetMaterials',
          method: 'GET',
          params: { PageNumber: PageNumber!, RecordsPerPage, code, name, isActive },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: number }) => ({
                  type: 'Materials' as const,
                  id,
                })),
                { type: 'Materials', id: 'LIST' },
              ]
            : [{ type: 'Materials', id: 'LIST' }],
      }),
      deleteMaterial: build.mutation<void, string>({
        query: (id) => ({
          url: `/Data/DeleteMaterial`,
          method: 'DELETE',
          params: { id },
        }),
        invalidatesTags: [{ type: 'Materials', id: 'LIST' }],
      }),
      createMaterial: build.mutation<string, CreateMaterialDto>({
        query: (data: CreateMaterialDto) => {
          return {
            url: `/Data/CreateMaterial`,
            method: 'POST',
            data: data,
            params: {},
          };
        },
        transformResponse: (response: string) => {
          return response;
        },
        invalidatesTags: () => [{ type: 'Materials', id: 'LIST' }],
      }),
      updateMaterial: build.mutation<void, UpdateMaterialDto>({
        query: (data: UpdateMaterialDto) => {
          return {
            url: `/Data/UpdateMaterial`,
            method: 'PUT',
            data: data,
            params: {},
          };
        },
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedPost } = await queryFulfilled;
            const patchResult = dispatch(
              api.util.updateQueryData('getMaterial', id, (draft) => {
                Object.assign(draft, updatedPost);
              })
            );
          } catch {}
        },
        invalidatesTags: (_, __, arg) => [{ type: 'Materials', id: 'LIST' }],
      }),
      getMaterial: build.query<MaterialDto, number>({
        query: (id) => {
          if (isNaN(Number(id))) {
            throw new Error('Invalid params');
          }
          return {
            url: `/Data/GetMaterialById`,
            method: 'GET',
            params: { id },
          };
        },
        providesTags: (_, __, id) => [{ type: 'Materials', id }],
      }),
      getFeatureTypes: build.query<FeatureTypeDto[], void>({
        query: () => ({
          url: '/LookUp/FeatureTypes',
          method: 'GET',
        }),
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result
            .map((r: any) => {
              return r as FeatureTypeDto;
            });

          return returnResult;
        },
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }: { id: number }) => ({
                  type: 'FeatureTypes' as const,
                  id,
                })),
                { type: 'FeatureTypes', id: 'LIST' },
              ]
            : [{ type: 'FeatureTypes', id: 'LIST' }],
      }),
      getFeaturesMaster: build.query<FeatureMasterDtoListResultDto, GetFeaturesFilterDto>({
        query: ({ PageNumber, RecordsPerPage, code, name, isActive }) => ({
          url: '/Data/GetFeatures',
          method: 'GET',
          params: { PageNumber: PageNumber!, RecordsPerPage, code, name, isActive },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: number }) => ({
                  type: 'Features' as const,
                  id,
                })),
                { type: 'Features', id: 'LIST' },
              ]
            : [{ type: 'Features', id: 'LIST' }],
      }),
      deleteFeatureMaster: build.mutation<void, string>({
        query: (id) => ({
          url: `/Data/DeleteFeature`,
          method: 'DELETE',
          params: { id },
        }),
        invalidatesTags: [{ type: 'Features', id: 'LIST' }],
      }),
      createFeatureMaster: build.mutation<string, CreateFeatureMasterDto>({
        query: (data: CreateFeatureMasterDto) => {
          return {
            url: `/Data/CreateFeature`,
            method: 'POST',
            data: data,
            params: {},
          };
        },
        transformResponse: (response: string) => {
          return response;
        },
        invalidatesTags: () => [{ type: 'Features', id: 'LIST' }],
      }),
      updateFeatureMaster: build.mutation<void, UpdateFeatureMasterDto>({
        query: (data: UpdateFeatureMasterDto) => {
          return {
            url: `/Data/UpdateFeature`,
            method: 'PUT',
            data: data,
            params: {},
          };
        },
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedPost } = await queryFulfilled;
            const patchResult = dispatch(
              api.util.updateQueryData('getFeatureMaster', id, (draft) => {
                Object.assign(draft, updatedPost);
              })
            );
          } catch {}
        },
        invalidatesTags: (_, __, arg) => [{ type: 'Features', id: 'LIST' }],
      }),
      getFeatureMaster: build.query<FeatureMasterDto, number>({
        query: (id) => {
          if (isNaN(Number(id))) {
            throw new Error('Invalid params');
          }
          return {
            url: `/Data/GetFeatureById`,
            method: 'GET',
            params: { id },
          };
        },
        providesTags: (_, __, id) => [{ type: 'Features', id }],
      }),
      getDesigns: build.query<DesignDtoListResultDto, GetDesignsFilterDto>({
        query: ({ PageNumber, RecordsPerPage, code, name, isActive }) => ({
          url: '/Data/GetSeries',
          method: 'GET',
          params: { PageNumber: PageNumber!, RecordsPerPage, code, name, isActive },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: number }) => ({ type: 'Designs' as const, id })),
                { type: 'Designs', id: 'LIST' },
              ]
            : [{ type: 'Designs', id: 'LIST' }],
      }),
      deleteDesign: build.mutation<void, string>({
        query: (id) => ({
          url: `/Data/DeleteSeries`,
          method: 'DELETE',
          params: { id },
        }),
        invalidatesTags: [{ type: 'Designs', id: 'LIST' }],
      }),
      createDesign: build.mutation<string, CreateDesignDto>({
        query: (data: CreateDesignDto) => {
          return {
            url: `/Data/CreateSeries`,
            method: 'POST',
            data: data,
            params: {},
          };
        },
        transformResponse: (response: string) => {
          return response;
        },
        invalidatesTags: () => [{ type: 'Designs', id: 'LIST' }],
      }),
      updateDesign: build.mutation<void, UpdateDesignDto>({
        query: (data: UpdateDesignDto) => {
          return {
            url: `/Data/UpdateSeries`,
            method: 'PUT',
            data: data,
            params: {},
          };
        },
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedPost } = await queryFulfilled;
            const patchResult = dispatch(
              api.util.updateQueryData('getDesign', id, (draft) => {
                Object.assign(draft, updatedPost);
              })
            );
          } catch {}
        },
        invalidatesTags: (_, __, arg) => [{ type: 'Designs', id: 'LIST' }],
      }),
      getDesign: build.query<DesignDto, number>({
        query: (id) => {
          if (isNaN(Number(id))) {
            throw new Error('Invalid params');
          }
          return {
            url: `/Data/GetSeriesById`,
            method: 'GET',
            params: { id },
          };
        },
        providesTags: (_, __, id) => [{ type: 'Designs', id }],
      }),
      getAllColours: build.query<DropdownTypeCode[], void>({
        query: () => ({
          url: `/Data/GetColours`,
          method: 'GET',
          params: { PageNumber: 0, RecordsPerPage: 1000 },
        }),
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result
            .filter((r: any) => r.isActive)
            .map((r: any) => {
              return {
                displayName: r.name.charAt(0).toUpperCase() + r.name.slice(1),
                code: r.code,
                value: r.id.toString(),
              } as DropdownTypeCode;
            });

          return returnResult;
        },
        providesTags: (result) => [{ type: 'Colours', id: 'LIST' }],
      }),
      getInventoryListings: build.query<
        InventoryListingDtoListResultDto,
        GetInventoryListingsFilterDto
      >({
        query: ({ PageNumber, RecordsPerPage, code, name, isActive }) => ({
          url: '/Data/GetItems',
          method: 'GET',
          params: { PageNumber: PageNumber!, RecordsPerPage, code, name, isActive },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: number }) => ({
                  type: 'Items' as const,
                  id,
                })),
                { type: 'Items', id: 'LIST' },
              ]
            : [{ type: 'Items', id: 'LIST' }],
      }),
      deleteInventoryListing: build.mutation<void, string>({
        query: (id) => ({
          url: `/Data/DeleteItem`,
          method: 'DELETE',
          params: { id },
        }),
        invalidatesTags: [{ type: 'Items', id: 'LIST' }],
      }),

      getItems: build.query<ItemDtoListResultDto, GetItemsFilterDto>({
        query: ({
          PageNumber,
          RecordsPerPage,
          code,
          name,
          isActive,
          carModel,
          unit,
          excludeZeroQuantity,
          colourIds,
        }) => {
          let colourIdsParam = '';
          if (colourIds && colourIds.length > 0) {
            colourIdsParam = '?';
            colourIds.forEach((c: any, index: number) => {
              colourIdsParam += `ColourIds=${c} ${index === colourIds.length - 1 ? '' : '&'}`;
            });
          }
          return {
            url: '/Data/GetItems' + colourIdsParam,
            method: 'GET',
            params: {
              PageNumber: PageNumber!,
              RecordsPerPage,
              code,
              name,
              isActive,
              carModel,
              unit,
              excludeZeroQuantity,
            },
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: number }) => ({ type: 'Items' as const, id })),
                { type: 'Items', id: 'LIST' },
              ]
            : [{ type: 'Items', id: 'LIST' }],
      }),
      deleteItem: build.mutation<void, string>({
        query: (id) => ({
          url: `/Data/DeleteItem`,
          method: 'DELETE',
          params: { id },
        }),
        invalidatesTags: [{ type: 'Items', id: 'LIST' }],
      }),
      createItem: build.mutation<string, CreateItemDto>({
        query: (data: CreateItemDto) => {
          return {
            url: `/Data/CreateItem`,
            method: 'POST',
            data: data,
            params: {},
          };
        },
        transformResponse: (response: string) => {
          return response;
        },
        invalidatesTags: () => [{ type: 'Items', id: 'LIST' }],
      }),
      updateItem: build.mutation<void, UpdateItemDto>({
        query: (data: UpdateItemDto) => {
          return {
            url: `/Data/UpdateItem`,
            method: 'PUT',
            data: data,
            params: {},
          };
        },
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedPost } = await queryFulfilled;
            const patchResult = dispatch(
              api.util.updateQueryData('getDesign', id, (draft) => {
                Object.assign(draft, updatedPost);
              })
            );
          } catch {}
        },
        invalidatesTags: (_, __, arg) => [{ type: 'Items', id: 'LIST' }],
      }),
      getItem: build.query<ItemDto, number>({
        query: (id) => {
          if (isNaN(Number(id))) {
            throw new Error('Invalid params');
          }
          return {
            url: `/Data/GetItemById`,
            method: 'GET',
            params: { id },
          };
        },
        providesTags: (_, __, id) => [{ type: 'Items', id }],
      }),
      getCarModelFilterByName: build.query<CarModelDtoListResultDto, GetCarBrandsDropdownFilterDto>(
        {
          query: ({ name, carBrandId, RecordsPerPage, groupByName }) => ({
            url: '/Data/GetCarModels',
            method: 'GET',
            params: { PageNumber: 0, RecordsPerPage, name, carBrandId, groupByName },
          }),
          providesTags: (_, __) => [{ type: 'CarModels', id: 'LIST' }],
          transformResponse: (response: { items: any }) => {
            const result = response.items;
            const returnResult = result
              .filter((r: any) => r.isActive);
            return {
              items: returnResult,
              totalRecords: returnResult.length
            };
          },
        }
      ),
      getPiecesForParts: build.query<ItemDtoListResultDto, GetPiecesForPartsFilterDto>({
        query: ({ carModelName, code, RecordsPerPage }) => {
          return {
            url: '/Data/GetItems',
            method: 'GET',
            params: { PageNumber: 0, RecordsPerPage, code, carModel: carModelName, unit: 2 },
          };
        },
        providesTags: (_, __) => [{ type: 'Items', id: 'LIST' }],
      }),
      getCarModelById: build.query<CarModelDto, number>({
        query: (id) => {
          if (isNaN(Number(id))) {
            throw new Error('Invalid params');
          }
          return {
            url: `/Data/GetCarModelById`,
            method: 'GET',
            params: { id },
          };
        },
        providesTags: (_, __, id) => [{ type: 'CarModels', id }],
      }),
      resetPassword: build.mutation<void, string>({
        query: (data: string) => {
          return {
            url: `/User/ResetPassword`,
            method: 'PUT',
            params: { userId: data },
          };
        },
      }),
      getBatches: build.query<BatchDtoListingResultDto, GetBatchesFilterDto>({
        query: ({
          PageNumber,
          RecordsPerPage,
          code,
          fromDate,
          toDate,
          status,
          createdBy,
          createdOn,
        }) => ({
          url: '/Stock/GetBatches',
          method: 'GET',
          params: {
            PageNumber: PageNumber!,
            RecordsPerPage,
            code,
            fromDate,
            toDate,
            status,
            createdBy,
            createdOn,
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: number }) => ({ type: 'Batches' as const, id })),
                { type: 'Batches', id: 'LIST' },
              ]
            : [{ type: 'Batches', id: 'LIST' }],
      }),

      getItemForBatches: build.query<ItemDtoListResultDto, GetItemsFilterDto>({
        query: ({
          PageNumber,
          RecordsPerPage,
          code,
          name,
          isActive,
          carModel,
          unit,
          excludeZeroQuantity,
          colourIds,
        }) => {
          let colourIdsParam = '';
          if (colourIds && colourIds.length > 0) {
            colourIdsParam = '?';
            colourIds.forEach((c: any, index: number) => {
              colourIdsParam += `ColourIds=${c} ${index === colourIds.length - 1 ? '' : '&'}`;
            });
          }
          return {
            url: '/Data/GetItems' + colourIdsParam,
            method: 'GET',
            params: {
              PageNumber: PageNumber!,
              RecordsPerPage,
              code,
              name,
              isActive,
              carModel,
              unit,
              excludeZeroQuantity,
            },
          };
        },
        providesTags: (_, __) => [{ type: 'Items', id: 'LIST' }],
      }),

      getItemByCode: build.query<ItemDtoListResultDto, GetItemsFilterDto>({
        query: ({ code }) => {
          return {
            url: '/Data/GetItems',
            method: 'GET',
            params: {
              PageNumber: 0,
              RecordsPerPage: 100,
              code,
              isExactSearch: true,
            },
          };
        },
        providesTags: (_, __) => [{ type: 'Items', id: 'LIST' }],
      }),

      getItemByCodeNew: build.query<ItemDto, string>({
        query: (code) => {
          return {
            url: `/Data/GetItemByCode`,
            method: 'GET',
            params: { code },
          };
        },
        providesTags: (_, __, id) => [{ type: 'Items', id }],
      }),

      createBatch: build.mutation<string, CreateBatchDto>({
        query: (data: CreateBatchDto) => {
          return {
            url: '/Stock/CreateBatch',
            method: 'POST',
            data: data,
            params: {},
          };
        },
        invalidatesTags: (_, __, arg) => [
          { type: 'Batches', id: 'LIST' },
          { type: 'Items', id: 'LIST' },
        ],
      }),

      updateBatch: build.mutation<GetBatchItemDto, UpdateBatchDto>({
        query: (data: UpdateBatchDto) => {
          return {
            url: '/Stock/UpdateBatch',
            method: 'PUT',
            data: data,
            params: {},
          };
        },
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedPost } = await queryFulfilled;
            const patchResult = dispatch(
              api.util.updateQueryData('getBatchById', id, (draft) => {
                Object.assign(draft, updatedPost);
              })
            );
          } catch {}
        },
        invalidatesTags: (_, __, arg) => [
          { type: 'Batches', id: 'LIST' },
          { type: 'Items', id: 'LIST' },
        ],
      }),

      getAllBatchStatuses: build.query<DropdownType[], void>({
        query: () => ({
          url: `/LookUp/BatchStatuses`,
          method: 'GET',
          params: { PageNumber: 0, RecordsPerPage: 1000 },
        }),
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result.map((r: any) => {
            return {
              displayName: r.name.charAt(0).toUpperCase() + r.name.slice(1),
              value: r.id.toString(),
            } as DropdownType;
          });

          return returnResult;
        },
        providesTags: [{ type: 'BatchStatuses', id: 'LIST' }],
      }),

      getBatchById: build.query<GetBatchItemDto, number>({
        query: (id) => {
          return {
            url: `/Stock/GetBatchById`,
            method: 'GET',
            params: { batchId: id },
          };
        },
        transformResponse: (response: any) => {
          const result = response;
          const items = result.items.map((x: any) => {
            return {
              id: x.id,
              itemId: x.itemId,
              remarks: x.remarks,
              quantity: x.quantity,
              itemDetail: {
                features: x.features,
                carModelId: x.carModelId,
                name: x.name,
                code: x.code,
                description: x.description,
                colourIds: x.colourIds,
                remarks: x.remarks,
                materialId: x.materialId,
                seriesId: x.seriesId,
                unit: x.unit.toString(),
                isActive: x.isActive,
                numberOfParts: x.numberOfParts,
              },
            };
          });
          result.items = items;
          return result;
        },
        providesTags: (_, __, id) => [{ type: 'Batches', id }],
      }),

      getQrCode: build.query<string, number>({
        query: (id) => {
          return {
            url: `/Stock/GetQRCode`,
            method: 'GET',
            params: { batchItemId: id },
          };
        },
      }),

      print: build.query<string, { batchItemId: number; printQuantity: number }[]>({
        query: (data: { batchItemId: number; printQuantity: number }[]) => {
          return {
            url: `/Stock/PrintBarcodes`,
            method: 'POST',
            data: data,
          };
        },
      }),

      getStockIns: build.query<StockInDtoListResultDto, GetStockInFilterDto>({
        query: ({
          PageNumber,
          RecordsPerPage,
          dateFrom,
          dateTo,
          createdOn,
          createdBy,
          isFinalized,
          isAdjustment,
        }) => {
          return {
            url: `/Stock/GetStockInRecords`,
            method: 'GET',
            params: {
              PageNumber,
              RecordsPerPage,
              dateFrom,
              dateTo,
              createdOn,
              createdBy,
              isFinalized,
              isAdjustment,
            },
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: number }) => ({
                  type: 'StockIns' as const,
                  id,
                })),
                { type: 'StockIns', id: 'LIST' },
              ]
            : [{ type: 'StockIns', id: 'LIST' }],
      }),

      getStockInRecords: build.query<StockInRecordDto, number>({
        query: (id: number) => {
          return {
            url: `/Stock/GetStockInRecordById`,
            method: 'GET',
            params: { id },
          };
        },
        transformResponse: (response: any) => {
          const result = response;
          const items = result.items.map((x: any) => {
            const { itemId, batchItemId, ...rest } = x;
            return {
              ...rest,
              itemId: batchItemId,
              hasLoaded: true
            };
          });
          result.items = items;
          return result;
        },
        providesTags: (_, __, id) => [{ type: 'StockIns', id }],
      }),

      getIsItemExist: build.query<number, string>({
        query: (code) => ({
          url: `/Data/isItemExists`,
          method: 'GET',
          params: { code },
        }),
      }),

      createStockIn: build.mutation<string, CreateStockInDto>({
        query: (data: CreateStockInDto) => ({
          url: `/Stock/CreateStockInRecord`,
          method: 'POST',
          params: {},
          data: data,
        }),
        invalidatesTags: (_, __, arg) => [
          { type: 'StockIns', id: 'LIST' },
          { type: 'Items', id: 'LIST' },
        ],
      }),

      getStockOuts: build.query<StockOutDtoListResultDto, GetStockInFilterDto>({
        query: ({
          PageNumber,
          RecordsPerPage,
          dateFrom,
          dateTo,
          createdOn,
          createdBy,
          isFinalized,
          isAdjustment,
        }) => {
          return {
            url: `/Stock/GetStockOutRecords`,
            method: 'GET',
            params: {
              PageNumber,
              RecordsPerPage,
              dateFrom,
              dateTo,
              createdOn,
              createdBy,
              isFinalized,
              isAdjustment,
            },
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.items.map(({ id }: { id: number }) => ({
                  type: 'StockOuts' as const,
                  id,
                })),
                { type: 'StockOuts', id: 'LIST' },
              ]
            : [{ type: 'StockOuts', id: 'LIST' }],
      }),

      getBatchItemById: build.query<BatchItemFlat, number>({
        query: (id: number) => {
          return {
            url: `/Stock/GetBatchItemById`,
            method: 'GET',
            params: { batchItemId: id },
          };
        },
      }),

      updateStockInRecord: build.mutation<StockInRecordDto, UpdateStockInDto>({
        query: (data: UpdateStockInDto) => ({
          url: `/Stock/UpdateStockInRecord`,
          method: 'PUT',
          params: {},
          data: data,
        }),
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedPost } = await queryFulfilled;
            const patchResult = dispatch(
              api.util.updateQueryData('getStockInRecords', id, (draft) => {
                Object.assign(draft, updatedPost);
              })
            );
          } catch {}
        },
        invalidatesTags: (_, __, arg) => [
          { type: 'StockIns', id: 'LIST' },
          { type: 'Items', id: 'LIST' },
        ],
      }),

      determineUnknownItem: build.query<BatchItemFlat, string>({
        query: (code: string) => {
          return {
            url: `/Data/DetermineUnknownItemFromCode`,
            method: 'GET',
            params: { code },
          };
        },
        transformResponse: (response: any) => {
          const result = response;
          const mapped = {
            code: result.code,
            name: result.name || '',
            unit: 0,
            carModelId: get(result, 'carModel.id', ''),
            features: [],
            materialId: get(result, 'material.id', ''),
            seriesId: get(result, 'series.id', ''),
            isActive: true,
            description: '',
            remarks: '',
            id: undefined,
            isEditable: true,
            itemId: undefined,
            materialCode: get(result, 'material.code', ''),
            materialName: get(result, 'material.name', ''),
            quantity: '0',
            colourIds: result.colours.map((c: any) => c.id),
          };
          return mapped;
        },
      }),

      deleteStockInRecord: build.mutation<void, string>({
        query: (id) => ({
          url: `/Stock/DeleteStockInRecord`,
          method: 'DELETE',
          params: { recordId: id },
        }),
        invalidatesTags: () => [{ type: 'StockIns', id: 'LIST' }],
      }),

      deleteBatch: build.mutation<void, string>({
        query: (id) => ({
          url: `/Stock/DeleteBatch`,
          method: 'DELETE',
          params: { batchId: id },
        }),
        invalidatesTags: () => [{ type: 'Batches', id: 'LIST' }],
      }),

      deleteStockOutRecord: build.mutation<void, string>({
        query: (id) => ({
          url: `/Stock/DeleteStockOutRecord`,
          method: 'DELETE',
          params: { recordId: id },
        }),
        invalidatesTags: () => [{ type: 'StockOuts', id: 'LIST' }],
      }),

      createStockOut: build.mutation<string, CreateStockInDto>({
        query: (data: CreateStockInDto) => ({
          url: `/Stock/CreateStockOutRecord`,
          method: 'POST',
          params: {},
          data: data,
        }),
        invalidatesTags: (_, __, arg) => [
          { type: 'StockOuts', id: 'LIST' },
          { type: 'Items', id: 'LIST' },
        ],
      }),

      updateStockOutRecord: build.mutation<StockOutRecordDto, UpdateStockOutDto>({
        query: (data: UpdateStockOutDto) => ({
          url: `/Stock/UpdateStockOutRecord`,
          method: 'PUT',
          params: {},
          data: data,
        }),
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedPost } = await queryFulfilled;
            const patchResult = dispatch(
              api.util.updateQueryData('getStockOutRecords', id, (draft) => {
                Object.assign(draft, updatedPost);
              })
            );
          } catch {}
        },
        invalidatesTags: (_, __, arg) => [
          { type: 'StockOuts', id: 'LIST' },
          { type: 'Items', id: 'LIST' },
        ],
      }),

      getStockOutRecords: build.query<StockOutRecordDto, number>({
        query: (id: number) => {
          return {
            url: `/Stock/GetStockOutRecordById`,
            method: 'GET',
            params: { id },
          };
        },
        transformResponse: (response: any) => {
          const result = response;
          const items = result.items.map((x: any) => {
            const { itemId, batchItemId, ...rest } = x;
            return {
              ...rest,
              itemId: batchItemId,
              hasLoaded: true
            };
          });
          result.items = items;
          return result;
        },
        providesTags: (_, __, id) => [{ type: 'StockOuts', id }],
      }),

      getSystemSettings: build.query<SystemSettingsDto, void>({
        query: () => {
          return {
            url: `/Setting/GetSystemSettings`,
            method: 'GET',
          };
        },
        providesTags: (_, __, id) => [{ type: 'SystemSettings', id: 'LIST' }],
      }),

      updateSystemSettings: build.mutation<SystemSettingsDto, SystemSettingsDto>({
        query: (data: SystemSettingsDto) => {
          return {
            url: `/Setting/UpdateSystemSettings`,
            method: 'PUT',
            data: data,
          };
        },
        invalidatesTags: () => [{ type: 'SystemSettings', id: 'LIST' }],
      }),

      getReportSettings: build.query<ReportSettingsDto, void>({
        query: () => {
          return {
            url: `/Setting/GetReportSettings`,
            method: 'GET',
          };
        },
        providesTags: (_, __, id) => [{ type: 'ReportSettings', id: 'LIST' }],
      }),

      updateReportSettings: build.mutation<ReportSettingsDto, ReportSettingsDto>({
        query: (data: ReportSettingsDto) => {
          return {
            url: `/Setting/UpdateReportSettings`,
            method: 'PUT',
            data: data,
          };
        },
        invalidatesTags: () => [{ type: 'ReportSettings', id: 'LIST' }],
      }),

      getDateFormats: build.query<ListResultBase<DropdownType>, void>({
        query: () => {
          return {
            url: `/LookUp/DateFormats`,
            method: 'GET',
          };
        },
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result.map((r: any) => {
            return {
              displayName: r.id,
              value: r.name,
            } as DropdownType;
          });

          const items = {
            items: returnResult,
            totalRecords: 0,
          } as ListResultBase<DropdownType>;
          return items;
        },
        providesTags: (_, __, id) => [{ type: 'DateFormats', id: 'LIST' }],
      }),

      getCurrencies: build.query<ListResultBase<DropdownType>, void>({
        query: () => {
          return {
            url: `/LookUp/Currencies`,
            method: 'GET',
          };
        },
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result.map((r: any) => {
            return {
              displayName: r.id,
              value: r.name,
            } as DropdownType;
          });

          const items = {
            items: returnResult,
            totalRecords: 0,
          } as ListResultBase<DropdownType>;
          return items;
        },
        providesTags: (_, __, id) => [{ type: 'Currencies', id: 'LIST' }],
      }),

      exportItems: build.mutation<ExportResultDto, ExportItemDto>({
        query: (data: ExportItemDto) => {
          return {
            url: `/Data/ExportItems`,
            method: 'POST',
            data: data,
          };
        },
      }),

      getDownloadFormats: build.query<ListResultBase<DropdownType>, void>({
        query: () => {
          return {
            url: `/LookUp/DownloadFormats`,
            method: 'GET',
          };
        },
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result.map((r: any) => {
            return {
              displayName: r.name,
              value: r.id,
            } as DropdownType;
          });

          const items = {
            items: returnResult,
            totalRecords: 0,
          } as ListResultBase<DropdownType>;
          return items;
        },
        providesTags: (_, __, id) => [{ type: 'DownloadFormats', id: 'LIST' }],
      }),

      getAvailableReports: build.query<ReportTypeList, void>({
        query: () => {
          return {
            url: '/GetAvailableReports',
            method: 'GET',
          };
        },
      }),

      exportEndOfDayStockReport: build.mutation<ExportResultDto, ExportEndOfDayReportDto>({
        query: (data: ExportEndOfDayReportDto) => {
          return {
            url: `/ExportEndOfDayStockReport`,
            method: 'POST',
            data: data,
          };
        },
      }),

      exportStockTransactions: build.mutation<ExportResultDto, ExportStockTransactionsDto>({
        query: (data: ExportStockTransactionsDto) => {
          return {
            url: `/Report/ExportStockTransactionsReport`,
            method: 'GET',
            params: data,
          };
        },
      }),

      getCookie: build.query<void, void>({
        query: () => {
          return {
            url: '/Account/GetCookie',
            method: 'GET',
          };
        },
      }),

      changePassword: build.mutation<void, { confirmPassword: string; newPassword: string }>({
        query: ({ confirmPassword, newPassword }) => {
          return {
            url: `/User/ChangePassword`,
            method: 'PUT',
            data: { confirmPassword, newPassword },
          };
        },
      }),

      exportCategorizedStockReport: build.mutation<ExportResultDto, ExportCategorizedStockReport>({
        query: (data: ExportCategorizedStockReport) => {
          return {
            url: `/ExportCategorizedStockReport`,
            method: 'POST',
            data: data,
          };
        },
      }),

      exportStockQtyAdjustment: build.mutation<ExportResultDto, ExportStockQtyAdjustment>({
        query: (data: ExportStockQtyAdjustment) => {
          return {
            url: `/ExportStockQtyAdjustment`,
            method: 'POST',
            data: data,
          };
        },
      }),

      getCategories: build.query<ListResultBase<DropdownType>, void>({
        query: () => {
          return {
            url: `/LookUp/StockCategories`,
            method: 'GET',
          };
        },
        transformResponse: (response: { items: any }) => {
          const result = response.items;
          const returnResult = result.map((r: any) => {
            return {
              displayName: r.name,
              value: r.id,
            } as DropdownType;
          });

          const items = {
            items: returnResult,
            totalRecords: 0,
          } as ListResultBase<DropdownType>;
          return items;
        },
        providesTags: (_, __, id) => [{ type: 'DownloadFormats', id: 'LIST' }],
      }),

      processAccountYearEnd: build.mutation<void, number>({
        query: (period: number) => {
          return {
            url: `/Setting/ProcessAccountYearEnd`,
            method: 'POST',
            params: { period }
          };
        },
      }),
    };
  },
});

export const {
  useGetLowInStockQuery,
  useGetInProductionQuery,
  useGetUsersQuery,
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useGetAllRolesQuery,
  useGetRolesQuery,
  useGetRoleQuery,
  useGetAllPermissionsQuery,
  useUpdateRoleMutation,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useLazyGetCarBrandsQuery,
  useDeleteCarBrandMutation,
  useCreateCarBrandMutation,
  useUpdateCarBrandMutation,
  useGetCarBrandQuery,
  useLazyGetColoursQuery,
  useDeleteColourMutation,
  useCreateColourMutation,
  useUpdateColourMutation,
  useGetColourQuery,
  useLazyGetCarModelsQuery,
  useDeleteCarModelMutation,
  useCreateCarModelMutation,
  useUpdateCarModelMutation,
  useGetCarModelQuery,
  useGetAllCarBrandsQuery,
  useLazyGetMaterialsQuery,
  useDeleteMaterialMutation,
  useCreateMaterialMutation,
  useUpdateMaterialMutation,
  useGetMaterialQuery,

  useLazyGetDesignsQuery,
  useDeleteDesignMutation,
  useCreateDesignMutation,
  useUpdateDesignMutation,
  useGetDesignQuery,
  useGetAllColoursQuery,

  useLazyGetInventoryListingsQuery,
  useDeleteInventoryListingMutation,

  useGetItemsQuery,
  useLazyGetItemsQuery,
  useDeleteItemMutation,
  useCreateItemMutation,
  useUpdateItemMutation,
  useGetItemQuery,

  useLazyGetCarModelFilterByNameQuery,

  useGetAllCarModelsQuery,
  useGetAllCoverModelsQuery,
  useGetAllMaterialsQuery,
  useGetAllUnitsQuery,
  useGetAllFeaturesQuery,

  useGetPiecesForPartsQuery,
  useLazyGetCarModelByIdQuery,
  useGetCarModelByIdQuery,

  useResetPasswordMutation,

  useLazyGetBatchesQuery,

  useGetItemForBatchesQuery,

  useLazyGetItemByCodeQuery,

  useCreateBatchMutation,

  useGetAllBatchStatusesQuery,

  useGetBatchByIdQuery,

  useLazyGetQrCodeQuery,

  useUpdateBatchMutation,

  useLazyPrintQuery,

  useLazyGetStockInsQuery,

  useLazyGetIsItemExistQuery,

  useCreateStockInMutation,

  useLazyGetStockOutsQuery,

  useGetStockInRecordsQuery,

  useLazyGetBatchItemByIdQuery,

  useUpdateStockInRecordMutation,

  useLazyDetermineUnknownItemQuery,

  useDeleteStockInRecordMutation,
  useDeleteBatchMutation,

  useDeleteStockOutRecordMutation,

  useCreateStockOutMutation,

  useUpdateStockOutRecordMutation,

  useGetStockOutRecordsQuery,

  useGetSystemSettingsQuery,
  useUpdateSystemSettingsMutation,

  useGetReportSettingsQuery,
  useUpdateReportSettingsMutation,

  useGetDateFormatsQuery,
  useGetCurrenciesQuery,

  useExportItemsMutation,
  useGetDownloadFormatsQuery,

  useGetAvailableReportsQuery,

  useExportEndOfDayStockReportMutation,
  useExportStockTransactionsMutation,

  useLazyGetCookieQuery,

  useLazyGetStockInRecordsQuery,
  useLazyGetStockOutRecordsQuery,
  useLazyGetBatchByIdQuery,

  useChangePasswordMutation,

  useExportCategorizedStockReportMutation,
  useExportStockQtyAdjustmentMutation,

  useGetCategoriesQuery,
  
  useProcessAccountYearEndMutation,

  useLazyGetItemByCodeNewQuery,

  useGetFeatureMasterQuery,
  useGetFeaturesMasterQuery,
  useDeleteFeatureMasterMutation,
  useUpdateFeatureMasterMutation,
  useCreateFeatureMasterMutation,
  useLazyGetFeaturesMasterQuery,
  useGetFeatureTypesQuery,

  useLazyGetItemForBatchesQuery
} = api;
