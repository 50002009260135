import Axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from '../config';
import storage from '../utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getAccessToken();
  if (token) {
    config!.headers!.authorization = `Bearer ${token}`;
  }

  const companyName = storage.getCompanyName();
  if (companyName) {
    config!.headers!['X-Tenant'] = `${companyName}`;
  }
  config!.headers!.Accept = 'text/plain';
  config!.headers!['Content-Type'] = 'application/json';
  // config!.headers!['X-Tenant'] = 'lec';
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
