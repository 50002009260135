import jwtDecode from 'jwt-decode';

import { AuthUser, CustomJwtPayload } from '../features/auth/types';
import storage from '../utils/storage';

export const storeTokens = (accessToken: string, refreshToken: string) => {
  storage.setAccessToken(accessToken);
  storage.setRefreshToken(refreshToken);
};

export const clearTokens = () => {
  storage.clearAccessToken();
  storage.clearRefreshToken();
};

export const decodeJwt = (accessToken: string): AuthUser => {
  const {
    sub: _id,
    family_name: _lastName,
    given_name: _firstName,
    preferred_username: _userName,
    name: _name,
    email: _email,
    realm_access,
  } = jwtDecode<CustomJwtPayload>(accessToken);

  const _roles = realm_access && realm_access.roles ? realm_access.roles : [];

  const user = {
    id: _id,
    lastName: _lastName,
    name: _name,
    firstName: _firstName,
    userName: _userName,
    roles: _roles.filter(
      (r: string) =>
        r !== 'default-roles-lec' && r !== 'offline_access' && r !== 'uma_authorization'
    ),

    email: _email,
  } as AuthUser;

  return user;
};
