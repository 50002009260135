import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { ReactNode, Suspense, useRef } from 'react';
import { Provider } from 'react-redux';
import { createRoutesFromElements, Route, RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import { Spinner } from '../components/Elements/Spinner';
import { AppRoutes } from '../routes';
import reduxStore from '../store/store';

const theme = createTheme({
  palette: {
    primary: {
      main: green[700],
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          background: 'white',
          margin: 0,
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#666666',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return ({
          ...(ownerState.readOnly && {
            backgroundColor: grey[100],
            color: grey[700],
          }),
        })},
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.readOnly && {
            backgroundColor: grey[100],
            color: grey[700],
          }),
        }),
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ ownerState, theme }) => ({
          ...(ownerState?.readOnly && {
            backgroundColor: grey[100],
            color: grey[700],
          }),
        }),
      }
    }
  },
});

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const myRef = useRef<SnackbarProvider>(null);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="*" element={<AppRoutes />} />
    )
  );

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </Box>
      }
    >
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        ref={myRef}
        action={(snackbarId) => (
          <Button color="inherit" onClick={() => myRef.current?.closeSnackbar(snackbarId)}>
            Dismiss
          </Button>
        )}
      >
        <ThemeProvider theme={theme}>
          <Provider store={reduxStore}>
            {/* <Router history={history}>{children}</Router> */}
            <RouterProvider router={router} />
          </Provider>
        </ThemeProvider>
      </SnackbarProvider>
    </Suspense>
  );
};
