import { Box, Typography } from '@mui/material';

export const MobileEmptyTable = () => {
  return (
    <>
      <Box sx={{ padding: 2, margin: 2 }}>
        <Typography>No records found.</Typography>
      </Box>
    </>
  );
};
