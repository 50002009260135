import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, unstable_usePrompt as usePrompt } from 'react-router-dom';
import { z } from 'zod';
import { useBeforeunload } from 'react-beforeunload';

import { PERMISSIONS } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { DropdownType } from '../../../types';
import { CreateUserDto, UpdateUserDto, UserDto } from '../types';
import { DeleteUser } from './DeleteUser';
import { FormDetails } from './FormDetails';

const schema = z.object({
  userName: z.string().min(1, 'Username is required'),
  firstname: z.string().min(1, 'First Name is required'),
  lastName: z.string(),
  email: z.string().email().optional().or(z.literal('')),
  phoneNumber: z
    .string()
    .regex(
      new RegExp('^(\\+?6?01)[02-46-9]-*[0-9]{7}$|^(\\+?6?01)[1]-*[0-9]{8}$'),
      'Invalid phone number format'
    )
    .optional()
    .or(z.literal('')),
  role: z.string().min(1, 'Role is required'),
  isActive: z.boolean(),
});

type CreateOrUpdateUserFormProps = {
  submitAction: (data: CreateUserDto | UpdateUserDto) => void;
  resetPassword?: (data: string) => void;
  mapUser: (origin: any, destination: any) => CreateUserDto | UpdateUserDto;
  rolesMaster: DropdownType[];
  isSubmitting?: boolean;
  user?: UserDto;
};

export const CreateOrUpdateUserForm = ({
  user,
  submitAction,
  resetPassword,
  mapUser,
  rolesMaster,
  isSubmitting = false,
}: CreateOrUpdateUserFormProps) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      userName: user?.userName || '',
      firstname: user?.firstname || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || '',
      role: user?.role || '',
      isActive: user ? user.isActive : true,
    },
  });

  const onSubmit = async (data: any) => {
    try {
      const update = mapUser(user!, data);
      submitAction(update);
      setShouldBlock(false);
    } catch (ex: any) {
      console.log(ex);
      throw new Error(ex);
    }
  };

  const handleCancelClick = () => {
    navigate('/user-management/users');
  };

  const isUpdate = !!user;
  const hasDeletePermission = getAuthorization(PERMISSIONS.USER.DELETE);
  const [shouldBlock, setShouldBlock] = useState(isDirty);

  useEffect(() => {
    setShouldBlock(isDirty);
  }, [isDirty]);

  usePrompt({ when: shouldBlock, message: 'You have unsaved changes. Are you sure you want to leave?' });
  useBeforeunload(shouldBlock ? (event) => {
    event.preventDefault();
  } : undefined);

  return (
    <>
      <Box>
        <Box sx={{ borderBottom: 1, borderBottomColor: grey[400], pb: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="h1">
              Information
            </Typography>

            {user && resetPassword && (
              <LoadingButton
                variant="outlined"
                color="primary"
                onClick={() => user && resetPassword(user.id)}
                loading={isSubmitting}
              >
                Reset Password
              </LoadingButton>
            )}
          </Box>
        </Box>
        <Grid
          container
          sx={{ mt: 1 }}
          spacing={4}
          noValidate
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item xs={12}>
            <Avatar src="/asd.jpg" sx={{ width: 150, height: 150, mx: 'auto' }} />
          </Grid>

          <FormDetails
            control={control}
            errors={errors}
            rolesMaster={rolesMaster}
            formState={isUpdate ? 'edit' : 'create'}
          />

          <Grid
            item
            xs={12}
            display={'flex'}
            sx={{ mx: { xs: 'auto', md: '0' }, justifyContent: { xs: 'center', md: 'flex-end' } }}
          >
            <LoadingButton sx={{ mr: 2 }} onClick={handleCancelClick} loading={isSubmitting}>
              Cancel
            </LoadingButton>
            {isUpdate && hasDeletePermission && user?.id && (
              <DeleteUser
                id={user.id.toString()}
                navigateCallback={handleCancelClick}
                triggerButton={
                  <LoadingButton
                    sx={{ mr: 2 }}
                    color="error"
                    variant="outlined"
                    loading={isSubmitting}
                  >
                    Delete
                  </LoadingButton>
                }
                setShouldBlock={setShouldBlock}
              />
            )}
            <LoadingButton variant="contained" color="primary" type="submit" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
