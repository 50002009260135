import { isNil } from 'lodash';

export type PaginationFilter = {
  PageNumber?: number;
  RecordsPerPage?: number;
};

export type ListResultBase<T> = {
  items: T[];
  totalRecords: number;
};

export type DropdownType = {
  displayName: string;
  value: string;
};

export type DropdownTypeCode = {
  displayName: string;
  value: string;
  code: string;
};

export const TrueFalseDropdown: DropdownType[] = [
  {
    displayName: 'Yes',
    value: 'true',
  },
  {
    displayName: 'No',
    value: 'false',
  },
];

export const CodeFormatDropdown: DropdownType[] = [
  {
    displayName: 'Special',
    value: 'true',
  },
  {
    displayName: 'General',
    value: 'false',
  },
];

export const PaginationSizeFilter: number[] = [5, 10, 25, 50];

export const SmallTable = PaginationSizeFilter[0];
export const LargeTable = PaginationSizeFilter[2];

export const dateFormatMapper: { [key: string]: string } = {
  'dd/MM/yyyy': 'DD/MM/YYYY',
  'dd-MM-yyyy': 'DD-MM-YYYY',
  'yyyy-MM-dd': 'YYYY-MM-DD',
  'yyyy/MM/dd': 'YYYY/MM/DD',
  'DD/MM/YYYY': 'DD/MM/YYYY',
};

export const getDateFormat = (format: string) => {
  return dateFormatMapper[format];
};

export const fileFormatMapper: { [key: string]: string } = {
  Pdf: '.pdf',
  Csv: '.csv',
  Excel: '.xlsx',
};

export const getFileFormat = (format: string) => {
  return !isNil(fileFormatMapper[format]) ? fileFormatMapper[format] : '.csv';
};
