import { Skeleton, TableCell, TableRow } from '@mui/material';
import {memo} from 'react';

type TableSkeleton = {
  rowsCount?: number;
  columnsCount?: number;
};

export const TableSkeleton = memo(({ rowsCount = 5, columnsCount = 5 }: TableSkeleton) => {
  return (
    <>
      {[...Array(rowsCount)].map((_, index) => {
        return (
          <TableRow key={'Row' + index}>
            {[...Array(columnsCount)].map((_, index) => {
              return (
                <TableCell key={'Column' + index}>
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    sx={{ width: '80%', p: 1 }}
                    height={20}
                  />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
});
