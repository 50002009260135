import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Menu, MenuItem, Paper, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { MouseEvent, memo, useState } from 'react';

import { MobileTextLabel } from '../../../components/Elements';
import { PERMISSIONS } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { RoleDto } from '../types';
import { DeleteRole } from './DeleteRole';

type RoleCardMobileViewProps = {
  role: RoleDto;
  handleEditRow: (selectedRow: string) => void;
  handleViewRow: (selectedRow: string) => void;
};

export const RoleCardMobileView = memo(
  ({ role, handleEditRow, handleViewRow }: RoleCardMobileViewProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRow, setSelectedRow] = useState<string | null>();
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setSelectedRow(event.currentTarget.getAttribute('data-id'));
    };

    const handleCloseMobile = () => {
      setAnchorEl(null);
    };

    const deleteCallBack = () => {
      handleCloseMobile();
    };

    const onEditClick = () => {
      handleEditRow(selectedRow!);
    };

    const onViewClick = () => {
      handleViewRow(selectedRow!);
    };

    const hasDeleteRolePermission = getAuthorization(PERMISSIONS.ROLE.DELETE);
    const hasViewRolePermission = getAuthorization(PERMISSIONS.ROLE.VIEW);
    const hasEditRolePermission = getAuthorization(PERMISSIONS.ROLE.EDIT);

    return (
      <Paper sx={{ padding: 2, margin: 2 }} elevation={0} variant="outlined">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {role.name}
          </Typography>

          {(hasDeleteRolePermission || hasViewRolePermission || hasEditRolePermission) && (
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                data-id={role.id}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMobile}
              >
                {hasViewRolePermission && (
                  <MenuItem sx={{ fontSize: 13 }} onClick={onViewClick}>
                    View Role
                  </MenuItem>
                )}
                {hasEditRolePermission && (
                  <MenuItem sx={{ fontSize: 13 }} onClick={onEditClick}>
                    Edit Role
                  </MenuItem>
                )}
                {hasDeleteRolePermission && (
                  <DeleteRole id={selectedRow!} callback={deleteCallBack} />
                )}
              </Menu>
            </div>
          )}
        </Box>
        <Typography>
          <MobileTextLabel field={role.description} labelName="Description" />
        </Typography>
      </Paper>
    );
  }
);
