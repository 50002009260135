import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import { ReactElement, useEffect } from 'react';

import { ConfirmationDialog } from '../../../components/Elements/ConfirmationDialog';
import { getDeleteSucessMessage, getErrorMessage } from '../../../const';
import { useDeleteUserMutation } from '../../../store/api';

export type DeleteUserModal = {
  id: string;
  callback?: () => void;
  navigateCallback?: () => void;
  triggerButton?: ReactElement;
  setShouldBlock?: (value: boolean) => void;
};

export const DeleteUser = ({ id, callback, navigateCallback, triggerButton, setShouldBlock = () => {} }: DeleteUserModal) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteUser, { isLoading: isUpdating, isSuccess, isError }] = useDeleteUserMutation();

  const handleOnClick = async () => {
    try {
      setShouldBlock(false);
      await deleteUser(id).unwrap();
      enqueueSnackbar(getDeleteSucessMessage(), { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(getErrorMessage(err?.data.Message.toString()), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (isSuccess || isError) {
      callback ? callback() : navigateCallback ? navigateCallback() : void 0;
    }
  }, [isSuccess, isError]);

  return (
    <ConfirmationDialog
      isDone={isSuccess}
      isLoading={isUpdating}
      title="Delete user"
      body="Delete this user?"
      triggerButton={
        triggerButton ? triggerButton : <MenuItem sx={{ fontSize: 13 }}>Delete User</MenuItem>
      }
      callback={callback}
      confirmButton={
        <LoadingButton onClick={handleOnClick} loading={isUpdating}>
          Delete
        </LoadingButton>
      }
    />
  );
};
