import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import { FormSkeleton, NotFound } from '../../../components/Elements';
import { PERMISSIONS } from '../../../const';
import { getErrorMessage, getSaveSuccessMessage } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import {
  useGetAllPermissionsQuery,
  useGetRoleQuery,
  useUpdateRoleMutation,
} from '../../../store/api';
import { CreateOrUpdateRoleForm } from '../components/CreateOrUpdateRoleForm';
import { RoleDto } from '../types';

export const UpdateRole = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { userId } = useParams();

  const { data, isLoading, error } = useGetRoleQuery(userId!);

  const {
    data: permissionData,
    isLoading: permissionLoading,
    error: permissionError,
  } = useGetAllPermissionsQuery();

  const [updateRoleMutation, { isLoading: isUpdating }] = useUpdateRoleMutation();

  const handleSuccess = (id: string) => {
    navigate(`/users/edit/${id}`);
  };

  const updateRole = async (data: any) => {
    try {
      await updateRoleMutation(data).unwrap();
      enqueueSnackbar(getSaveSuccessMessage(), { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(getErrorMessage(err?.data.Message.toString()), { variant: 'error' });
      throw err;
    }
  };

  const mapRole = (data: any, destination: any): RoleDto => {
    const { id } = data;
    return { id, ...destination } as RoleDto;
  };

  const hasEditPermission = getAuthorization(PERMISSIONS.ROLE.EDIT);

  return (
    <>
      {!hasEditPermission ? (
        <NotFound snackbarText="Unauthorized to view page" text="Unauthorized to view page" />
      ) : isLoading || permissionLoading ? (
        <FormSkeleton />
      ) : error && 'data' in error && error.data.Message == 'Could not find group by id' ? (
        <NotFound snackbarText="Role not found" text="Role not found" />
      ) : error ? (
        <>Error loading role, please refresh the page</>
      ) : (
        <CreateOrUpdateRoleForm
          role={data}
          permissionsMaster={permissionError ? undefined : permissionData}
          mapRole={mapRole}
          submitAction={updateRole}
          isSubmitting={isUpdating}
        />
      )}
    </>
  );
};
