import { TableCell, TableRow, Typography } from '@mui/material';

export const EmptyTable = () => {
  return (
    <>
      <TableRow sx={{ padding: 2, margin: 2 }}>
        <TableCell colSpan={2} sx={{ border: 'none' }}>
          <Typography>No records found.</Typography>
        </TableCell>
      </TableRow>
    </>
  );
};
