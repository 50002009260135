import { Box } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthorizationButton } from '../../../components/Elements';
import { PERMISSIONS } from '../../../const';
import { useGetUsersQuery } from '../../../store/api';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { setUserSearchFilter } from '../../../store/search';
import { LargeTable } from '../../../types';
import { UsersFilter } from '../components/UsersFilter';
import { UsersTable } from '../components/UsersTable';

export const Users = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pageSize, setPageSize] = useState(LargeTable);
  const [page, setPage] = useState(0);
  const { userFilter } = useAppSelector((state) => state.search);

  const { data, isFetching, error, isLoading } = useGetUsersQuery(
    Object.assign({}, { PageNumber: page, RecordsPerPage: pageSize }, { ...userFilter })
  );

  const handleChangePageSize = (event: ChangeEvent<HTMLInputElement>) => {
    setPageSize(Number(event.target.value));
    setPage(0);
  };

  const handlePageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const setFilterObject = (value: any) => {
    setPage(0);
    dispatch(setUserSearchFilter(value));
  };

  const handleEditRow = (id: string) => {
    navigate(`edit/${id}`);
  };

  const handleViewRow = (id: string) => {
    navigate(`view/${id}`);
  };

  let content = (
    <Box sx={{ height: '80vh' }}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'flex-start' }}
      >
        <UsersFilter
          existingFilterObject={userFilter}
          handleFilterChange={setFilterObject}
          isFetching={isFetching}
        />
        <AuthorizationButton
          requiredPermission={PERMISSIONS.USER.CREATE}
          variant="contained"
          size="medium"
          to="/user-management/users/create"
          label="Add User"
        />
      </Box>

      <UsersTable
        pageSize={pageSize}
        page={page}
        isFetching={isFetching}
        handleEditRow={handleEditRow}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        handleViewRow={handleViewRow}
        data={isLoading || error ? undefined : data}
      />
    </Box>
  );

  return <>{content}</>;
};
