const SAVE_SUCCESS_MESSAGE = 'Saved successfully!';
const SAVE_AND_REDIRECT_SUCCESS_MESSAGE = 'Saved successfully!';
const DELETE_SUCCESS_MESSAGE = 'Deleted successfully!';
const GENERIC_ERROR_MESSAGE = 'Error occurred.';

const ERROR_MESSAGES: { [key: string]: string } = {
  'Microsoft.AspNetCore.Identity.IdentityError': 'Saving failed. Duplicate username or email.',
  'Code already in used by another car brand': 'Code already in used by another car brand',
  'Code already in used by another colour': 'Code already in used by another colour',
  'Code already in used by another material': 'Code already in used by another material',
  'Invalid user credentials': 'Invalid user credentials',
  'Realm does not exist': 'Company code not found',
};

export const getSaveSuccessMessage = () => SAVE_SUCCESS_MESSAGE;
export const getEditSuccessMessage = () => SAVE_SUCCESS_MESSAGE;
export const getDeleteSucessMessage = () => DELETE_SUCCESS_MESSAGE;

export const getErrorMessage = (value: string) => {
  return ERROR_MESSAGES[value] === undefined ? value : ERROR_MESSAGES[value];
};

export const PERMISSIONS = {
  USER: {
    CREATE: 'authorization.user.create',
    VIEW: 'authorization.user.view',
    DELETE: 'authorization.user.delete',
    EDIT: 'authorization.user.edit',
  },
  ROLE: {
    CREATE: 'authorization.role.create',
    VIEW: 'authorization.role.view',
    DELETE: 'authorization.role.delete',
    EDIT: 'authorization.role.edit',
  },
  SERIES: {
    CREATE: 'maintenance.series.create',
    VIEW: 'maintenance.series.view',
    DELETE: 'maintenance.series.delete',
    EDIT: 'maintenance.series.edit',
  },
  CARBRAND: {
    CREATE: 'maintenance.car_brand.create',
    VIEW: 'maintenance.car_brand.view',
    DELETE: 'maintenance.car_brand.delete',
    EDIT: 'maintenance.car_brand.edit',
  },
  CARMODEL: {
    CREATE: 'maintenance.car_model.create',
    VIEW: 'maintenance.car_model.view',
    DELETE: 'maintenance.car_model.delete',
    EDIT: 'maintenance.car_model.edit',
  },
  MATERIAL: {
    CREATE: 'maintenance.material.create',
    VIEW: 'maintenance.material.view',
    DELETE: 'maintenance.material.delete',
    EDIT: 'maintenance.material.edit',
  },
  FEATURE: {
    CREATE: 'maintenance.feature.create',
    VIEW: 'maintenance.feature.view',
    DELETE: 'maintenance.feature.delete',
    EDIT: 'maintenance.feature.edit',
  },
  COLOUR: {
    CREATE: 'maintenance.colour.create',
    VIEW: 'maintenance.colour.view',
    DELETE: 'maintenance.colour.delete',
    EDIT: 'maintenance.colour.edit',
  },
  ITEM: {
    CREATE: 'maintenance.item.create',
    VIEW: 'maintenance.item.view',
    DELETE: 'maintenance.item.delete',
    EDIT: 'maintenance.item.edit',
  },
  INVENTORY: {
    VIEW: 'stocks.inventory.view',
  },
  STOCK_IN: {
    VIEW: 'stocks.stock_in.view',
    EDIT: 'stocks.stock_in.edit',
    CREATE: 'stocks.stock_in.create',
    DELETE: 'stocks.stock_in.delete',
    FINALIZE: 'stocks.stock_in.finalize',
  },
  STOCK_OUT: {
    VIEW: 'stocks.stock_out.view',
    EDIT: 'stocks.stock_out.edit',
    CREATE: 'stocks.stock_out.create',
    DELETE: 'stocks.stock_out.delete',
    FINALIZE: 'stocks.stock_out.finalize',
  },
  BATCH: {
    VIEW: 'stocks.batch.view',
    EDIT: 'stocks.batch.edit',
    CREATE: 'stocks.batch.create',
    DELETE: 'stocks.batch.delete',
    FINALIZE: 'stocks.batch.finalize',
  },
  MASTERDATA: {
    VIEW: 'settings.master_data.view',
  },
  SYSTEMSETTINGS: {
    VIEW: 'settings.system.view',
    EDIT: 'settings.system.edit',
  },
  REPORTSETTINGS: {
    VIEW: 'settings.report.view',
    EDIT: 'settings.report.edit',
  },
  REPORT_STOCK_IN_OUT: {
    VIEW: 'reports.stock_in_out.view',
  },
  REPORT_END_OF_DAY: {
    VIEW: 'reports.end_of_day_stocks.view',
  },
  REPORT_STOCK_QUANTITY_ADJUSTMENT: {
    VIEW: 'reports.stock_quantity_adjustment.view',
  },
  REPORT_CATEGORIZED_STOCK: {
    VIEW: 'reports.categorized_stock.view',
  },
  YEAR_END_PROCESSING: {
    CREATE: 'settings.year_end_processing.create',
  }
};

export const getChildPermissions = (value: string) => {
  return typeof PERMISSIONS[value as keyof typeof PERMISSIONS] !== 'undefined'
    ? Object.values(PERMISSIONS[value as keyof typeof PERMISSIONS]).map((p: string) => p)
    : [];
};

export const refreshMessage = (
  <>
    Please refresh the page to get latest values before attempting to save. <br /> <b>Note</b>:
    Current changes will be lost if you refresh this page.
  </>
);

export const refreshTitle = "The record has been modified after you obtained the original values";