import { configureStore } from '@reduxjs/toolkit';
// import { createBrowserHistory } from 'history';
// import { createReduxHistoryContext } from 'redux-first-history';

import { api } from './api';
import AuthSlice from './auth';
import SearchSlice from './search';

// const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
//   history: createBrowserHistory(),
//   savePreviousLocations: 3,
// });

const store = configureStore({
  reducer: {
    search: SearchSlice,
    auth: AuthSlice,
    // router: routerReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export default store;
// export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
