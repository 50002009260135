import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect } from 'react';
import {
  Link,
  Navigate,
  Route,
  Routes,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { getChildPermissions } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { lazyImport } from '../../../utils/lazyImport';
import { ReportSettings } from '../components/ReportSettings';
import { SystemActions } from '../components/SystemAction';
import { SystemSettings } from '../components/SystemSettings';

const { MasterDataRoutes } = lazyImport(() => import('../../master-data'), 'MasterDataRoutes');

export const SettingsRoute = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const useRouteMatch = (patterns: readonly string[]) => {
    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  };

  const routeMatch = useRouteMatch([
    '/settings/system',
    '/settings/master-data',
    '/settings/reports',
    '/settings/system-actions',
  ]);
  const currentTab = routeMatch?.pattern?.path;

  const hasSystemSettingsPermission = getAuthorization(getChildPermissions('SYSTEMSETTINGS'));
  const hasReportSettingsPermission = getAuthorization(getChildPermissions('REPORTSETTINGS'));
  const hasMasterDataPermission = getAuthorization(getChildPermissions('MASTERDATA'));
  //TODO: Change permission below
  const hasSystemActionsPermission = getAuthorization(getChildPermissions('YEAR_END_PROCESSING'));

  const inv = [
    { hasPermission: hasSystemSettingsPermission, route: '/settings/system' },
    { hasPermission: hasReportSettingsPermission, route: '/settings/reports' },
    { hasPermission: hasMasterDataPermission, route: '/settings/master-data' },
    { hasPermission: hasSystemActionsPermission, route: '/settings/system-actions' },
  ];

  useEffect(() => {
    const firstPermission = inv.find((a) => a.hasPermission) || { route: '/' };
    if (
      !hasSystemSettingsPermission &&
      !hasReportSettingsPermission &&
      !hasMasterDataPermission &&
      !hasSystemActionsPermission
    ) {
      navigate('/');
    } else if (pathname === '/settings/system' && !hasSystemSettingsPermission) {
      navigate(firstPermission.route);
    } else if (pathname === '/settings/master-data' && !hasMasterDataPermission) {
      navigate(firstPermission.route);
    } else if (pathname === '/settings/reports' && !hasReportSettingsPermission) {
      navigate(firstPermission.route);
    } else if (pathname === '/settings/system-actions' && !hasSystemActionsPermission) {
      navigate(firstPermission.route);
    }
  }, [
    hasSystemSettingsPermission,
    hasReportSettingsPermission,
    hasMasterDataPermission,
    hasSystemActionsPermission,
  ]);

  return (
    <>
      {pathname === '/settings/system' ||
      pathname === '/settings/master-data' ||
      pathname === '/settings/reports' ||
      pathname === '/settings/system-actions' ? (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={currentTab}>
            <Tab
              label="System"
              value="/settings/system"
              to="/settings/system"
              component={Link}
              sx={{
                display: hasSystemSettingsPermission ? 'block' : 'none',
              }}
            />
            <Tab
              label="Master Data"
              value="/settings/master-data"
              to="/settings/master-data"
              component={Link}
              sx={{ display: hasMasterDataPermission ? 'block' : 'none' }}
            />
            <Tab
              label="Report"
              value="/settings/reports"
              to="/settings/reports"
              component={Link}
              sx={{ display: hasReportSettingsPermission ? 'block' : 'none' }}
            />
            <Tab
              label="System Actions"
              value="/settings/system-actions"
              to="/settings/system-actions"
              component={Link}
              sx={{ display: hasSystemActionsPermission ? 'block' : 'none' }}
            />
          </Tabs>
        </Box>
      ) : (
        <></>
      )}
      <Routes>
        <Route path="/system" element={<SystemSettings />} />
        <Route path="/reports" element={<ReportSettings />} />
        <Route path="/master-data/*" element={<MasterDataRoutes />} />
        <Route path="/system-actions" element={<SystemActions />} />
        <Route path="*" element={<Navigate to="system" />} />
      </Routes>
    </>
  );
};
