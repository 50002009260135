import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getUser } from '../features/auth/api/getUser';
import { loginWithUsernameAndPassword } from '../features/auth/api/login';
import { AuthUser, UserResponse } from '../features/auth/types';
import { clearTokens, decodeJwt, storeTokens } from '../lib/auth';
import storage from '../utils/storage';

interface AuthState {
  user?: AuthUser | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  responseTokenStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isAuthenticated: boolean;
}

export const loginAction = createAsyncThunk<
  UserResponse,
  { userName: string; password: string; companyName: string },
  { rejectValue: string }
>(
  'auth/login',
  async (
    {
      userName,
      password,
      companyName,
    }: { userName: string; password: string; companyName: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await loginWithUsernameAndPassword({
        userName,
        password,
        companyName,
      });

      storage.setCompanyName(companyName);
      storeTokens(response.accessToken, response.refreshToken);

      const user = decodeJwt(response.accessToken);
      storage.setRoles(user.roles || []);
      response.user = user;

      return response;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err.response.data.Message);
    }
  }
);

export const getUserAction = createAsyncThunk('auth/me', async (_, { rejectWithValue }) => {
  try {
    const refreshToken = storage.getRefreshToken();
    const response = await getUser({ refreshToken });

    if (response) {
      storeTokens(response.accessToken, response.refreshToken);
    }
    return response;
  } catch (err: any) {
    console.error(err);
    return rejectWithValue(err.response.data.Message);
  }
});

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  status: 'idle',
  responseTokenStatus: 'idle',
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initializeState: (state) => Object.assign(state, initialState),
    logout: (state) => {
      Object.assign(state, {
        user: null,
        isAuthenticated: false,
        status: 'error',
        responseTokenstatus: 'error',
        error: null,
      });
      clearTokens();
      storage.clearCompanyName();
      storage.clearRoles();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.responseTokenStatus = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload.user;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.status = 'failed';
        state.responseTokenStatus = 'failed';
        state.error = action.payload || '';
      })
      .addCase(getUserAction.pending, (state) => {
        state.responseTokenStatus = 'loading';
      })
      .addCase(getUserAction.rejected, (state) => {
        state.responseTokenStatus = 'failed';
        Object.assign(state, {
          user: null,
          isAuthenticated: false,
          status: 'error',
          responseTokenStatus: 'error',
          error: null,
        });
        clearTokens();
        storage.clearCompanyName();
        storage.clearRoles();
      })
      .addCase(getUserAction.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = decodeJwt(action.payload.accessToken);
        storage.setRoles(state.user.roles || []);
        state.responseTokenStatus = 'succeeded';
      });
  },
});

export const { initializeState, logout } = authSlice.actions;

export default authSlice.reducer;
