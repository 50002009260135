import { Assessment } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import VerticalSplit from '@mui/icons-material/VerticalSplitOutlined';
import { Box } from '@mui/material';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '../components/Elements/Spinner';
import { MainLayout } from '../components/Layout';
import { getChildPermissions } from '../const';
import { InventoryRoute } from '../features/inventory';
import { Reports } from '../features/reports/routes/Reports';
import { nonHookGetAuthorization } from '../lib/Authorization';
import { lazyImport } from '../utils/lazyImport';

const { Dashboard } = lazyImport(() => import('../features/dashboard'), 'Dashboard');
// const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');
const { UsersRoute } = lazyImport(() => import('../features/users'), 'UsersRoute');
const { SettingsRoute } = lazyImport(() => import('../features/settings'), 'SettingsRoute');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <Box
            sx={{
              height: '100vh',
              width: '100vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner />
          </Box>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

const hasUserPermission = nonHookGetAuthorization(getChildPermissions('USER'));
const hasRolePermission = nonHookGetAuthorization(getChildPermissions('ROLE'));

const userManagemenet = [
  { hasPermission: hasUserPermission, route: '/user-management/users' },
  { hasPermission: hasRolePermission, route: '/user-management/roles' },
];

const firstPermissionUser = userManagemenet.find((a) => a.hasPermission) || { route: '/' };

const hasInventoryPermission = nonHookGetAuthorization(getChildPermissions('INVENTORY'));
const hasStockInPermission = nonHookGetAuthorization(getChildPermissions('STOCK_IN'));
const hasStockOutPermission = nonHookGetAuthorization(getChildPermissions('STOCK_OUT'));
const hasBatchPermission = nonHookGetAuthorization(getChildPermissions('BATCH'));

const inv = [
  { hasPermission: hasInventoryPermission, route: '/stocks/listing' },
  { hasPermission: hasStockInPermission, route: '/stocks/stock-in' },
  { hasPermission: hasStockOutPermission, route: '/stocks/stock-out' },
  { hasPermission: hasBatchPermission, route: '/stocks/batches' },
];

const firstPermission = inv.find((a) => a.hasPermission) || { route: '/' };

const hasSystemSettingsPermission = nonHookGetAuthorization(getChildPermissions('SYSTEMSETTINGS'));
const hasReportSettingsPermission = nonHookGetAuthorization(getChildPermissions('REPORTSETTINGS'));
const hasMasterDataPermission = nonHookGetAuthorization(getChildPermissions('MASTERDATA'));

const settings = [
  { hasPermission: hasSystemSettingsPermission, route: '/settings/system' },
  { hasPermission: hasMasterDataPermission, route: '/settings/master-data' },
  { hasPermission: hasReportSettingsPermission, route: '/settings/reports' },
];

const firstPermissionSettings = settings.find((a) => a.hasPermission) || { route: '/' };

export const protectedRoutesChildren = [
  {
    path: '/',
    element: <Dashboard />,
    name: 'Dashboard',
    icon: <HomeIcon />,
    shouldInclude: true,
    navigateTo: '/',
    matchingRoute: '',
  },
  {
    path: '/user-management/*',
    element: <UsersRoute />,
    name: 'User Management',
    icon: <PersonAdd />,
    shouldInclude: true,
    navigateTo: firstPermissionUser.route,
    matchingRoute: 'user-management',
    requiredPermissions: [...getChildPermissions('USER'), ...getChildPermissions('ROLE')],
  },
  {
    path: '/stocks/*',
    element: <InventoryRoute />,
    name: 'Stocks',
    icon: <VerticalSplit />,
    shouldInclude: true,
    navigateTo: firstPermission.route,
    matchingRoute: 'stocks',
    requiredPermissions: [
      ...getChildPermissions('INVENTORY'),
      ...getChildPermissions('BATCH'),
      ...getChildPermissions('STOCK_IN'),
      ...getChildPermissions('STOCK_OUT'),
    ],
  },
  {
    path: '/settings/*',
    element: <SettingsRoute />,
    name: 'Settings',
    icon: <Settings />,
    shouldInclude: true,
    navigateTo: firstPermissionSettings.route,
    matchingRoute: 'settings',
    requiredPermissions: [
      ...getChildPermissions('SYSTEMSETTINGS'),
      ...getChildPermissions('MASTERDATA'),
      ...getChildPermissions('REPORTSETTINGS'),
    ],
  },
  {
    path: '/reports/*',
    element: <Reports />,
    name: 'Reports',
    icon: <Assessment />,
    shouldInclude: true,
    navigateTo: '/reports',
    matchingRoute: 'reports',
    requiredPermissions: [
        ...getChildPermissions('REPORT_STOCK_IN_OUT'),
        ...getChildPermissions('REPORT_END_OF_DAY'),
        ...getChildPermissions('REPORT_STOCK_QUANTITY_ADJUSTMENT'),
        ...getChildPermissions('REPORT_CATEGORIZED_STOCK'),
    ],
  },
];

export const protectedRoutes = [
  {
    path: '',
    element: <App />,
    children: [
      { path: '/login', element: <Navigate to="/" /> },
      ...protectedRoutesChildren,
      // { path: '/users', element: <Users /> },
      // { path: '/profile', element: <Profile /> },
      // { path: '/', element: <Dashboard /> },
    ],
  },
];
