import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect } from 'react';
import {
  Link,
  Navigate,
  Route,
  Routes,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { getChildPermissions } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { useAppDispatch } from '../../../store/hook';
import { clearRoleSearchFilter, clearUserSearchFilter } from '../../../store/search';
import { Roles } from '../../roles';
import { CreateRole } from '../../roles/routes/CreateRole';
import { UpdateRole } from '../../roles/routes/UpdateRole';
import { ViewRole } from '../../roles/routes/ViewRole';
import { CreateUser } from './CreateUser';
import { UpdateUser } from './UpdateUser';
import { Users } from './Users';
import { ViewUser } from './ViewUser';

export const UsersRoute = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const useRouteMatch = (patterns: readonly string[]) => {
    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  };

  const routeMatch = useRouteMatch(['/user-management/users', '/user-management/roles']);
  const currentTab = routeMatch?.pattern?.path;
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearUserSearchFilter());
      dispatch(clearRoleSearchFilter());
    };
  }, []);

  const hasPermissionUser = getAuthorization(getChildPermissions('USER'));
  const hasPermissionRole = getAuthorization(getChildPermissions('ROLE'));

  useEffect(() => {
    if (!hasPermissionUser && !hasPermissionRole) {
      navigate('/');
    } else if (pathname === '/user-management/users' && !hasPermissionUser) {
      navigate('/user-management/roles');
    } else if (pathname === '/user-management/roles' && !hasPermissionRole) {
      navigate('/user-management/users');
    }
  }, [hasPermissionUser, hasPermissionRole]);

  return (
    <>
      {pathname === '/user-management/users' || pathname === '/user-management/roles' ? (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={currentTab}>
            <Tab
              label="Users"
              value="/user-management/users"
              to="/user-management/users"
              component={Link}
              sx={{ display: hasPermissionUser ? 'block' : 'none' }}
            />

            <Tab
              label="Roles"
              value="/user-management/roles"
              to="/user-management/roles"
              component={Link}
              sx={{ display: hasPermissionRole ? 'block' : 'none' }}
            />
          </Tabs>
        </Box>
      ) : (
        <></>
      )}
      <Routes>
        <Route path="/users" element={<Users />} />
        <Route path="/users/create" element={<CreateUser />} />
        <Route path="/users/edit/:userId" element={<UpdateUser />} />
        <Route path="/users/view/:userId" element={<ViewUser />} />
        <Route path="/roles" element={<Roles />} />
        <Route path="/roles/create" element={<CreateRole />} />
        <Route path="/roles/edit/:userId" element={<UpdateRole />} />
        <Route path="/roles/view/:userId" element={<ViewRole />} />
        <Route path="*" element={<Navigate to="users/" />} />
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
    </>
  );
};
