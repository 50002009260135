import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

type PromptDialog = {
  isOpen: boolean;
  handleClose: () => void;
  dialogTitle: string;
  dialogContent: string | React.ReactElement;
  confirmButtonText: string;
  submitAction: () => void;
};

export const PromptDialog = ({
  isOpen,
  handleClose: onClose,
  dialogTitle,
  dialogContent,
  confirmButtonText,
  submitAction,
}: PromptDialog) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
      //FIXME: look into solving the flicking issue, temporarily set to 0 to preventhe modal to be flickering when its state is changed
      transitionDuration={{ exit: 0 }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
          {dialogContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" onClick={submitAction}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
