import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect } from 'react';
import { Link, Route, Routes, matchPath, useLocation, useNavigate } from 'react-router-dom';

import { getChildPermissions } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { lazyImport } from '../../../utils/lazyImport';

const { StockInRoutes } = lazyImport(
  () => import('../../stock-in/routes/StockInRoutes'),
  'StockInRoutes'
);

const { StockOutRoutes } = lazyImport(
  () => import('../../stock-out/routes/StockOutRoutes'),
  'StockOutRoutes'
);

const { InventoryListingRoutes } = lazyImport(
  () => import('../../inventory-listing/routes/InventoryListings'),
  'InventoryListingRoutes'
);
const { BatchesListingRoutes } = lazyImport(
  () => import('../../inventory-listing/routes/Batches'),
  'BatchesListingRoutes'
);

export const InventoryRoute = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const useRouteMatch = (patterns: readonly string[]) => {
    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  };

  const routeMatch = useRouteMatch([
    '/stocks/listing',
    '/stocks/stock-in',
    '/stocks/batches',
    '/stocks/stock-out',
  ]);
  const currentTab = routeMatch?.pattern?.path;

  const hasInventoryPermission = getAuthorization(getChildPermissions('INVENTORY'));
  const hasStockInPermission = getAuthorization(getChildPermissions('STOCK_IN'));
  const hasStockOutPermission = getAuthorization(getChildPermissions('STOCK_OUT'));
  const hasBatchPermission = getAuthorization(getChildPermissions('BATCH'));

  const inv = [
    { hasPermission: hasInventoryPermission, route: '/stocks/listing' },
    { hasPermission: hasStockInPermission, route: '/stocks/stock-in' },
    { hasPermission: hasStockOutPermission, route: '/stocks/stock-out' },
    { hasPermission: hasBatchPermission, route: '/stocks/batches' },
  ];

  useEffect(() => {
    const firstPermission = inv.find((a) => a.hasPermission) || { route: '/' };
    if (
      !hasInventoryPermission &&
      !hasStockInPermission &&
      !hasStockOutPermission &&
      !hasBatchPermission
    ) {
      navigate('/');
    } else if (pathname === '/stocks/listing' && !hasInventoryPermission) {
      navigate(firstPermission.route);
    } else if (pathname === '/stocks/stock-in' && !hasStockInPermission) {
      navigate(firstPermission.route);
    } else if (pathname === '/stocks/stock-out' && !hasStockOutPermission) {
      navigate(firstPermission.route);
    } else if (pathname === '/stocks/batches' && !hasBatchPermission) {
      navigate(firstPermission.route);
    }
  }, [hasInventoryPermission, hasStockInPermission, hasStockOutPermission, hasBatchPermission]);

  return (
    <>
      {pathname === '/stocks/listing' ||
      pathname === '/stocks/batches' ||
      pathname === '/stocks/stock-in' ||
      pathname === '/stocks/stock-out' ? (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={currentTab}>
            <Tab
              label="Listing"
              value="/stocks/listing"
              to="/stocks/listing"
              component={Link}
              sx={{ display: hasInventoryPermission ? 'block' : 'none' }}
            />
            <Tab
              label="Stock In"
              value="/stocks/stock-in"
              to="/stocks/stock-in"
              component={Link}
              sx={{ display: hasStockInPermission ? 'block' : 'none' }}
            />
            <Tab
              label="Stock Out"
              value="/stocks/stock-out"
              to="/stocks/stock-out"
              component={Link}
              sx={{ display: hasStockOutPermission ? 'block' : 'none' }}
            />
            <Tab
              label="Batches"
              value="/stocks/batches"
              to="/stocks/batches"
              component={Link}
              sx={{ display: hasBatchPermission ? 'block' : 'none' }}
            />
          </Tabs>
        </Box>
      ) : (
        <></>
      )}
      <Routes>
        <Route path="/listing/*" element={<InventoryListingRoutes />} />
        <Route path="/stock-in/*" element={<StockInRoutes />} />
        <Route path="/stock-out/*" element={<StockOutRoutes />} />
        <Route path="/batches/*" element={<BatchesListingRoutes />} />
      </Routes>
    </>
  );
};
