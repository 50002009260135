import { groupBy } from 'lodash';

import { ListResultBase, PaginationFilter } from '../../../types';

export type RoleDto = {
  id: string;
  name: string;
  description: string;
  permissions: string[];
};

export type PermissionDto = {
  id: string;
  name: string;
};

export type CreateRoleDto = Omit<RoleDto, 'id'>;

export type GetRolesFilterDto = {
  name?: string;
} & PaginationFilter;

interface Column {
  field: string;
  headerName: string;
  width?: number;
  align?: 'right';
  format?: (value: any) => string;
  hidden?: boolean;
  flex?: number;
}

export const RoleColumns: Column[] = [
  // { field: 'id', headerName: 'ID', width: 50  },
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'description', headerName: 'Description', flex: 1 },
];

export const mapPermissionToItem = (permissionData: PermissionDto[]): any => {
  var resultSplit = permissionData.map((r: PermissionDto) => {
    var temp = r.name.split('.');
    return {
      header: temp[0],
      sub: temp[1],
      items: temp[2],
      value: r.name,
    };
  });

  // Top level: group by birthdate
  const groupedByHeader = groupBy(resultSplit, (d) => d.header);

  // Convert object into array of objects
  const transformedData = Object.entries(groupedByHeader).map((entry) => {
    const [header, value] = entry;

    // Second level: group by category
    const groupedSubheader = groupBy(value, (d) => d.sub);
    const birthdatedetail = Object.entries(groupedSubheader).map((entry) => {
      const [subheader, value] = entry;

      // Step through all entries to ensure we remove keys of your selection
      const categorydetail = value.map((d) => {
        return { [d.items]: d.value };
      });

      return { [subheader]: Object.assign({}, ...categorydetail) };
    });

    return { [header]: Object.assign({}, ...birthdatedetail) };
  });

  return Object.assign({}, ...transformedData);
};

export const PermissionTableHeader = ['Permissions', 'View', 'Create', 'Edit', 'Delete', 'Finalize'];

export type RoleDtoListResultDto = ListResultBase<RoleDto>;
