import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Menu, MenuItem, Paper, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { MouseEvent, memo, useState } from 'react';

import { MobileTextLabel } from '../../../components/Elements';
import { PERMISSIONS } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { UserDto } from '../types';
import { DeleteUser } from './DeleteUser';

type UserCardMobileViewProps = {
  user: UserDto;
  handleEditRow: (selectedRow: string) => void;
  handleViewRow: (selectedRow: string) => void;
};

export const UserCardMobileView = memo(
  ({ user, handleEditRow, handleViewRow }: UserCardMobileViewProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRow, setSelectedRow] = useState<string | null>();
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setSelectedRow(event.currentTarget.getAttribute('data-id'));
    };

    const handleCloseMobile = () => {
      setAnchorEl(null);
    };

    const deleteCallBack = () => {
      handleCloseMobile();
    };

    const onEditClick = () => {
      handleEditRow(selectedRow!);
    };

    const onViewClick = () => {
      handleViewRow(selectedRow!);
    };

    const hasDeleteUserPermission = getAuthorization(PERMISSIONS.USER.DELETE);
    const hasViewUserPermission = getAuthorization(PERMISSIONS.USER.VIEW);
    const hasEditUserPermission = getAuthorization(PERMISSIONS.USER.EDIT);

    return (
      <Paper sx={{ padding: 2, margin: 2 }} elevation={0} variant="outlined">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {user.userName}
          </Typography>

          {(hasViewUserPermission || hasEditUserPermission || hasDeleteUserPermission) && (
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                data-id={user.id}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMobile}
              >
                {hasViewUserPermission && (
                  <MenuItem sx={{ fontSize: 13 }} onClick={onViewClick}>
                    View User
                  </MenuItem>
                )}
                {hasEditUserPermission && (
                  <MenuItem sx={{ fontSize: 13 }} onClick={onEditClick}>
                    Edit User
                  </MenuItem>
                )}
                {hasDeleteUserPermission && (
                  <DeleteUser id={selectedRow!} callback={deleteCallBack} />
                )}
              </Menu>
            </div>
          )}
        </Box>
        <Typography>
          <MobileTextLabel field={user.firstname} labelName="First Name" />
          <MobileTextLabel field={user.lastName} labelName="Last Name" />
          <MobileTextLabel field={user.email} labelName="Email" />
          <MobileTextLabel field={user.phoneNumber} labelName="Phone Number" />
          <MobileTextLabel
            fieldTransform={(value: string) => value.charAt(0).toUpperCase() + value.slice(1)}
            field={user.role}
            labelName="Role"
          />
          <MobileTextLabel field={user.isActive} labelName="Is Active" />
        </Typography>
      </Paper>
    );
  }
);
