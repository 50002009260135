import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { memo } from 'react';

type TableSkeleton = {
  rowsCount?: number;
};

export const MobileTableSkeleton = memo(({ rowsCount = 5 }: TableSkeleton) => {
  return (
    <>
      {[...Array(rowsCount)].map((_, index) => {
        return (
          <Box sx={{ padding: 2, margin: 2 }} key={'Box' + index}>
            <Skeleton key={index} variant="rectangular" sx={{ width: '95%' }} height={100} />
          </Box>
        );
      })}
    </>
  );
});
