import { LoadingButton } from '@mui/lab';
import { DialogContentText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { memo } from 'react';

export type ProcessFinancialConfirmationProps = {
  item: any;
  open: boolean;
  handleClose: () => void;
  submitAction: (data: any) => Promise<void>;
  isLoading?: boolean;
};

export const ProcessFinancialConfirmation = memo(
  ({
    item,
    open,
    handleClose,
    submitAction,
    isLoading,
  }: ProcessFinancialConfirmationProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const onClose = (event: object, reason: string) => {
      if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
        return;
      }

      handleClose();
    };

    const handleSave = async () => {
      try {
        await submitAction(item);
        onClose({}, '');
        enqueueSnackbar("A/C Period Closed successfully!", { variant: 'success' });
      } catch (ex) {
        enqueueSnackbar('Error occured, please contact system administrator', { variant: 'error' });
        onClose({}, '');
      }
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={'sm'}
        fullWidth
        //FIXME: look into solving the flicking issue, temporarily set to 0 to preventhe modal to be flickering when its state is changed
        transitionDuration={{ exit: 0 }}
      >
        <DialogTitle>Are you sure you want close the financial period?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            All the transaction records within this financial period will be finalised. <br />
            Note: This action cannot be reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} onClick={() => onClose({}, '')}>
            Cancel
          </LoadingButton>
          <LoadingButton loading={isLoading} onClick={() => handleSave()}>
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
);
