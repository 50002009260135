import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { GetBatchesFilterDto } from '../features/inventory-listing/types';
import {
  GetCarBrandsFilterDto,
  GetCarModelsFilterDto,
  GetColoursFilterDto,
  GetDesignsFilterDto,
  GetItemsFilterDto,
  GetMaterialsFilterDto,
} from '../features/master-data/types';
import { GetRolesFilterDto } from '../features/roles/types';
import { GetStockInFilterDto } from '../features/stock-in/types';
import { GetStockOutFilterDto } from '../features/stock-out/types';
import { GetUsersFilterDto } from '../features/users/types';
import { PaginationFilter } from '../types';
import { GetFeaturesFilterDto } from '../features/master-data/types/Feature';

interface SearchState {
  userFilter?: (GetUsersFilterDto & PaginationFilter) | null;
  roleFilter?: (GetRolesFilterDto & PaginationFilter) | null;
  designFilter?: (GetDesignsFilterDto & PaginationFilter) | null;
  carBrandFilter?: (GetCarBrandsFilterDto & PaginationFilter) | null;
  carModelFilter?: (GetCarModelsFilterDto & PaginationFilter) | null;
  materialFilter?: (GetMaterialsFilterDto & PaginationFilter) | null;
  featureFilter?: (GetFeaturesFilterDto & PaginationFilter) | null;
  colourFilter?: (GetColoursFilterDto & PaginationFilter) | null;
  itemFilter?: (GetItemsFilterDto & PaginationFilter) | null;
  listingFilter?: (GetItemsFilterDto & PaginationFilter) | null;
  batchesFilter?: (GetBatchesFilterDto & PaginationFilter) | null;
  stockInFilter?: (GetStockInFilterDto & PaginationFilter) | null;
  stockOutFilter?: (GetStockOutFilterDto & PaginationFilter) | null;
}

const initialState: SearchState = {
  userFilter: null,
  roleFilter: null,
  designFilter: null,
  carBrandFilter: null,
  carModelFilter: null,
  materialFilter: null,
  featureFilter: null,
  colourFilter: null,
  itemFilter: null,
  listingFilter: null,
  batchesFilter: {
    fromDate: dayjs().format('YYYY-MM-DD 00:00:00'),
    toDate: dayjs().format('YYYY-MM-DD 23:59:59'),
  },
  stockInFilter: {
    dateFrom: dayjs().format('YYYY-MM-DD 00:00:00'),
    dateTo: dayjs().format('YYYY-MM-DD 23:59:59'),
  },
  stockOutFilter: {
    dateFrom: dayjs().format('YYYY-MM-DD 00:00:00'),
    dateTo: dayjs().format('YYYY-MM-DD 23:59:59'),
  },
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    initializeState: (state) => Object.assign(state, initialState),
    setUserSearchFilter: (state, action: PayloadAction<GetUsersFilterDto>) => {
      state.userFilter = action.payload;
    },
    clearUserSearchFilter: (state) => {
      state.userFilter = null;
    },
    setRoleSearchFilter: (state, action: PayloadAction<GetRolesFilterDto>) => {
      state.roleFilter = action.payload;
    },
    clearRoleSearchFilter: (state) => {
      state.roleFilter = null;
    },
    setDesignSearchFilter: (state, action: PayloadAction<GetDesignsFilterDto>) => {
      state.designFilter = action.payload;
    },
    clearDesignSearchFilter: (state) => {
      state.designFilter = null;
    },
    setCarBrandSearchFilter: (state, action: PayloadAction<GetCarBrandsFilterDto>) => {
      state.carBrandFilter = action.payload;
    },
    clearCarBrandSearchFilter: (state) => {
      state.carBrandFilter = null;
    },
    setCarModelSearchFilter: (state, action: PayloadAction<GetCarModelsFilterDto>) => {
      state.carModelFilter = action.payload;
    },
    clearCarModelSearchFilter: (state) => {
      state.carModelFilter = null;
    },
    setFeatureSearchFilter: (state, action: PayloadAction<GetFeaturesFilterDto>) => {
      state.featureFilter = action.payload;
    },
    clearFeatureSearchFilter: (state) => {
      state.featureFilter = null;
    },
    setMaterialSearchFilter: (state, action: PayloadAction<GetMaterialsFilterDto>) => {
      state.materialFilter = action.payload;
    },
    clearMaterialSearchFilter: (state) => {
      state.materialFilter = null;
    },
    setColourSearchFilter: (state, action: PayloadAction<GetColoursFilterDto>) => {
      state.colourFilter = action.payload;
    },
    clearColourSearchFilter: (state) => {
      state.colourFilter = null;
    },
    setItemSearchFilter: (state, action: PayloadAction<GetItemsFilterDto>) => {
      state.itemFilter = action.payload;
    },
    clearItemSearchFilter: (state) => {
      state.itemFilter = null;
    },
    setListingFilter: (state, action: PayloadAction<GetItemsFilterDto>) => {
      state.listingFilter = action.payload;
    },
    clearListingFilter: (state) => {
      state.listingFilter = null;
    },
    setBatchesFilter: (state, action: PayloadAction<GetBatchesFilterDto>) => {
      state.batchesFilter = action.payload;
    },
    clearBatchesFilter: (state) => {
      state.batchesFilter = {
        fromDate: dayjs().format('YYYY-MM-DD 00:00:00'),
        toDate: dayjs().format('YYYY-MM-DD 23:59:59'),
      };
    },
    setStockInFilter: (state, action: PayloadAction<GetStockInFilterDto>) => {
      state.stockInFilter = action.payload;
    },
    clearStockInFilter: (state) => {
      state.stockInFilter = {
        dateFrom: dayjs().format('YYYY-MM-DD 00:00:00'),
        dateTo: dayjs().format('YYYY-MM-DD 23:59:59'),
      };
    },
    setStockOutFilter: (state, action: PayloadAction<GetStockOutFilterDto>) => {
      state.stockOutFilter = action.payload;
    },
    clearStockOutFilter: (state) => {
      state.stockOutFilter = {
        dateFrom: dayjs().format('YYYY-MM-DD 00:00:00'),
        dateTo: dayjs().format('YYYY-MM-DD 23:59:59'),
      };
    },
  },
});

export const {
  initializeState,
  setUserSearchFilter,
  clearUserSearchFilter,
  setRoleSearchFilter,
  clearRoleSearchFilter,
  setDesignSearchFilter,
  clearDesignSearchFilter,
  setCarBrandSearchFilter,
  clearCarBrandSearchFilter,
  setCarModelSearchFilter,
  clearCarModelSearchFilter,
  setMaterialSearchFilter,
  clearMaterialSearchFilter,
  setColourSearchFilter,
  clearColourSearchFilter,
  setItemSearchFilter,
  clearItemSearchFilter,
  setListingFilter,
  clearListingFilter,
  setBatchesFilter,
  clearBatchesFilter,
  setStockInFilter,
  clearStockInFilter,
  setStockOutFilter,
  clearStockOutFilter,
  setFeatureSearchFilter,
  clearFeatureSearchFilter
} = searchSlice.actions;

export default searchSlice.reducer;
