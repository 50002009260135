import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';

import { Spinner } from '../components/Elements/Spinner';
import { nonHookGetAuthorization } from '../lib/Authorization';
import { getUserAction } from '../store/auth';
// import { Login } from '../features/auth/routes/Login';
import { useAppDispatch, useAppSelector } from '../store/hook';
import storage from '../utils/storage';
import { protectedRoutes, protectedRoutesChildren } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  // const item = useAppSelector((state) => state.router);
  const item = {} as any;
  const { status, responseTokenStatus, isAuthenticated } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!storage.getAccessToken() && !!storage.getRefreshToken() && !isAuthenticated) {
      dispatch(getUserAction());
    }

    if (!!storage.getAccessToken() && !!storage.getRefreshToken() && isAuthenticated) {
      setTimeout(() => {
        if (item.previousLocations && item.previousLocations.length > 0) {
          const targetLocation = item.previousLocations.find(
            (a: any) => a.location?.pathname != '/login' && a.location?.pathname != '/'
          );
          if (targetLocation && targetLocation.location?.pathname) {
            const route = protectedRoutesChildren
              .filter((a) => a.path != '/')
              .find(
                (a) =>
                  targetLocation.location?.pathname.indexOf(
                    a.path.replaceAll('/', '').replaceAll('*', '')
                  ) != -1
              );
            if (route !== undefined && nonHookGetAuthorization(route.requiredPermissions)) {
              navigate(targetLocation?.location?.pathname);
            } else {
              navigate('/');
            }
          }
        }
      }, 0);
    }
  }, [
    isAuthenticated,
    storage.getAccessToken(),
    dispatch,
    storage.getRefreshToken(),
    protectedRoutesChildren,
  ]);

  const routes =
    responseTokenStatus === 'loading' ||
    (responseTokenStatus === 'idle' && !isNil(storage.getAccessToken()))
      ? [
          {
            path: '*',
            element: (
              <Box
                sx={{
                  height: '100vh',
                  width: '100vw',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spinner />
              </Box>
            ),
          },
        ]
      : isAuthenticated
      ? protectedRoutes
      : publicRoutes;

  const element = useRoutes([...routes]);

  return <>{element}</>;
};
