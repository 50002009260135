import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as NLink } from 'react-router-dom';

export type BreadcrumbPath = {
  name: string;
  path: string;
  children?: BreadcrumbPath[];
};

export type BreadcrumbProps = {
  paths: BreadcrumbPath[];
};

export const Breadcrumb = ({ paths }: BreadcrumbProps) => {
  const theme = useTheme();
  const smallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div role="presentation">
      <Breadcrumbs
        maxItems={smallerThanMd ? 3 : 99}
        aria-label="breadcrumb"
        separator={<NavigateNextIcon sx={{ color: 'white' }} />}
      >
        <Link component={NLink} underline="hover" color={grey[100]} to="/">
          LEC Inventory
        </Link>
        {paths.map((path, index) => {
          return (
            path &&
            (index === paths.length - 1 ? (
              <Link
                underline="none"
                color="#FFF"
                aria-current={index === paths.length - 1}
                key={path.path}
              >
                {path.name}
              </Link>
            ) : (
              <Link
                component={NLink}
                underline="hover"
                color={grey[100]}
                to={path.path}
                aria-current={index === paths.length - 1}
                key={path.path}
              >
                {path.name}
              </Link>
            ))
          );
        })}
      </Breadcrumbs>
    </div>
  );
};
