import { LoadingButton } from '@mui/lab';
import { Button, Grid, Menu, MenuItem, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import { useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

type StockCardReportProps = {
  filterObject: any;
  setFilterObject: (data: any) => void;
  dateFormat?: string;
  viewReport: (data: any) => void;
  download: (data: any) => Promise<void>;
  formatData: any;
  loading: boolean;
};
export const StockCardReport = ({
  filterObject,
  setFilterObject,
  viewReport,
  dateFormat,
  download,
  formatData,
  loading,
}: StockCardReportProps) => {
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  dayjs().locale('en_MY');
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<{
    fromDate: any;
    toDate: any;
    code: any;
  }>({
    defaultValues: {
      fromDate: filterObject?.fromDate
        ? dayjs(filterObject?.fromDate)
        : dayjs().startOf('year').format('YYYY-MM-DD'),
      toDate: filterObject?.toDate
        ? dayjs(filterObject?.toDate)
        : dayjs().endOf('year').format('YYYY-MM-DD'),
      code: !isNil(filterObject?.code) ? (filterObject.code as string) : '',
    },
  });

  // useEffect(() => {
  //   if (!isLoading && masterData && masterData.items) {
  //     const items = masterData.items;
  //     setFromCategory(items);
  //     setToCategory(items);
  //   }
  // }, [isLoading, masterData]);
  //
  const [fromDate, toDate, code] = useWatch({
    control,
    name: ['fromDate', 'toDate', 'code'],
  });

  const downloadReport = async (fileType: number) => {
    const { fromDate, toDate, code } = getValues();
    await download({
      ...(!isNil(fromDate) && { fromDate: dayjs(fromDate).format('YYYY-MM-DD') }),
      ...(!isNil(toDate) && { toDate: dayjs(toDate).format('YYYY-MM-DD') }),
      ...(!isNil(code) && { code: code }),
      fileType: fileType,
    });
  };

  const onSubmit = (values: any) => {
    const { fromDate, toDate, code } = values;
    viewReport({
      ...(!isNil(fromDate) && { fromDate: dayjs(fromDate).format('YYYY-MM-DD') }),
      ...(!isNil(toDate) && { toDate: dayjs(toDate).format('YYYY-MM-DD') }),
      ...(!isNil(code) && { code: code }),
    });
  };

  // useEffect(() => {
  //   if ((toCategoryField && fromCategoryField) || (!toCategoryField && !fromCategoryField)) {
  //     clearErrors();
  //   }
  // }, [toCategoryField, fromCategoryField]);

  return (
    <Grid item xs={12} md={12}>
      <Grid
        container
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        spacing={2}
        display="flex"
        alignItems={'flex-start'}
      >
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Controller
                name="fromDate"
                rules={{
                  required: false,
                  validate: {
                    validDate: (value) =>
                      value ? dayjs(value).isValid() || 'Invalid From Date' : true,
                    isBeforeToDate: (value) =>
                      dayjs(value).isValid() && dayjs(toDate).isValid()
                        ? dayjs(value).isSameOrBefore(dayjs(toDate)) ||
                          'From Date cannot be after To Date'
                        : true,
                  },
                }}
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="frFr">
                    <DatePicker
                      label="From Date"
                      value={field.value}
                      inputFormat={dateFormat}
                      onChange={(newValue: any) => {
                        field.onChange(newValue);
                        setFilterObject({
                          ...filterObject,
                          fromDate: dayjs(newValue).format('YYYY-MM-DD'),
                        });
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          fullWidth
                          {...params}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          variant="standard"
                          error={!!errors.fromDate}
                          helperText={errors.fromDate?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Controller
                name="toDate"
                rules={{
                  required: false,
                  validate: {
                    validDate: (value) =>
                      value ? dayjs(value).isValid() || 'Invalid To Date' : true,
                    isAfterFromDate: (value) =>
                      dayjs(value).isValid() && dayjs(fromDate).isValid()
                        ? dayjs(value).isSameOrAfter(dayjs(fromDate)) ||
                          'To Date cannot be before From Date'
                        : true,
                  },
                }}
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="frFr">
                    <DatePicker
                      label="To Date"
                      value={field.value}
                      inputFormat={dateFormat}
                      onChange={(newValue: any) => {
                        field.onChange(newValue);
                        setFilterObject({
                          ...filterObject,
                          toDate: dayjs(newValue).format('YYYY-MM-DD'),
                        });
                      }}
                      minDate={dayjs(fromDate)}
                      renderInput={(params: any) => (
                        <TextField
                          fullWidth
                          {...params}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          variant="standard"
                          error={!!errors.toDate}
                          helperText={errors.toDate?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: 'Code is required',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label="Code"
                    type="text"
                    helperText={!isNil(errors.code?.message) ? errors.code?.message.toString() : ''}
                    error={!!errors.code}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              flexDirection={'row'}
              display="flex"
              sx={{ ml: 'auto' }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button type="submit" sx={{ mr: 2 }} variant="outlined">
                View
              </Button>
              <LoadingButton
                onClick={handleClick}
                loading={loading}
                variant="contained"
                size="medium"
                disabled={!!errors.fromDate || !!errors.toDate}
              >
                Export
              </LoadingButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {formatData &&
                  formatData?.items.map((f: any) => {
                    return (
                      <MenuItem key={f.value} onClick={() => downloadReport(Number(f.value))}>
                        {f.displayName}
                      </MenuItem>
                    );
                  })}
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
