import { ListResultBase, PaginationFilter } from '../../../types';

export type UserDto = {
  id: string;
  userName: string;
  firstname: string;
  lastName: string;
  role: string;
  isActive: boolean;
  email: string;
  phoneNumber: string;
};

export type CreateUserDto = {} & Omit<UserDto, 'id' | 'isActive'>;

export type UpdateUserDto = {} & Omit<UserDto, 'userName' | 'isActive'>;

export const mapUserToUpdateDto = (
  original: UserDto,
  toUpdate: Partial<UserDto>
): UpdateUserDto => {
  const { userName, isActive, ..._original } = original;
  const { userName: _userName, isActive: _isActive, ..._toUpdate } = toUpdate;

  let newObj = { ..._original, ..._toUpdate, isActive: _isActive };
  return newObj;
};

export type UserDtoListResultDto = ListResultBase<UserDto>;

interface Column {
  field: string;
  headerName: string;
  width?: number;
  align?: 'right';
  format?: (value: any) => string;
  hidden?: boolean;
  flex?: number;
}

export const UserColumns: Column[] = [
  // { field: 'id', headerName: 'ID', width: 50  },
  { field: 'userName', headerName: 'Username', flex: 1 },
  { field: 'firstname', headerName: 'First Name', flex: 1 },
  { field: 'lastName', headerName: 'Last Name', flex: 1 },
  { field: 'email', headerName: 'Email', flex: 1 },
  { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
    format: (param: any) => param && param.charAt(0).toUpperCase() + param.slice(1),
  },
  {
    field: 'isActive',
    headerName: 'Is Active',
    flex: 1,
    format: (param: any) => (param ? 'Yes' : 'No'),
  },
];

export type GetUsersFilterDto = {
  firstName?: string;
  lastName?: string;
  userName?: string;
  role?: string;
} & PaginationFilter;
