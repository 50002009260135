import { useSnackbar } from 'notistack';
// import { useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom';

import { FormSkeleton, NotFound } from '../../../components/Elements/';
import { PERMISSIONS } from '../../../const';
import { getErrorMessage, getSaveSuccessMessage } from '../../../const';
import { getAuthorization } from '../../../lib/Authorization';
import { useCreateUserMutation, useGetAllRolesQuery } from '../../../store/api';
import { CreateOrUpdateUserForm } from '../components/CreateOrUpdateUserForm';
import { CreateUserDto } from '../types';

export const CreateUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [createUserMutation, { isLoading: isUpdating }] = useCreateUserMutation();
  const { data: roleData, isLoading: isRoleLoading, error: roleError } = useGetAllRolesQuery();
  const hasCreatePermission = getAuthorization(PERMISSIONS.USER.CREATE);
  const hasEditPermission = getAuthorization(PERMISSIONS.USER.EDIT);

  const handleSuccess = (id: string) => {
    if (hasEditPermission) {
      navigate(`/user-management/users/edit/${id}`);
    } else {
      navigate(`/user-management/users/view/${id}`);
    }
  };

  const createUser = async (data: any) => {
    try {
      const result = await createUserMutation(data).unwrap();

      if (!!result) {
        enqueueSnackbar(getSaveSuccessMessage(), { variant: 'success' });
        handleSuccess(result);
      }
    } catch (err: any) {
      enqueueSnackbar(getErrorMessage(err?.data.Message.toString()), { variant: 'error' });
      throw err;
    }
  };

  const mapUser = (_: any, destination: any): CreateUserDto => {
    return destination as CreateUserDto;
  };

  return (
    <>
      {!hasCreatePermission ? (
        <NotFound
          snackbarText="Unauthorized to view page"
          text="Unauthorized to view page"
        ></NotFound>
      ) : isRoleLoading ? (
        <FormSkeleton />
      ) : roleError ? (
        <>Error loading user, please refresh the page</>
      ) : (
        <CreateOrUpdateUserForm
          submitAction={createUser}
          mapUser={mapUser}
          isSubmitting={isUpdating}
          rolesMaster={roleData || []}
        />
      )}
    </>
  );
};
