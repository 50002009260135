import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';

import { DropdownType } from '../../../types';

type FormDetailsProps = {
  control: any;
  errors?: any;
  rolesMaster: DropdownType[];
  formState?: 'edit' | 'create' | 'view';
};

export const FormDetails = ({ rolesMaster, control, errors, formState }: FormDetailsProps) => {
  const isUpdate = formState === 'edit';
  const isReadOnly = formState === 'view';

  return (
    <>
      <Grid item xs={12} md={6}>
        <Controller
          name="userName"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              required
              {...field}
              variant="outlined"
              label="Username"
              type="text"
              InputProps={{
                readOnly: isReadOnly || isUpdate,
              }}
              helperText={errors.userName?.message}
              error={!!errors.userName}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="firstname"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              required
              {...field}
              InputProps={{
                readOnly: isReadOnly,
              }}
              variant="outlined"
              label="First Name"
              type="text"
              helperText={errors.firstname?.message}
              error={!!errors.firstname}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              InputProps={{readOnly: isReadOnly}}
              variant="outlined"
              label="Last Name"
              type="text"
              helperText={errors.lastName?.message}
              error={!!errors.lastName}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              InputProps={{
                readOnly: isReadOnly,
              }}
              variant="outlined"
              label="Email"
              type="text"
              helperText={errors.email?.message}
              error={!!errors.email}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              InputProps={{
                readOnly: isReadOnly,
              }}
              variant="outlined"
              label="Phone Number"
              type="text"
              helperText={errors.phoneNumber?.message}
              error={!!errors.phoneNumber}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="role"
          control={control}
          render={({ field }) => {
            let content = isReadOnly ? (
              <TextField
                fullWidth
                {...field}
                InputProps={{
                  readOnly: isReadOnly,
                }}
                variant="outlined"
                label="Role"
                type="text"
                helperText={errors.role?.message}
                error={!!errors.role}
              />
            ) : (
              <FormControl variant="outlined" fullWidth error={!!errors.role}>
                <InputLabel required id="demo-simple-select-standard-label">
                  Role
                </InputLabel>
                <Select labelId="demo-simple-select-standard-label" label="Role" {...field}>
                  {rolesMaster &&
                    rolesMaster.map((role: DropdownType) => {
                      return (
                        <MenuItem key={role.displayName} value={role.value}>
                          {role.displayName}
                        </MenuItem>
                      );
                    })}
                </Select>
                {!!errors.role && <FormHelperText>{errors.role?.message}</FormHelperText>}
              </FormControl>
            );
            return content;
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
        <FormControlLabel
          control={
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <Checkbox
                  disabled={isReadOnly}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              )}
            />
          }
          label="Is Active"
        />
      </Grid>
    </>
  );
};
